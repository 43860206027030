/**
 * Abstraction of an contextual Api configuration
 */
 interface ApiContext {
    baseURL : string;
    timeout : number;
}

interface CdnServer {
    server : string;
}

/**
 * Abstract of the web application context
 */
class WebappContext {
    /**
     * Store the Accounts API configuration
     */
    accountsErpAPI : ApiContext;

    accountsCdn : CdnServer;

    contractsApi: ApiContext;

    constructor(accountsErpAPI : ApiContext, accountsCdn : CdnServer, contractsApi: ApiContext) {
        this.accountsErpAPI = accountsErpAPI;
        this.accountsCdn = accountsCdn;
        this.contractsApi = contractsApi;
    }
    
}

//Store the default development host name
const developmentHost = process.env.REACT_APP_ACCOUNTS_ERP_API || 'http://localhost:20000';
const avatarCdn = process.env.REACT_APP_AVATAR_CDN || 'http://localhost:8080';

//Create the development context
const DevelopmentContext = new WebappContext(
    {
        baseURL : `${developmentHost}/klausc2w-accounts-erp-ws`,
        timeout : 100000
    },
    {
        server : avatarCdn
    },
    {
        baseURL: `http://localhost:21002/klausc2w-contracts-ws`,
        timeout: 100000
    }
);

const ProductionContext = new WebappContext(
    {
        baseURL : `https://accounts-erp-ws.klaus.services`,
        timeout : 100000
    },
    {
        server : "https://accountsblob.blob.core.windows.net/publicassets"
    },
    {
        baseURL: `https://contracts-ws.cartorizi.com`,
        timeout: 60000
    }
)

//Store the current application context
let CurrentContext : WebappContext;
if (process.env.NODE_ENV === 'development') {
    CurrentContext = DevelopmentContext;
}
else {
    CurrentContext = ProductionContext;
}

//Export modules
export {CurrentContext, DevelopmentContext};