import { Customer } from "../models/customer";
import AccountsErpAPI from "./accountsErpApi";

const CustomerService = {
    updateCustomerStatus: async (targetUuid: string, targetService: string, status: string): Promise<Customer> =>  {
        const response = await AccountsErpAPI.put(`rest/v1/customers/${targetUuid}/customer-status?service=${targetService}&status=${status}`);
        
        return await response.data;
    },
};

export default CustomerService;