import { AttachFile, Block, Check, Close, ColorLens, Description, Group, Info, MoneyOff, RequestQuote, Star, Storage, TextFields } from "@mui/icons-material";
import { Alert, Box, Button, CircularProgress, Divider, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Skeleton, Switch, SxProps, Tab, TextField, Theme, Tooltip, Typography, alpha } from "@mui/material";
import React, { useContext, useEffect, useState } from 'react';
import AuthenticationContext from '../../../contexts/authentication';
import { Roles, RolesContext } from "../../../contexts/roles";
import { ContractsQuota as ContractsQuotas, ContractsMetrics } from '../../../models/contracts-quota';
import { Customer, CustomerStatus, customerStatus } from '../../../models/customer';
import { show } from "../../../redux/features/app-global-notification/app-global-notification-slice";
import { useAppDispatch } from '../../../redux/hooks';
import ContractsQuotaService from '../../../services/contracts-quota';
import { PlansQuota } from '../../../static/contracts-plans';
import ErrorWrapper from '../../../utils/ErrorWrapper';
import CustomerService from "../../../services/customer";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import 'moment/locale/pt-br';

interface ContractsQuotaProps extends React.ComponentProps<any> {
    organization : Customer
}

export default function ContractsQuota(props: ContractsQuotaProps) {
    //Contract Quota Object

    const contractQuotaObject: ContractsQuotas = {
        maxTemplateCount: 0,
        maxSubusers: 0,
        contractsIssuedPerMonth: 0,
        maxStorageBytes: 0,
        financialAnalyticsModuleEnabled: false,
        contractsFieldsAutomationEnabled: false,
        contractAttachmentsEnabled: false,
        plan: "PAY_AS_YOU_GO",
        interfaceThemesCustomizationEnabled: false
    }


    //Context props
    const useAuthentication = () => { return useContext(AuthenticationContext) };
    const auth = useAuthentication();
    const userRoles = useContext(RolesContext);
    const notification = useAppDispatch();

    //Quota state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [ContractsQuota, setContractsQuota] = useState<ContractsQuotas>(contractQuotaObject);
    const [contractMetrics, setContractMetrics] = useState<ContractsMetrics>();

    //Dialog states 
    const [open, setOpen] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);

    const [selectedPlan, setSelectedPlan] = useState("");

    const handleChangeSelectedPlan = (event: SelectChangeEvent) => {
        setSelectedPlan(event.target.value)
        const index = PlansQuota.findIndex(planQuota => planQuota.plan === event.target.value)
        const selectedPlanQuota = PlansQuota[index];
        setContractsQuota(selectedPlanQuota);
    };

    const fetchContractsQuotaConsumption = async (startDate? : number, endDate?: number) => {
        setLoading(true);

        try {
            const resp = await ContractsQuotaService.findOrganizationQuotasConsumption(props.organization.uuid, startDate, endDate);
            setContractMetrics(resp as ContractsMetrics);
        }
        catch (e) {
            notification(show({
                type: 'error',
                message: `Erro ao buscar as métricas de uso do cliente.`
            }))
        }
        finally {
            setLoading(false);
        }
    }

    const [interfaceTheme, setInterfaceTheme] = useState(false);

    const [loadingStatus, setLoadingStatus] = useState(false);

    // Tabs 
    const [tabValue, setTabValue] = useState('0');

    // Date
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    moment.locale("pt-br");

    //Search the users accounts quota
    useEffect(() => {
        searchContractsQuota();
        fetchContractsQuotaConsumption();
     }, []);

     useEffect(() => {
        const getFirstDayOfMonth = (date: Date): Date => {
          return new Date(date.getFullYear(), date.getMonth(), 1);
        };
        
        const getLastDayOfMonth = (date: Date): Date => {
          return new Date(date.getFullYear(), date.getMonth() + 1, 0);
        };
      
        const start = getFirstDayOfMonth(selectedDate);
        const end = getLastDayOfMonth(selectedDate);
      
        fetchContractsQuotaConsumption(start.getTime(), end.getTime());
      }, [selectedDate]);

    //API calls
    const searchContractsQuota = () => {
        setLoading(true);
        setError(null);

        ContractsQuotaService.find(props.organization.uuid)
            .then(resp => {
                setContractsQuota(resp)
                setSelectedPlan(resp.plan)
            })
            .catch(err => {
                const e = new ErrorWrapper(err)
                if (e.httpStatus != 404) setError(e.message)
                else {
                    setContractsQuota(contractQuotaObject)
                }
            })
            .finally(() => {
                setInterfaceTheme(ContractsQuota.interfaceThemesCustomizationEnabled)
                setLoading(false)
            })
    }

    const updateContractsQuota = () => {
        setUpdateLoading(true);

        ContractsQuotaService.update(props.organization.uuid, ContractsQuota)
            .then(resp => {
                notification(show({
                    type: 'success',
                    message: `Plano alterado.`
                }))
                setOpen(false)
                setContractsQuota({
                    maxTemplateCount: resp.maxTemplatesCount,
                    maxSubusers: resp.maxSubusers,
                    contractsIssuedPerMonth: resp.contractsIssuedPerMonth,
                    maxStorageBytes: resp.maxStorageBytes,
                    financialAnalyticsModuleEnabled: resp.financialAnalyticsModuleEnabled,
                    contractsFieldsAutomationEnabled: resp.contractsFieldsAutomationEnabled,
                    contractAttachmentsEnabled: resp.contractAttachmentsEnabled,
                    plan: resp.plan,
                    interfaceThemesCustomizationEnabled: resp.interfaceThemesCustomizationEnabled
                })
            })
            .catch(err => {
                const e = new ErrorWrapper(err)
                notification(show({
                    type: 'error',
                    message: e.httpStatus === 422 ?
                        `Dados inválidos` : `Ocorreu um erro: ${err.message}`
                }))
            })
            .finally(() => setUpdateLoading(false))
    }

    function treatCustomerStatus(): string {
        switch(props.organization.contractsCrmStatus) {
            case CustomerStatus.BRONZE:
                return "Bronze";
            case CustomerStatus.SILVER:
                return "Prata";
            case CustomerStatus.GOLD:
                return "Ouro";
            case CustomerStatus.DIAMOND:
                return "Diamante";
            case CustomerStatus.CANCELLED:
                return "Cancelado";
            default:
                return "";
        }
    }

    function handleChangeCustomerStatus(newStatus: string) {
        setLoadingStatus(true);
        CustomerService.updateCustomerStatus(props.organization.uuid, "CONTRACTS", newStatus)
            .then(response => {
                props.organization.contractsCrmStatus = response.contractsCrmStatus;
                notification(show({type: "success", message: "Status do cliente atualizado com sucesso"}))
            })
            .catch(e => {
                const err = new ErrorWrapper(e);
                notification(show({type: "error", message: err.message}));
            })
            .finally(() => {
                setLoadingStatus(false);
            })
    }

    //Components
    const ErrorNotification = (): JSX.Element => {
        if (!error) return <></>
        return (
            <Button color='error' sx={{ m: 1 }}
                startIcon={<Tooltip title={error}><Info /></Tooltip>}
                onClick={searchContractsQuota}>
                Ocorreu um erro. Tente novamente.
            </Button>
        );
    };

    const Loading = (): JSX.Element => {
        return (
            <Box mt={2}>
                <Skeleton sx={{ mb: .5 }} />
                <Skeleton sx={{ mb: .5 }} />
                <Skeleton />
            </Box>
        );
    };

    //Style
    const sb = { display: 'flex', justifyContent: 'space-between', mt: 1.5 }

    const quotaCard: SxProps<Theme> = {
        borderBottom: 1, 
        borderColor: alpha("#000000", 0.15), 
        paddingBottom: 5,
    }

    return (
        <Box>
            <Typography variant="h6" >Status do cliente: <b>{treatCustomerStatus()}</b></Typography>
            {
                !loadingStatus
                    ?
                    <Grid container mt={2} columnSpacing={3} >
                        {
                            customerStatus.map(cs => (
                                <Grid item>
                                    <Button 
                                        variant="outlined" 
                                        disabled={cs.value === props.organization.contractsCrmStatus}
                                        onClick={() => handleChangeCustomerStatus(cs.value)}
                                    >
                                        <Box 
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            {
                                                cs.value !== "CANCELLED"
                                                    ?
                                                    <Star htmlColor={cs.color} sx={{fontSize: "24px"}} />
                                                    :
                                                    <Block htmlColor={cs.color} sx={{fontSize: "24px"}} />
                                            }
                                            <Typography variant="button" ml={2}>{cs.label}</Typography>
                                        </Box>
                                    </Button>
                                </Grid>
                            ))
                        }
                    </Grid>
                    :
                    <CircularProgress />
            }
            <Divider sx={{my: 3}} />
            <TabContext value={tabValue}>
                <Box sx={{display:"flex"}}>
                    <TabList sx={{minWidth:"100px", borderRight: "1px solid #DCDCDD"}} orientation="vertical" variant="scrollable"  onChange={(e, selectedTab) => { setTabValue(selectedTab)}}>
                        <Tab label="Cotas" value="0"/>
                        <Tab label="Métricas" value="1"/>
                    </TabList>
                    <TabPanel value="0">
                        <Typography variant="h6" >Cotas do cliente</Typography>
                        {!updateLoading ? 
                            <>
                                <Box sx={{display: "flex", alignItems: "center", width: "100%", marginBottom: 3, mt: 2}}>
                                    <Typography variant='subtitle1' sx={{marginRight: 3}}>Plano Contratado</Typography>
                                    <FormControl sx={{m: 1, width: "75%"}}>   
                                        <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                            <InputLabel id="plan" >Plano</InputLabel>
                                            <Select
                                                labelId="plan"
                                                id="selectedPlan"
                                                disabled={!userRoles.hasRole(auth, Roles.CM_QM)}
                                                value={selectedPlan}
                                                onChange={handleChangeSelectedPlan}
                                                input={<OutlinedInput label="Plano"/>}
                                                sx={{width: 250}}
                                            >
                                                {
                                                    PlansQuota.map(pq => (
                                                        <MenuItem
                                                            key={pq.plan}
                                                            value={pq.plan}
                                                        >
                                                            {pq.label}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                            {userRoles.hasRole(auth, Roles.CM_QM) ?
                                                    <Button 
                                                        disabled={props.organization.user.emailConfirmationDate == null} 
                                                        variant="contained" 
                                                        onClick={() => {updateContractsQuota()}}
                                                    >
                                                        Salvar
                                                    </Button>
                                                :
                                                    <></>
                                            }
                                        </Box>                       
                                    </FormControl>
                                </Box>
                                <Grid container rowSpacing={8} columnGap={3} sx={{alignItems: 'center', justifyContent: 'center'}}>
                                    <Grid item xs={5} sx={quotaCard}>
                                        <Grid container gap={2} alignItems='center'>
                                            <Grid item xs={1} md={2} textAlign='center'>
                                                <Description color='action' sx={{ fontSize: { sm: 30, md: 40 } }} />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant='subtitle1'>Modelos de Contrato</Typography>
                                                <Typography variant='body2'>Modelos de contrato da organização</Typography>
                                            </Grid>
                                        </Grid>

                                        {ContractsQuota && !loading && !error ?
                                            <Box mt={2} px={{ sm: 0, md: 3 }}>
                                                <Box sx={sb}>
                                                    <Typography variant='body1' color='text.secondary'>Permitidos</Typography>
                                                    {userRoles.hasRole(auth, Roles.CM_QM) ? 
                                                        <TextField 
                                                            type="number"
                                                            InputProps={{inputProps: {
                                                                min: 1,
                                                                max: 1000,
                                                                maxLength: 1000,
                                                                minLength: 1
                                                            }}}
                                                            defaultValue={1}
                                                            sx={{width: 100, height: 30,}}
                                                            value={ContractsQuota.maxTemplateCount.toString()}
                                                            onChange={e => {
                                                                let num: Number
                                                                Number(e.target.value) > 1000 ? num = 1000 : num = Number(e.target.value)
                                                                setContractsQuota({...ContractsQuota, maxTemplateCount: num})
                                                            }}
                                                            onBlur={e => {
                                                                let num: Number
                                                                Number(e.target.value) < 1 ? num = 1 : num = Number(e.target.value)
                                                                setContractsQuota({...ContractsQuota, maxTemplateCount: num})
                                                            }}
                                                        />
                                                    :   
                                                        <Typography variant='body1' color='text' ml={2}>{ContractsQuota.maxTemplateCount.toString()}</Typography>
                                                    }
                                                </Box>
                                            </Box>
                                            : loading ? <Loading /> : error ? <ErrorNotification /> : null
                                        }
                                    </Grid>
                                    <Grid item xs={5} sx={quotaCard}>
                                        <Grid container gap={2} alignItems='center'>
                                            <Grid item xs={1} md={2} textAlign='center'>
                                                <Group color='action' sx={{ fontSize: { sm: 30, md: 40 } }} />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant='subtitle1'>Sub Usuários</Typography>
                                                <Typography variant='body2'>Sub usuários da organização</Typography>
                                            </Grid>
                                        </Grid>

                                        {ContractsQuota && !loading && !error ?
                                            <Box mt={2} px={{ sm: 0, md: 3 }}>
                                                <Box sx={sb}>
                                                    <Typography variant='body1' color='text.secondary'>Permitidas</Typography>
                                                    {userRoles.hasRole(auth, Roles.CM_QM) ? 
                                                        <TextField 
                                                            type="number" 
                                                            InputProps={{inputProps: {
                                                                min: 0,
                                                                max: 1000
                                                            }}}
                                                            sx={{width: 100, height: 30,}}
                                                            value={ContractsQuota.maxSubusers.toString()}
                                                            onChange={e => {
                                                                let num: Number
                                                                Number(e.target.value) > 1000 ? num = 1000 : num = Number(e.target.value)
                                                                setContractsQuota({...ContractsQuota, maxSubusers: num})
                                                            }}
                                                            onBlur={e => {
                                                                let num: Number
                                                                Number(e.target.value) < 0 ? num = 0 : num = Number(e.target.value)
                                                                setContractsQuota({...ContractsQuota, contractsIssuedPerMonth: num})
                                                            }}
                                                        />
                                                    :   
                                                        <Typography variant='body1' color='text' ml={2}>{ContractsQuota.maxSubusers.toString()}</Typography>
                                                    }
                                                </Box>
                                            </Box>
                                            : loading ? <Loading /> : error ? <ErrorNotification /> : null
                                        }
                                    </Grid>
                                    <Grid item xs={5} sx={quotaCard}>
                                        <Grid container gap={2} alignItems='center' >
                                            <Grid item xs={1} md={2} textAlign='center'>
                                                <MoneyOff color='action' sx={{ fontSize: { sm: 30, md: 40 } }} />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant='subtitle1'>Contratos Gratuitos / Mês</Typography>
                                                <Typography variant='body2'>Contratos emitidos no mês da organização</Typography>
                                            </Grid>
                                        </Grid>

                                        {ContractsQuota && !loading && !error ?
                                            <Box mt={2} px={{ sm: 0, md: 3 }}>
                                                <Box sx={sb}>
                                                    <Typography variant='body1' color='text.secondary'>Permitidos</Typography>
                                                    {userRoles.hasRole(auth, Roles.CM_QM) &&  ContractsQuota.contractsIssuedPerMonth !== null ? 
                                                        <TextField 
                                                            type="number" 
                                                            InputProps={{inputProps: {
                                                                min: 0,
                                                                max: 100000
                                                            }}}
                                                            sx={{width: 100, height: 30,}}
                                                            value={ContractsQuota.contractsIssuedPerMonth !== null ? ContractsQuota.contractsIssuedPerMonth.toString() : 0}
                                                            onChange={e => {
                                                                let num: Number
                                                                Number(e.target.value) > 100000 ? num = 100000 : num = Number(e.target.value)
                                                                setContractsQuota({...ContractsQuota, contractsIssuedPerMonth: num})
                                                            }}
                                                            onBlur={e => {
                                                                let num: Number
                                                                Number(e.target.value) < 1 ? num = 1 : num = Number(e.target.value)
                                                                setContractsQuota({...ContractsQuota, contractsIssuedPerMonth: num})
                                                            }}
                                                        />
                                                    :   
                                                        <Typography variant='body1' color='text' ml={2}>{
                                                            ContractsQuota.contractsIssuedPerMonth !== null ? ContractsQuota.contractsIssuedPerMonth.toString() : "Sem Limite"
                                                        }</Typography>
                                                    }
                                                    
                                                </Box>
                                            </Box>
                                            : loading ? <Loading /> : error ? <ErrorNotification /> : null
                                        }
                                    </Grid>
                                    <Grid item xs={5} sx={quotaCard}>
                                        <Grid container gap={2} alignItems='center'>
                                            <Grid item xs={1} md={2} textAlign='center'>
                                                <Storage color='action' sx={{ fontSize: { sm: 30, md: 40 } }} />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant='subtitle1'>Armazenamento</Typography>
                                                <Typography variant='body2'>Espaço de armazenamento da organização</Typography>
                                            </Grid>
                                        </Grid>

                                        {ContractsQuota && !loading && !error ?
                                            <Box mt={2} px={{ sm: 0, md: 3 }}>
                                                <Box sx={sb}>
                                                    <Typography variant='body1' color='text.secondary'>Maximo</Typography>
                                                    {userRoles.hasRole(auth, Roles.CM_QM) ? 
                                                        <Box sx={{display: 'flex'}}>
                                                            <TextField 
                                                                type="number"
                                                                InputProps={{inputProps: {
                                                                    min: 1,
                                                                    max: 1000
                                                                }}}
                                                                sx={{width: 115, height: 30, marginRight: 0}}
                                                                value={ (ContractsQuota.maxStorageBytes >= 1048576 && ContractsQuota.maxStorageBytes < 1073741824) ?
                                                                    (Number(ContractsQuota.maxStorageBytes) / 1048576).toString() :
                                                                    (Number(ContractsQuota.maxStorageBytes) / 1073741824).toString()
                                                                }
                                                                onChange={e => {
                                                                    let num: Number
                                                                    Number(e.target.value) > 100000 ? num = 100000 : num = Number(e.target.value)
                                                                    setContractsQuota({...ContractsQuota, maxStorageBytes:
                                                                        (ContractsQuota.maxStorageBytes >= 1048576 && ContractsQuota.maxStorageBytes < 1073741824) ? 
                                                                            Number(num) * 1048576 : 
                                                                            Number(num) * 1073741824
                                                                        })
                                                                }}
                                                                onBlur={e => {
                                                                    let num: Number
                                                                    Number(e.target.value) < 1 ? num = 1 : num = Number(e.target.value)
                                                                    setContractsQuota({...ContractsQuota, maxStorageBytes:
                                                                        (ContractsQuota.maxStorageBytes >= 1048576 && ContractsQuota.maxStorageBytes < 1073741824) ? 
                                                                            Number(num) * 1048576 : 
                                                                            Number(num) * 1073741824
                                                                        })
                                                                }}
                                                            />
                                                            {(ContractsQuota.maxStorageBytes >= 1048576 && ContractsQuota.maxStorageBytes < 1073741824)? 
                                                                <Typography>MB</Typography> :
                                                                <Typography>GB</Typography>
                                                            }
                                                        </Box>
                                                    :   
                                                        <Typography variant='body1' color='text' ml={2}>{
                                                            (ContractsQuota.maxStorageBytes >= 1048576 && ContractsQuota.maxStorageBytes < 1073741824)? 
                                                            `${((Number(ContractsQuota.maxStorageBytes) / 1048576).toFixed(0)).toString()} MB` :
                                                            `${((Number(ContractsQuota.maxStorageBytes) / 1073741824).toFixed(0)).toString()} GB`
                                                        }</Typography>
                                                    }
                                                </Box>
                                            </Box>
                                            : loading ? <Loading /> : error ? <ErrorNotification /> : null
                                        }
                                    </Grid>
                                    <Grid item xs={5} sx={quotaCard}>
                                        <Grid container gap={2} alignItems='center' >
                                            <Grid item xs={1} md={2} textAlign='center'>
                                                <RequestQuote color='action' sx={{ fontSize: { sm: 30, md: 40 } }} />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant='subtitle1'>Análise Financeira</Typography>
                                                <Typography variant='body2'>Acesso ao modulo de análise financeira da organização</Typography>
                                            </Grid>
                                        </Grid>

                                        {ContractsQuota && !loading && !error ?
                                            <Box mt={2} px={{ sm: 0, md: 3 }}>
                                                <Box sx={sb}>
                                                    <Typography variant='body1' color='text.secondary'>Acesso</Typography>
                                                    {userRoles.hasRole(auth, Roles.CM_QM) ? 
                                                        <Box sx={{display: "flex", alignItems: "center"}}>
                                                        <Typography>{ContractsQuota.financialAnalyticsModuleEnabled? "Ativo" : "Desativo"}</Typography>
                                                        <Switch checked={ContractsQuota.financialAnalyticsModuleEnabled}
                                                            onChange={e => {
                                                                setContractsQuota({...ContractsQuota, financialAnalyticsModuleEnabled: e.target.checked})
                                                            }}
                                                        />
                                                    </Box>
                                                    :   
                                                        <Typography variant='body1' color='text' ml={2}>{
                                                        ContractsQuota.financialAnalyticsModuleEnabled? "Ativo" : "Desativo"
                                                        }</Typography>
                                                    }
                                                </Box>
                                            </Box>
                                            : loading ? <Loading /> : error ? <ErrorNotification /> : null
                                        }
                                    </Grid>
                                    <Grid item xs={5} sx={quotaCard}>
                                        <Grid container gap={2} alignItems='center'>
                                            <Grid item xs={1} md={2} textAlign='center'>
                                                <TextFields color='action' sx={{ fontSize: { sm: 30, md: 40 } }} />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant='subtitle1'>Campos Automatizados</Typography>
                                                <Typography variant='body2'>Acesso ao modulo de campos automatizados da organização</Typography>
                                            </Grid>
                                        </Grid>

                                        {ContractsQuota && !loading && !error ?
                                            <Box mt={2} px={{ sm: 0, md: 3 }}>
                                                <Box sx={sb}>
                                                    <Typography variant='body1' color='text.secondary'>Acesso</Typography>
                                                    {userRoles.hasRole(auth, Roles.CM_QM) ? 
                                                        <Box sx={{display: "flex", alignItems: "center"}}>
                                                        <Typography>{ContractsQuota.contractsFieldsAutomationEnabled? "Ativo" : "Desativo"}</Typography>
                                                        <Switch checked={ContractsQuota.contractsFieldsAutomationEnabled}
                                                            onChange={e => {
                                                                setContractsQuota({...ContractsQuota, contractsFieldsAutomationEnabled: e.target.checked})
                                                            }}
                                                        />
                                                    </Box>
                                                    :   
                                                        <Typography variant='body1' color='text' ml={2}>{
                                                        ContractsQuota.contractsFieldsAutomationEnabled? "Ativo" : "Desativo"
                                                        }</Typography>
                                                    }
                                                </Box>
                                            </Box>
                                            : loading ? <Loading /> : error ? <ErrorNotification /> : null
                                        }
                                    </Grid>
                                    <Grid item xs={5} sx={quotaCard}>
                                        <Grid container gap={2} alignItems='center' >
                                            <Grid item xs={1} md={2} textAlign='center'>
                                                <AttachFile color='action' sx={{ fontSize: { sm: 30, md: 40 } }} />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant='subtitle1'>Inserção de Anexos</Typography>
                                                <Typography variant='body2'>Acesso ao modulo de inserção de anexos da organização</Typography>
                                            </Grid>
                                        </Grid>

                                        {ContractsQuota && !loading && !error ?
                                            <Box mt={2} px={{ sm: 0, md: 3 }}>
                                                <Box sx={sb}>
                                                    <Typography variant='body1' color='text.secondary'>Acesso</Typography>
                                                    {userRoles.hasRole(auth, Roles.CM_QM) ? 
                                                        <Box sx={{display: "flex", alignItems: "center"}}>
                                                        <Typography>{ContractsQuota.contractAttachmentsEnabled? "Ativo" : "Desativo"}</Typography>
                                                        <Switch checked={ContractsQuota.contractAttachmentsEnabled}
                                                            onChange={e => {
                                                                setContractsQuota({...ContractsQuota, contractAttachmentsEnabled: e.target.checked})
                                                            }}
                                                        />
                                                    </Box>
                                                    :   
                                                        <Typography variant='body1' color='text' ml={2}>{
                                                        ContractsQuota.contractAttachmentsEnabled? "Ativo" : "Desativo"
                                                        }</Typography>
                                                    }
                                                </Box>
                                            </Box>
                                            : loading ? <Loading /> : error ? <ErrorNotification /> : null
                                        }
                                    </Grid>
                                    <Grid item xs={5} sx={quotaCard}>
                                        <Grid container gap={2} alignItems='center' >
                                            <Grid item xs={1} md={2} textAlign='center'>
                                                <ColorLens color='action' sx={{ fontSize: { sm: 30, md: 40 } }} />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant='subtitle1'>Personalização de interface</Typography>
                                                <Typography variant='body2'>Acesso ao modulo de personalização da interface do contrato</Typography>
                                            </Grid>
                                        </Grid>

                                        {ContractsQuota && !loading && !error ?
                                            <Box mt={2} px={{ sm: 0, md: 3 }}>
                                                <Box sx={sb}>
                                                    <Typography variant='body1' color='text.secondary'>Acesso</Typography>
                                                    {userRoles.hasRole(auth, Roles.CM_QM) ? 
                                                        <Box sx={{display: "flex", alignItems: "center"}}>
                                                            <Typography>{ContractsQuota.interfaceThemesCustomizationEnabled? "Ativo" : "Desativo"}</Typography>
                                                            <Switch checked={ContractsQuota.interfaceThemesCustomizationEnabled}
                                                                onChange={e => {
                                                                    setContractsQuota({...ContractsQuota, interfaceThemesCustomizationEnabled: e.target.checked})
                                                                }}
                                                            />
                                                        </Box>
                                                    :   
                                                        <Typography variant='body1' color='text' ml={2}>{
                                                        ContractsQuota.interfaceThemesCustomizationEnabled? "Ativo" : "Desativo"
                                                        }</Typography>
                                                    }
                                                </Box>
                                            </Box>
                                            : loading ? <Loading /> : error ? <ErrorNotification /> : null
                                        }
                                    </Grid>
                                </Grid>
                            </>
                        :
                            <CircularProgress color='primary' size={100} sx={{display: 'block', margin: 'auto'}} />
                        }
                    </TabPanel>
                    <TabPanel value="1">
                        <Box sx={{display: "flex", justifyContent:"space-between", mb: 4}}>
                            <Typography variant="h6" sx={{mb: 4}}>Métricas de uso do cliente</Typography>
                            <Box sx={{display: "flex", flexDirection:"column", alignItems:"end"}}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="Buscar em um mês específico"
                                        inputFormat="MM/YYYY"
                                        views={['year', 'month']}
                                        value={selectedDate}
                                        onChange={(value: Moment | null) => { if (value) setSelectedDate(value.toDate()) }}
                                        onMonthChange={(value: Moment | null) => { if (value) setSelectedDate(value.toDate()) }}
                                        renderInput={(params) => <TextField {...params} size='small' sx={{ mb: 2 }} />}
                                        />     
                                </LocalizationProvider>                 
                            </Box>
                        </Box>
                        {
                            contractMetrics 
                            ?
                            <Grid container rowSpacing={8} columnGap={3} sx={{justifyContent:"center"}}>
                                <Grid item xs={12} md={5.5}>
                                    <Grid container gap={2} alignItems="center">
                                        <Grid item xs={1} md={2} textAlign='center'>
                                            <AttachFile color='action' sx={{ fontSize: { sm: 30, md: 40 } }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Box sx={{ mb: 2 }}>
                                                <Typography sx={{mb: 1}}>Contratos Emitidos</Typography>
                                                <Box sx={{display:"flex"}}>
                                                    <Typography sx={{color:"primary.main", fontWeight: 600}}>{contractMetrics.contractsIssuedPerMonth.toString()}</Typography>
                                                </Box>
                                            </Box>
                                            <Divider/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={5.5}>
                                    <Grid container gap={2} alignItems="center">
                                        <Grid item xs={1} md={2} textAlign='center'>
                                            <Description color='action' sx={{ fontSize: { sm: 30, md: 40 } }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Box sx={{ mb: 2 }}>
                                                <Typography sx={{mb: 1}}>Quantidade de Modelos</Typography>
                                                <Box sx={{display:"flex"}}>
                                                    <Typography sx={{color:"primary.main", fontWeight: 600}}>{contractMetrics.currentTemplateCount.toString()}</Typography>
                                                </Box>
                                            </Box>
                                            <Divider/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={5.5}>
                                    <Grid container gap={2} alignItems="center">
                                        <Grid item xs={1} md={2} textAlign='center'>
                                            <Group color='action' sx={{ fontSize: { sm: 30, md: 40 } }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Box sx={{ mb: 2 }}>
                                                <Typography sx={{mb: 1}}>Quantidade de Usuários</Typography>
                                                <Box sx={{display:"flex"}}>
                                                    <Typography sx={{color:"primary.main", fontWeight: 600}}>{contractMetrics.currentSubusersCount.toString()}</Typography>
                                                </Box>
                                            </Box>
                                            <Divider/>                                        
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={5.5}>
                                    <Grid container gap={2} alignItems="center">
                                        <Grid item xs={1} md={2} textAlign='center'>
                                            <Storage color='action' sx={{ fontSize: { sm: 30, md: 40 } }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Box sx={{ mb: 2 }}>
                                                <Typography sx={{mb: 1}}>Uso de Armazenamento</Typography>
                                                <Box sx={{display:"flex"}}>
                                                {
                                                    contractMetrics.currentStorageConsumptionInBytes >= 1073741824
                                                    ? 
                                                    <Typography sx={{ color: "primary.main", fontWeight: 600 }}>
                                                        { (contractMetrics.currentStorageConsumptionInBytes / 1073741824).toFixed(2) } GB
                                                    </Typography>
                                                    : 
                                                    <Typography sx={{ color: "primary.main", fontWeight: 600 }}>
                                                        { (contractMetrics.currentStorageConsumptionInBytes / 1048576).toFixed(2) } MB
                                                    </Typography>
                                                }
                                                </Box>
                                            </Box>
                                            <Divider/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={5.5}>
                                    <Grid container gap={2} alignItems="center">
                                        <Grid item xs={1} md={2} textAlign='center'>
                                            <TextFields color='action' sx={{ fontSize: { sm: 30, md: 40 } }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Box sx={{ mb: 2 }}>
                                                <Typography sx={{mb: 1}}>Acesso ao módulo de Campos Automatizados</Typography>
                                                <Box sx={{display:"flex"}}>
                                                    {
                                                        ContractsQuota.contractsFieldsAutomationEnabled ?
                                                        <Box sx={{display: "flex", width: "100%", padding: 1}}>
                                                            <Check sx={{color: "primary.main", mr: 1}}/>
                                                            <Typography sx={{color: "primary.main"}}>Possui acesso a esse módulo</Typography>
                                                        </Box>
                                                        :
                                                        <Box sx={{display: "flex", width: "100%", padding: 1}}>
                                                            <Close sx={{color: "primary.main", mr: 1}}/>
                                                            <Typography sx={{color: "primary.main"}}>Não possui acesso a esse módulo</Typography>
                                                        </Box>
                                                    }
                                                </Box>
                                            </Box>
                                            <Divider/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={5.5}>
                                    <Grid container gap={2} alignItems="center">
                                        <Grid item xs={1} md={2} textAlign='center'>
                                            <AttachFile color='action' sx={{ fontSize: { sm: 30, md: 40 } }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Box sx={{ mb: 2 }}>
                                                <Typography sx={{mb: 1}}>Acesso ao módulo de Anexos</Typography>
                                                <Box sx={{display:"flex"}}>
                                                    {
                                                        ContractsQuota.contractAttachmentsEnabled ?
                                                        <Box sx={{display: "flex", width: "100%", padding: 1}}>
                                                            <Check sx={{color: "primary.main", mr: 1}}/>
                                                            <Typography sx={{color: "primary.main"}}>Possui acesso a esse módulo</Typography>
                                                        </Box>
                                                        :
                                                        <Box sx={{display: "flex", width: "100%", padding: 1}}>
                                                            <Close sx={{color: "primary.main", mr: 1}}/>
                                                            <Typography sx={{color: "primary.main"}}>Não possui acesso a esse módulo</Typography>
                                                        </Box>
                                                    }
                                                </Box>
                                            </Box>
                                            <Divider/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={5.5}>
                                    <Grid container gap={2} alignItems="center">
                                        <Grid item xs={1} md={2} textAlign='center'>
                                            <RequestQuote color='action' sx={{ fontSize: { sm: 30, md: 40 } }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Box sx={{ mb: 2 }}>
                                                <Typography sx={{mb: 1}}>Acesso ao módulo de Gestão Financeira</Typography>
                                                <Box sx={{display:"flex"}}>
                                                    {
                                                        ContractsQuota.financialAnalyticsModuleEnabled ?
                                                        <Box sx={{display: "flex", width: "100%", padding: 1}}>
                                                            <Check sx={{color: "primary.main", mr: 1}}/>
                                                            <Typography sx={{color: "primary.main"}}>Possui acesso a esse módulo</Typography>
                                                        </Box>
                                                        :
                                                        <Box sx={{display: "flex", width: "100%", padding: 1}}>
                                                            <Close sx={{color: "primary.main", mr: 1}}/>
                                                            <Typography sx={{color: "primary.main"}}>Não possui acesso a esse módulo</Typography>
                                                        </Box>
                                                    }
                                                </Box>
                                            </Box>
                                            <Divider/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={5.5}>
                                    <Grid container gap={2} alignItems="center">
                                        <Grid item xs={1} md={2} textAlign='center'>
                                            <ColorLens color='action' sx={{ fontSize: { sm: 30, md: 40 } }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Box sx={{ mb: 2 }}>
                                                <Typography sx={{mb: 1}}>Acesso ao módulo de Personalização</Typography>
                                                <Box sx={{display:"flex"}}>
                                                    {
                                                        ContractsQuota.interfaceThemesCustomizationEnabled ?
                                                        <Box sx={{display: "flex", width: "100%", padding: 1}}>
                                                            <Check sx={{color: "primary.main", mr: 1}}/>
                                                            <Typography sx={{color: "primary.main"}}>Possui acesso a esse módulo</Typography>
                                                        </Box>
                                                        :
                                                        <Box sx={{display: "flex", width: "100%", padding: 1}}>
                                                            <Close sx={{color: "primary.main", mr: 1}}/>
                                                            <Typography sx={{color: "primary.main"}}>Não possui acesso a esse módulo</Typography>
                                                        </Box>
                                                    }
                                                </Box>
                                            </Box>
                                            <Divider/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            :
                                loading 
                                ?
                                <CircularProgress />
                                :
                                <Alert severity="error" sx={{backgroundColor:"#fdeded", color:"#5f2120"}}>Não foi possível buscar as métricas de uso do cliente.</Alert>
                        }
                    </TabPanel>
                </Box>
            </TabContext>
        </Box>
    )
}