import React, { useState } from "react";

import { Box, Typography, TextField } from "@mui/material";
import { Report, CheckCircle } from "@mui/icons-material";

import { useSearchParams } from "react-router-dom";

import AuthenticationService from "../../services/authentication";
import { LoadingButton } from "../../utils/Components";

import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import { AppNotification } from "../../utils/AppNotification"
import ErrorWrapper from "../../utils/ErrorWrapper";

function SendEmailConfirmation(): JSX.Element {
    const notification = useAppDispatch();

    //Redux props
    const [searchParams] = useSearchParams();

    //Store the recover password email state
    const [email, setEmail] = useState(searchParams.get('email') || '');
    const [emailSent, setEmailSent] = useState(false);

    //Store errors state
    const [error, setError] = useState(false);

    //Store 'loading' state
    const [loading, setLoading] = useState(false);

    //Event changes
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    //Functions
    function sendEmailConfirmationMail() {
        //Update loading state
        setLoading(true);
        AuthenticationService.sendAccountConfirmationMail(email)
            .then(() => setEmailSent(true))
            .catch(e => notification(show({ type: 'error', message: new ErrorWrapper(e).message })))
            .finally(() => setLoading(false))
    }

    return (
        <Box>
            <AppNotification />
            {!emailSent ?
                <Box>
                    <Typography variant='h5' className='ba' mb={3}>  <Report color='info' sx={{ mr: 1 }} />Email não confirmado</Typography>
                    <Typography variant='body2'>Digite seu e-mail abaixo para receber a confirmação de conta</Typography>
                    <TextField sx={{ mt: 2 }} required type='email' label="Insira o e-mail da sua conta" fullWidth variant="standard" value={email} onChange={handleEmailChange} size="small" />
                    <LoadingButton id='bc' disabled={!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))} loading={loading} variant="contained" fullWidth sx={{ mt: 5 }} onClick={sendEmailConfirmationMail}>  Enviar </LoadingButton>
                </Box>
                :
                <Box textAlign='center'>
                    <Typography variant='h5' className='ba'><CheckCircle color='success' sx={{ mr: 1 }} />Email enviado!</Typography>
                    <Typography variant='body1' mt={3}>
                        Acesse a caixa de entrada do email <b>{email}</b> e siga as instruções para confirmar a conta.
                    </Typography>
                    <Typography variant='caption' color='text.secondary' mt={1} >Caso não encontre o email verifique seu SPAM.</Typography>
                </Box>
            }
        </Box>
    );
};

export default SendEmailConfirmation;