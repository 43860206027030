export enum Actions {
    LOGIN = 'LOGIN',
    PASSWORD_CHANGED = 'PASSWORD_CHANGED',
    EMAIL_CHANGE_REQUEST = 'EMAIL_CHANGE_REQUEST',
    EMAIL_CHANGED = 'EMAIL_CHANGED',
    SESSION_EXPIRATION = 'SESSION_EXPIRATION',
    PASSWORD_EXPIRATION = 'PASSWORD_EXPIRATION'
}

type AccountAccessActivity = {
    label: string;
    value: Actions;
}

export const AccountAccessActivities: AccountAccessActivity[] = [
    {
        label: 'Acesso à conta',
        value: Actions.LOGIN
    },
    {
        label: 'Troca de senha',
        value: Actions.PASSWORD_CHANGED
    },
    {
        label: 'Solicitação de troca de email',
        value: Actions.EMAIL_CHANGE_REQUEST
    },
    {
        label: 'Alteração de email',
        value: Actions.EMAIL_CHANGED
    },
    {
        label: 'Expiração de sessões',
        value: Actions.SESSION_EXPIRATION
    },
    {
        label: 'Expiração de senha',
        value: Actions.PASSWORD_EXPIRATION
    }
]