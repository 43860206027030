import { useState, useContext } from 'react';
import { Alert, Box, Button, CircularProgress, DialogActions, Grid, IconButton, Popover, Tab, TextField, Tooltip, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Search, PersonSearch, History, BarChart, Lock, Badge, VerifiedUser, AdminPanelSettings, Error, LockOpen } from "@mui/icons-material";
import { RolesContext, Roles } from "../../contexts/roles";
import { AccountType } from '../../models/user';
import { Customer } from '../../models/customer';
import UserAccountsService from '../../services/user-accounts';
import ErrorWrapper from '../../utils/ErrorWrapper';
import Avatar from '../../utils/avatar/Avatar';

import RecentActivities from './RecentActivities';
import AuthenticationContext from '../../contexts/authentication';
import ProductsQuota from './ProductsQuotas';

import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import { AppNotification } from "../../utils/AppNotification"
import AuthenticationService from '../../services/authentication';

import BlockOrUnblockDialog from "../administrator/dialogs/BlockOrUnblockDialog"

export default function CustomerView() {

    //Context props
    const useAuthentication = () => { return useContext(AuthenticationContext) }
    const auth = useAuthentication();
    const userRoles = useContext(RolesContext);
    const notification = useAppDispatch();

    //User states
    const [userAccount, setUserAccount] = useState<Customer | null>(null);
    const [organization, setOrganization] = useState<Customer | null>(null);
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('');

    const [blockDialog, setBlockDialog] = useState(false);

    //Tab state
    const [tab, setTab] = useState('0');
    const handleTabChange = (event: React.SyntheticEvent, value: string) => { setTab(value) };

    //Popover states
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    /**
     * Search a customer or subuser by its email
     * @returns 
     */
    const searchCustomer = () => {
        //Reset state values
        resetState();

        //Check is email is in the correct format
        if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)))
            return notification(show({ type: 'error', message: `Insira um email válido.` }))

        //Search client  
        setLoading(true)
        UserAccountsService.find(email)
            .then((resp) => {
                setUserAccount(resp.userAccount)
                setOrganization(resp.organization)
            })
            .catch(err => {
                const e = new ErrorWrapper(err);
                notification(show({
                    type: 'error',
                    message: e.httpStatus === 404 ?
                        `Cliente não encontrado.` : e.httpStatus === 403 ?
                            `Ação não autorizada.` : `Ocorreu um erro: ${e.message}`
                }))
            })
            .finally(() => setLoading(false))
    }

    /**
     * Send an email to confirm the account's email
     */
    const sendEmailConfirmationMail = () => {
        if (!organization) return;
        AuthenticationService.sendAccountConfirmationMail(organization.user.email)
            .then(() => {
                handleClose()
                notification(show({
                    type: 'success',
                    message: `Um email de confirmação de conta foi enviado para: ${organization.user.email}`
                }))

            })
            .catch(e => notification(show({ type: 'error', message: new ErrorWrapper(e).message })))
            .finally(() => setLoading(false))
    }

    //Reset state values to initial
    const resetState = () => {
        setUserAccount(null)
        setOrganization(null)
    }

    //Capture the enter key press to signin
    const keyPress = (e: any) => { if (e.keyCode === 13) searchCustomer() }

    //Email change handler
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

    //Parse date 
    const normalize = (milliseconds: string | number | Date) => { return new Date(milliseconds).toLocaleDateString() };

    //Styles
    const card_header = { display: 'flex', alignItems: 'center' }
    const icon_header = { color: 'action.active', fontSize: 35, pr: 2 }
    const action_icon = { fontSize: 20, mr: 1, cursor: 'pointer' }
    const sb = { display: 'flex', justifyContent: 'space-between', mt: 1.5 }

    return (
        <Box sx={{ width: .8, maxWidth: 1000, margin: 'auto', mt: 5 }}>
            <Grid container alignItems='center'>
                <Grid item xs={2} sm={2}>
                    <PersonSearch color='action' sx={{ fontSize: 75 }} />
                </Grid>
                <Grid item xs sm md sx={{ display: 'flex' }}>
                    <TextField
                        required fullWidth type="email" variant='standard'
                        label='Insira o email do cliente que deseja consultar'
                        onChange={handleChange} value={email} onKeyDown={keyPress} />
                    <IconButton onClick={searchCustomer}><Search color='action' /></IconButton>
                </Grid>
            </Grid>
            {organization && userAccount && !loading ?
                <Box mt={3}>
                    {userAccount.type === AccountType.CUSTOMER ?
                        <Grid container alignItems='center' sx={{ bgcolor: 'success.light', borderRadius: 1, p: 1 }}>
                            <VerifiedUser color='success' />
                            <Typography ml={2}>Este cliente é uma organização</Typography>
                        </Grid>
                        :
                        <Grid container alignItems='center' sx={{ bgcolor: '#E7F3FF', borderRadius: 1, p: 1 }}>
                            <Badge color='action' />
                            <Typography ml={2}> Este cliente pertence a uma organização</Typography>
                        </Grid>

                    }
                    {
                        userAccount.user.blockDate ?
                            <Alert severity='error' variant='outlined' sx={{ mt: 2, p: 0, alignItems: 'center', display: 'flex' }}>
                                <strong>Este usuário está bloqueado.</strong>
                                {
                                    !userRoles.hasRole(auth, Roles.CM_SM) ? null :
                                        <Button color='inherit' className='nh' onClick={() => setBlockDialog(true)}>Clique aqui para desbloquear.</Button>
                                }
                            </Alert>
                            : null
                    }
                    <Box mt={2} sx={{ display: 'flex' }}>
                        <Typography variant='h6'>Exibindo informações de:</Typography>
                        <Typography variant='h6' color='primary' ml={1}>{userAccount.preferredName}</Typography>

                    </Box>
                    <Typography variant='body2'>Aqui você pode consultar as informações pessoais do cliente, cotas de consumo, atividades recentes e mais.</Typography>
                    <Grid container gap={3} mt={5} >
                        <Grid item className='bd' p={3} width={1} md lg>

                            <Box sx={card_header}>
                                <Avatar user={userAccount.user} sx={icon_header} mr={2} />
                                <Typography variant='body1' color='text.secondary'>CONTA</Typography>
                            </Box>
                            <Box px={{ sm: 4, md: 3 }} mt={2}>
                                <Box sx={sb}>
                                    <Typography variant='body1' color='text.secondary'>Nome completo:</Typography>
                                    <Typography variant='body1' color='text' ml={2}>{userAccount.fullname}</Typography>
                                </Box>
                                <Box sx={sb}>
                                    <Typography variant='body1' color='text.secondary'>Nome de preferência:</Typography>
                                    <Typography variant='body1' color='text' ml={2}>{userAccount.preferredName}</Typography>
                                </Box>
                                <Box sx={sb}>
                                    <Typography variant='body1' color='text.secondary'>Data de cadastro:</Typography>
                                    <Typography variant='body1' color='text' ml={2}>{normalize(userAccount.creationDate)}</Typography>
                                </Box>
                                <Box sx={sb}>
                                    <Typography variant='body1' color='text.secondary'>Email:</Typography>
                                    <Typography variant='body1' color='text' ml={2}>{userAccount.email}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item className='bd' width={1} p={2} md lg>
                            <Box sx={card_header}>
                                <Lock sx={icon_header} />
                                <Typography variant='body1' color='text.secondary'>SEGURANÇA</Typography>
                            </Box>

                            <Box px={{ sm: 4, md: 3 }} mt={2}>
                                <Box sx={sb}>
                                    <Typography variant='body1' color='text.secondary'>Primeiro acesso:</Typography>
                                    <Typography variant='body1' color='text' ml={2}>{normalize(userAccount.user.minimumSessionCreationDate)}</Typography>
                                </Box>
                                <Box sx={sb}>
                                    <Typography variant='body1' color='text.secondary'>Confirmação de email:</Typography>
                                    {
                                        userAccount.user.emailConfirmationDate ?
                                            <Typography variant='body1' color='text' ml={2}>{normalize(userAccount.user.emailConfirmationDate)}</Typography>
                                            :
                                            <Typography variant='body1' color='info.main' ml={2} alignItems='flex-start' display='flex'>
                                                Não confirmado
                                                <Tooltip title='Reenviar email de confirmação' placement='top'>
                                                    <IconButton onClick={handleClick} aria-describedby={id} color='info' sx={{ p: 0 }}>
                                                        <Error sx={{ fontSize: 20, ml: .5, cursor: 'pointer' }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Popover
                                                    id={id}
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <Box p={2} >
                                                        <Typography display='flex'>
                                                            <Error sx={{ fontSize: 20, mr: 1 }} color='info' />
                                                            Deseja reenviar o email de confirmação de conta?
                                                        </Typography>
                                                        <DialogActions sx={{ p: 0, mt: 1 }}>
                                                            <Button onClick={handleClose} size="small">Cancelar</Button>
                                                            <Button onClick={sendEmailConfirmationMail} size="small" id='bc'
                                                                className='nh' variant='contained'>Sim</Button>
                                                        </DialogActions>
                                                    </Box>
                                                </Popover>
                                            </Typography>
                                    }
                                </Box>
                                <Box sx={sb}>
                                    <Typography variant='body1' color='text.secondary'>Expiração de senha:</Typography>
                                    {
                                        userAccount.user.passwordExpirationDate ?
                                            <Typography variant='body1' color='error' ml={2}>{normalize(userAccount.user.passwordExpirationDate)}</Typography>
                                            :
                                            <Typography variant='body1' color='success.main' ml={2}>Senha válida</Typography>
                                    }
                                </Box>
                                <Box sx={sb}>
                                    <Typography variant='body1' color='text.secondary' sx={{ display: 'flex' }}>
                                        {
                                            userAccount.user.blockDate && userRoles.hasRole(auth, Roles.CM_SM) ?
                                                <Tooltip title='Desbloquear usuário' placement='top'>
                                                    <Lock color='error' sx={action_icon} onClick={() => setBlockDialog(true)} />
                                                </Tooltip>
                                                : userRoles.hasRole(auth, Roles.CM_SM) ?
                                                    <Tooltip title='Bloquear usuário' placement='top'>
                                                        <LockOpen color='success' sx={action_icon} onClick={() => setBlockDialog(true)} />
                                                    </Tooltip> : null
                                        }

                                        Bloqueio da conta:
                                    </Typography>
                                    {
                                        userAccount.user.blockDate ?
                                            <Typography variant='body1' color='error' ml={2}>{normalize(userAccount.user.blockDate)}</Typography>
                                            :
                                            <Typography variant='body1' color='success.main' ml={2}>Conta válida</Typography>
                                    }
                                </Box>
                            </Box>

                        </Grid>
                    </Grid>
                    <Box width={{ sm: .95, md: .45 }} className='bd' p={2} mt={3} >
                        <Box sx={card_header}>
                            <AdminPanelSettings sx={icon_header} />
                            <Typography variant='body1' color='text.secondary'>ORGANIZAÇÃO</Typography>
                        </Box>
                        <Box px={{ sm: 4, md: 3 }} mt={2}>
                            <Box sx={sb}>
                                <Typography variant='body1' color='text.secondary'>Nome completo:</Typography>
                                <Typography variant='body1' color='text' ml={2}>{organization.fullname}</Typography>
                            </Box>
                            <Box sx={sb}>
                                <Typography variant='body1' color='text.secondary'>Nome de preferência:</Typography>
                                <Typography variant='body1' color='text' ml={2}>{organization.preferredName}</Typography>
                            </Box>
                            <Box sx={sb}>
                                <Typography variant='body1' color='text.secondary'>Data de cadastro:</Typography>
                                <Typography variant='body1' color='text' ml={2}>{normalize(organization.creationDate)}</Typography>
                            </Box>
                            <Box sx={sb}>
                                <Typography variant='body1' color='text.secondary'>Email:</Typography>
                                <Typography variant='body1' color='text' ml={2}>{organization.email}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className='bd' my={5} px={4}>
                        <TabContext value={tab}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: 1 }}>
                                <TabList onChange={handleTabChange}>
                                    <Tab icon={<BarChart />} iconPosition='start' label='Licenças de software' value='0' />
                                    {
                                        !userRoles.hasRole(auth, Roles.CM_SM) ? null :
                                            <Tab icon={<History />} iconPosition='start' label='Atividades recentes' value='1' />
                                    }
                                    {
                                        !userRoles.hasRole(auth, Roles.CM_SM) ? null :
                                            <Tab icon={<Lock />} iconPosition='start' label='Segurança' value='2' />
                                    }
                                </TabList>
                            </Box>
                            <TabPanel value='0' sx={{ p: 0 }}>
                                <ProductsQuota organization={organization} sendEmailConfirmationMail={sendEmailConfirmationMail} />
                            </TabPanel>
                            {
                                !userRoles.hasRole(auth, Roles.CM_SM) ? null :

                                    <TabPanel value='1' sx={{ p: 0 }}> <RecentActivities uuid={userAccount.uuid} /> </TabPanel>

                            }
                            {
                                !userRoles.hasRole(auth, Roles.CM_SM) ? null : <TabPanel value='2' sx={{ p: 0 }}>
                                    <Box p={3} >
                                        <Typography mb={2}>Ações de segurança aplicadas a este usuário</Typography>
                                        {
                                            userAccount.user.blockDate ?
                                                <Button variant='contained' onClick={() => setBlockDialog(true)} className='unblockBtn'
                                                    startIcon={<LockOpen />}>Desbloquear usuário</Button>
                                                :
                                                <Button variant='contained' onClick={() => setBlockDialog(true)} className='blockBtn'
                                                    startIcon={<Lock />}> Bloquear usuário</Button>
                                        }
                                    </Box>
                                </TabPanel>
                            }

                        </TabContext>
                    </Box>

                </Box>
                :
                !loading ? null :
                    <Box width={1} mt={10} textAlign='center'><CircularProgress /></Box>
            }
            <AppNotification />
            <BlockOrUnblockDialog open={blockDialog} close={() => setBlockDialog(false)} user={userAccount}
                success={(block: boolean) => {
                    if (userAccount) userAccount.user.blockDate = block ? new Date() : null;
                    setBlockDialog(false)
                }}
            />
        </Box >
    );
}