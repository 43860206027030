import React, { useState, useRef } from 'react'
import { Avatar as AvatarMUI, Button, Box, Dialog, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import Avatar from './Avatar';
import { AddAPhoto } from "@mui/icons-material";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const AvatarUploader = (props: React.ComponentProps<any>) => {

    const { user, sx, onUpload, onRemove } = props;

    const [open, setOpen] = useState(false)
    const [image, setImage] = useState('');
    const [cropper, setCropper] = useState<any>();
    const [cropData, setCropData] = useState('');

    const uploadImage = (e: any) => {
        setOpen(true)
        e.preventDefault();

        const reader = new FileReader();
        reader.onload = () => { setImage(reader.result as any) };
        reader.readAsDataURL(e.target.files[0]);

        //Reset input file value
        e.target.value = null;
    };

    const cropImage = () => {
        if (typeof cropper !== "undefined") {
            const cropSettings = { maxWidth: 225, maxHeight: 225 }
            setCropData(cropper.getCroppedCanvas(cropSettings).toDataURL())
            cropper.getCroppedCanvas(cropSettings).toBlob((blob: Blob) => onUpload(blob))

            setOpen(false)
        }
    };

    const removeAvatar = () => {
        if (user) {
            user.hasAvatar = false;
            onRemove();
        }
        setCropData('');
        setAnchorAuthUserMenu(null)
    }

    const [anchorAuthUserMenu, setAnchorAuthUserMenu] = useState<HTMLElement | null>(null);
    
    //Refs
    const userMenuRef = useRef<HTMLDivElement | null>(null);
    const fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();

    //Event handlers
    const handleUserMenuClick = () => { setAnchorAuthUserMenu(userMenuRef.current || null) }
    const openInputFile = () => {
        fileInputRef.current?.click();
        setAnchorAuthUserMenu(null)
    }

    const style = { width: sx.fontSize, height: sx.fontSize }
    return (
        <>
            <input accept='image/*' hidden type='file' onChange={uploadImage} ref={fileInputRef} />
            <Menu anchorEl={anchorAuthUserMenu} open={anchorAuthUserMenu != null} onClose={() => { setAnchorAuthUserMenu(null) }}>
                <MenuItem onClick={openInputFile} color='secondary'> Alterar </MenuItem>
                <MenuItem onClick={() => removeAvatar()} color='secondary'> Remover </MenuItem>
            </Menu>
            <Box ref={userMenuRef}>
                {
                    cropData ?
                        <IconButton onClick={handleUserMenuClick}> <AvatarMUI src={cropData} sx={style} /> </IconButton>
                        :
                        user ?
                            <IconButton onClick={handleUserMenuClick}><Avatar user={user} sx={sx} /></IconButton> :
                            <IconButton onClick={openInputFile}><AddAPhoto /></IconButton>
                }
            </Box>
            <Dialog onClose={() => { setOpen(false); setImage('') }} open={open} sx={{ overflow: 'hidden' }}>
                <Tooltip title='Utilize o scroll do mouse para dar zoom na imagem' arrow placement='top' >
                    <Box>
                        <Cropper
                            style={{ maxHeight: 400, width: '100%' }}
                            aspectRatio={1 / 1}
                            src={image}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            onInitialized={(instance: any) => setCropper(instance)}
                            guides={true}
                        />
                        <Box textAlign='end'>
                            <Button onClick={() => setOpen(false)} color='error'>Cancelar</Button>
                            <Button onClick={cropImage} sx={{ mx: 2 }}>Escolher imagem</Button>
                        </Box>
                    </Box>
                </Tooltip>
            </Dialog >
        </>
    )
}
export default AvatarUploader;

