import { AuthenticatedUser, AuthenticationContextData } from "../models/user";
import AuthenticationService from "../services/authentication"

//Local storage variables
const storage = localStorage;
const STORE_USER = "App@user"
const STORE_TOKEN = "Dev@token";

/**
 * Class that store authenticated user data on application cache
 */
class AuthenticationStore implements AuthenticationContextData {

    isAuthenticated(): boolean {
        return this.user() != null;
    }

    user(): AuthenticatedUser {
        const storageData = storage.getItem(STORE_USER);
        return (storageData) ? JSON.parse(storageData) : null;
    }

    getToken(): string | null {
        return storage.getItem(STORE_TOKEN);
    }

    setToken(token: string) {
        storage.setItem(STORE_TOKEN, token);
    }

    signout(callback: VoidFunction) {

        storage.removeItem(STORE_USER);
        if (callback) callback();
    }

    hasStlAtLeast(requiredStlLevel: number): boolean {
        return this.user().session.stl >= requiredStlLevel;
    }

    async renewSessionWithPassword(password : string, trustDevice : boolean) : Promise<AuthenticatedUser> {
        const authenticationResponse = await AuthenticationService.renewTokenWithPassword({
            password: password,
            trustDevice: trustDevice
        }); 

        // Store the authentication token on storage
        this.setToken(authenticationResponse.token);

        //Return the updated user from server
        return await this.updatedUser();
    }

    
    async authenticateWithEmailAndPassword(email: string, password: string, trustDevice: boolean): Promise<any> {
        //Authenticated the user
        const authenticationResponse = await AuthenticationService.authenticateWithEmailAndPassword({
            email: email,
            password: password,
            trustDevice: trustDevice
        });

        // Store the authentication token on storage
        this.setToken(authenticationResponse.token);
        
        //Return the updated user from server
        return await this.updatedUser();
    }

    async updatedUser(): Promise<AuthenticatedUser> {
        //Return user data from server
        const authenticatedUser = await AuthenticationService.fetchAuthenticatedUserData();

        //Store the user data
        storage.setItem(STORE_USER, JSON.stringify(authenticatedUser));

        return authenticatedUser;
    }
};

export default new AuthenticationStore();
