import { createTheme } from '@mui/material/styles';

const tableStyle = {
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '&.MuiDataGrid-root': {
    border: '1px solid #EBEBEB'
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell': {
    borderBottom: '1px solid #EBEBEB'
  },
  '&.MuiDataGrid-root .MuiDataGrid-footerContainer': {
    borderTop: '1px solid #EBEBEB'
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeaders': {
    borderBottom: '1px solid #EBEBEB',
    zIndex: 1
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 600
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '&.MuiDataGrid-root .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '&.MuiDataGrid-root .MuiDataGrid-row:hover': {
    backgroundColor: '#E7F3FF',
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:hover': {
    borderBottom: '1px solid #EBEBEB'
  },
  '&.MuiDataGrid-root .MuiDataGrid-virtualScroller': {
    background: '#FFF'
  }
}

const Theme = createTheme({
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: 1
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#DCDCDD',
          borderBottomWidth: 1
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderBottom: '.8px solid #DCDCDD',
          color: '#242424',
          ":hover": {
            borderBottom: '1px solid #3590F3',
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#909399'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#040F16',
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 13,
          fontFamily: 'Nunito',
          maxWidth: 'none'
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: '#EBEEF5'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Nunito'
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 16
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          background: '#3590F3',
          "&:disabled": {
            backgroundColor: '#A9CCF1',
            color: '#FFF'
          }
        },
        text: {
          "&:disabled": {
            backgroundColor: '#E7F3FF',
          }
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingBottom: 15,
          paddingRight: 22
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          maxWidth: 400
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          fontFamily: 'Nunito'
        }
      }
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Nunito',
      color: '#242424'
    },
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    mode: 'dark',
    success: {
      main: '#54B948', //green
      light: '#E1F3D8'
    },
    info: {
      main: '#FF7B3C' //orange
    },
    error: {
      main: '#FF4B3C', //red
      light: '#FDE9E9'
    },
    action: {
      active: '#3590F3', //blue
    },
    primary: {
      main: '#3590F3', //blue
      dark: '#040F16',
      light: '#EBEEF5',
    },
    secondary: {
      main: "#EBEEF5" //blue
    },
    text: {
      primary: '#424345',
      secondary: "#606266",
      disabled: "#909399"
    },
    background: {
      paper: "#FDFDFD"
    },
    divider: '#DCDCDD'
  },
})

export { Theme, tableStyle };
