import { Box, Button, Container, LinearProgress, Typography } from "@mui/material";
import { Check, Error as ErrorIcon } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import jwt from "jwt-decode"

import { Logo, LogoBar } from "../../utils/Components";
import AuthenticationService from "../../services/authentication";

/**
 * Enum used to store token validation status
 */
enum TokenValidationStatus {
    Processing,
    Failed,
    Validated
}

const ConfirmEmailWithToken = (): JSX.Element => {

    //Context prop used to navigate to another page
    const navigate = useNavigate();

    // content used to get query parameter data
    const [searchParams] = useSearchParams();

    function TokenValidationProgress(): JSX.Element | null {
        //Store the state of the token verification step
        const [tokenVerificationStatus, setTokenVerificationStatus] = useState<TokenValidationStatus>(TokenValidationStatus.Processing);
        const [token, setToken] = useState<string | null>(null);
        const [progress, setProgress] = useState(0);

        //Effect used to validate the token when the component is first loaded
        useEffect(() => {
            try {

                //Get the token from query parameter
                const tokenFromQuery = searchParams.get("token") || null;
                if (tokenFromQuery === null) {
                    console.error("Token not found");
                    throw new Error("Token not found");
                }

                //Get the decoded JWT
                const decodedJwt = jwt(tokenFromQuery) as any;
                if (decodedJwt === null) {
                    console.error("Invalid token");
                    throw new Error("Invalid token");
                }

                //Get the token expiration date to validate if it is expired
                const tokenExpirationTimestamp = decodedJwt.exp * 1000;
                const now = new Date().getTime();

                //Verify is the token expired
                if (tokenExpirationTimestamp < now) {
                    console.error("The verification token expired");
                    throw new Error("The verification token expired");
                }

                /**
                 * Call the API to confirm the account
                 */
                AuthenticationService.confirmEmailWithToken(tokenFromQuery)
                    .then(() => {
                        //If any error is detected set the progress to check and set a timeout to change to step 1 (change password)
                        setTokenVerificationStatus(TokenValidationStatus.Validated);
                        setToken(tokenFromQuery);
                    })
                    .catch((e) => {
                        console.error("The verification on server failed", e);
                        setTokenVerificationStatus(TokenValidationStatus.Failed);
                    })


            } catch (error) {
                setTokenVerificationStatus(TokenValidationStatus.Failed);
            }
        }, []);

        //Effect used to automatically skip to reset password step when the token is set
        const Timeout = 6000;
        useEffect(() => {
            if (token !== null) {
                //Go to login after timeout
                setTimeout(() => {
                    navigate("/login", { replace: true })
                }, Timeout);

                //Progress bar 
                const interval = setInterval(() => {
                    setProgress((oldProgress) => {
                        //Keep always on 100
                        if (oldProgress >= 80) {
                            clearInterval(interval);
                            return 100;
                        }
                        return oldProgress + 10;
                    });
                }, Timeout / 10);
            }
        }, [token]);

        switch (tokenVerificationStatus) {
            case TokenValidationStatus.Processing:
                return (
                    <Box sx={{ textAlign: 'center', my: 2 }}>
                        <Typography variant='h6' >Validando a conta...</Typography>
                        <LinearProgress sx={{ my: 2 }} />
                    </Box>
                );
            case TokenValidationStatus.Validated:
                return (
                    <Box sx={{ mt: 2 }}>
                        <Typography variant='h5' className='ba' mb={3}> <Check color="success" fontSize="large" sx={{ mr: 2 }} />Token validado!</Typography>
                        <Typography variant='body2' sx={{ mt: 3, mb: 1, textAlign: 'center' }}> Agora é só redefinir sua senha.</Typography>
                        <LinearProgress variant="determinate" value={progress} />
                        <Button sx={{ textTransform: 'none', mt: 1 }} onClick={() => navigate("/login", { replace: true })}>Ir para o login</Button>
                    </Box>
                );
            case TokenValidationStatus.Failed:
                return (
                    <Box sx={{ textAlign: 'center', mt: 2 }}>
                        <ErrorIcon color="error" fontSize="large" sx={{ mr: 1 }} />
                        <Typography variant='h6' sx={{ mb: 2 }}>
                            Ocorreu um erro ao recuperar a conta</Typography>
                        <Link to='/recover-my-password' >
                            <Button sx={{ textTransform: 'none', mt: 1 }}>Iniciar recuperação de senha novamente</Button>
                        </Link>
                    </Box>
                )
            default:
                return null;
        }
    }

    /**
     * Main component
     */
    return (
        <Container id='formContainer' sx={{ bgcolor: 'primary.dark' }}>
            <Logo mode={'primary.light'} />
            <LogoBar size={400} />
            <Box id='box'><TokenValidationProgress /></Box>
        </Container>
    );
};

export default ConfirmEmailWithToken;