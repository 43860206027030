import React, { useState, useEffect } from "react";
import { Box, Button, Container, LinearProgress, TextField, Typography } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { Link, useSearchParams } from "react-router-dom";
import jwt from "jwt-decode"
import { LoadingButton, Logo, LogoBar, Notification } from "../../utils/Components";
import AuthenticationService from "../../services/authentication"
import ErrorWrapper from "../../utils/ErrorWrapper";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import { AppNotification } from "../../utils/AppNotification"

type UpdatePasswordWithEmailTokenDTO = {
    newPassword: string,
    token: string | null
}

/**
 * Enum used to store token validation status
 */
enum TokenValidationStatus {
    Processing,
    Failed,
    Validated
}

const ResetPasswordByEmail = (): JSX.Element => {
    const notification = useAppDispatch();

    //Context prop used to navigate to another page
    const navigate = useNavigate();

    // content used to get query parameter data
    const [searchParams] = useSearchParams();

    //Store the state of the password confirmation field
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    //Store loading state
    const [loading, setLoading] = useState(false);

    //Store the state of the token verification step
    const [tokenVerificationStatus, setTokenVerificationStatus] = useState<TokenValidationStatus>(TokenValidationStatus.Processing);

    //Store the state of the DTO used to reset the password
    const [resetPasswordDTO, setResetPasswordDTO] = useState<UpdatePasswordWithEmailTokenDTO>({
        newPassword: '',
        token: null
    });

    //Event handlers
    const handleChangeNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResetPasswordDTO({ ...resetPasswordDTO, newPassword: event.target.value })
    };
    const handleChangePasswordConfirmation = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordConfirmation(event.target.value);
    }

    //Inner components
    function TokenValidationProgress(): JSX.Element | null {
        //Validate the token and set its status
        useEffect(() => {
            if (resetPasswordDTO.token === null) {
                try {
                    //Get the token from query parameter
                    const tokenFromQuery = searchParams.get("token") || null;
                    if (tokenFromQuery === null) {
                        console.error("Token not found");
                        throw new Error("Token not found");
                    }

                    //Get the decoded JWT
                    const decodedJwt = jwt(tokenFromQuery) as any;

                    if (decodedJwt === null) {
                        console.error("Invalid token");
                        throw new Error("Invalid token");
                    }

                    //Get the token expiration date to validate if it is expired
                    const tokenExpirationTimestamp = decodedJwt.exp * 1000;
                    const now = new Date().getTime();

                    //Verify is the token expired
                    if (tokenExpirationTimestamp < now) {
                        console.error("The verification token expired");
                        throw new Error("The verification token expired");
                    }

                    //If any error is detected set the progress to check and set a timeout to change to step 1 (change password)
                    setTimeout(() => { setTokenVerificationStatus(TokenValidationStatus.Validated) }, 2500);
                    setResetPasswordDTO({ ...resetPasswordDTO, token: tokenFromQuery });
                } catch (error) {
                    setTokenVerificationStatus(TokenValidationStatus.Failed);
                }
            }
        }, []);

        //Switch between token verification status components
        switch (tokenVerificationStatus) {
            case TokenValidationStatus.Processing:
                return (
                    <Box sx={{ textAlign: 'center', my: 2 }}>
                        <Typography variant='h6' >Iniciando a recuperação da conta...</Typography>
                        <LinearProgress sx={{ my: 2 }} />
                    </Box>
                );
            case TokenValidationStatus.Failed:
                return (
                    <Box sx={{ textAlign: 'center', mt: 2 }}>
                        <ErrorIcon color="error" fontSize="large" sx={{ mr: 1 }} />
                        <Typography variant='h6' sx={{ mb: 2 }}>
                            Ocorreu um erro ao recuperar a conta</Typography>
                        <Link to='/recover-my-password' >
                            <Button sx={{ textTransform: 'none', mt: 1 }}>Iniciar recuperação de senha novamente</Button>
                        </Link>
                    </Box>
                );
            default:
                return null;
        }
    }

    //Function
    function resetPassword() {
        //Ignore if the token is not set
        if (resetPasswordDTO.token === null) {
            console.error("Token not set on transfer model", resetPasswordDTO);
            return;
        }

        //Call the API
        setLoading(true);
        AuthenticationService.resetPasswordByEmailToken(resetPasswordDTO.newPassword, resetPasswordDTO.token)
            .then(() => {
                //Go to login
                setTimeout(() => navigate('/login', { replace: true }), 2000);
                notification(show({ type: 'success', message: `Senha alterada` }))
            })
            .catch(e => notification(show({ type: 'error', message: new ErrorWrapper(e).message })))
            .finally(() => setLoading(false))
    }

    const validatePassword = () => {
        if (resetPasswordDTO.newPassword.length > 6 &&
            resetPasswordDTO.newPassword === passwordConfirmation) return false;
        return true;
    }

    return (
        <Container id='formContainer' sx={{ bgcolor: 'primary.dark' }}>
            <AppNotification />
            <Logo mode={'primary.light'} />
            <LogoBar size={400} />
            <Box id='box'>
                {tokenVerificationStatus != TokenValidationStatus.Validated ?
                    <TokenValidationProgress /> :
                    <Box>
                        <Typography variant='h5' sx={{ mb: 2 }} >Insira sua nova senha</Typography>
                        <Typography variant='caption' >Lembre-se de usar seis ou mais caracteres contendo uma combinação de letras, números e símbolos.</Typography>
                        <TextField
                            sx={{ mt: 2 }}
                            variant='standard'
                            value={resetPasswordDTO.newPassword}
                            label='Nova senha'
                            type='password'
                            onChange={handleChangeNewPassword}
                            fullWidth
                        />
                        <TextField
                            sx={{ mt: 2, mb: 5 }}
                            variant='standard'
                            value={passwordConfirmation}
                            label='Confirme a nova senha'
                            type='password'
                            onChange={handleChangePasswordConfirmation}
                            fullWidth
                        />
                        <LoadingButton fullWidth variant="contained" id='bc' loading={loading} disabled={validatePassword()} onClick={resetPassword}>
                            Alterar senha
                        </LoadingButton>
                    </Box>
                }
                <Link to='/' style={{ width: 'fit-content' }}>
                    <Button sx={{ textTransform: 'none', mt: 1 }}>Voltar para o login</Button>
                </Link>
            </Box>
        </Container>
    );
};

export default ResetPasswordByEmail;