import { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import AuthenticationContext from "../../contexts/authentication";

import { Box, Button, Container, Typography } from "@mui/material";
import { Report, Error as ErrorIcon } from "@mui/icons-material";

import { LoadingButton, Logo, LogoBar } from "../../utils/Components";
import SendEmailConfirmation from "./SendEmailConfirmation";
import RenewPassword from "./RenewPassword";

const AuthStatusRouter = (): JSX.Element => {

    //Context prop
    const params = useParams();

    function AuthStatusInfo(): JSX.Element {
        const statusCode = (params.statusCode != null && /\d+/.test(params.statusCode)) ? params.statusCode : "?";

        switch (statusCode) {
            case "401": //User credentials/password expired
                return <RenewPassword />;

            case "402": //Token sent by the client is invalid

            case "404": //User session expired
                return <ExpiredSession />;

            case "405": //User account of the client is not confirmed
                return <SendEmailConfirmation />;

            case "406": //User account blocked
                return <BlockedAccount />;

            default:
                return (
                    <Box>
                        <Typography variant='h6' className='ba' mb={2} >
                            <Report color='info' sx={{ mr: 1 }} />Acesso negado</Typography>
                        <Typography>Código de status do erro: <b>{statusCode}</b></Typography>
                    </Box>
                )
        }
    }

    const ExpiredSession = (): JSX.Element => {
        return (
            <Box>
                <Typography variant='h6' className='ba' mb={3}>
                    <Report color='info' sx={{ mr: 1 }} />Sessão expirada ou invalidada.</Typography>
                <Link to="/login">
                    <Button variant='contained' id='bc' fullWidth>Faça login novamente</Button>
                </Link>
            </Box>
        )
    }

    const BlockedAccount = (): JSX.Element => {
        return (
            <Box>
                <Typography variant='h5' className='ba' mb={3}>
                    <ErrorIcon color="error" fontSize="medium" sx={{ mr: 1 }} />Conta bloqueada</Typography>
                <Typography variant="subtitle1">Conta bloqueada por um administrador.</Typography>
                <Typography variant="subtitle2">Em caso de dúvidas sobre o bloqueio da sua conta entre em contato com o suporte Klaus</Typography>
            </Box>
        );
    };

    return (
        <Container id='formContainer' sx={{ bgcolor: 'primary.dark' }}>
            <Logo mode={'primary.light'} />
            <LogoBar size={400} />
            <Box id='box'>
                <AuthStatusInfo />
                <Link to='/login' style={{ width: 'fit-content' }}>
                    <Button sx={{ textTransform: 'none', mt: 1 }}>Voltar</Button>
                </Link>
            </Box>
        </Container>
    );
}

export default AuthStatusRouter;