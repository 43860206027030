import { useState, useContext, useEffect } from "react"
import { Button, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { Lock, LockOpen } from "@mui/icons-material";
import { LoadingButton } from "../../../utils/Components";

import RenewAccessDialog from '../../account-management/RenewAccessDialog'
import StlInterceptor from '../../../contexts/stl'
import { SecurityTierLevels } from '../../../store/stl'

import AdministratorService from "../../../services/administrator";
import ErrorWrapper from "../../../utils/ErrorWrapper";

import { useAppDispatch } from '../../../redux/hooks';
import { show } from "../../../redux/features/app-global-notification/app-global-notification-slice"
import { AppNotification } from "../../../utils/AppNotification"
import { AccountType } from "../../../models/user";
import UserAccountService from "../../../services/user-accounts";

/**
 * Dialog to confirm actions UI
 * @returns {JSX.Element}
 */
export default function BlockOrUnblockDialog(props: React.ComponentProps<any>) {
    const notification = useAppDispatch();

    //Props
    const { auth, user, open, close, success } = props;

    //Flag that indicates if user is blocked
    const isUserBlocked = user && user.user.blockDate ? true : false;

    //Context props
    const stl = useContext(StlInterceptor);

    //Renew access dialog 
    const [renewSessionDialog, setRenewSessionDialog] = useState(false);

    //State props
    const [error, setError] = useState({ open: false, message: '' })
    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState('');

    useEffect(() => {
        setReason('')
        setLoading(false)
        setError({ open: false, message: '' })
    }, [open, close])

    const checkSTLlevel = () => {
        setLoading(true);
        stl.require(auth, SecurityTierLevels.MaxLevel)
            .then(() => updateUser())
            .catch((e) => setRenewSessionDialog(true))
    }

    const onSessionRenewed = () => {
        setRenewSessionDialog(false);
        updateUser()
    }

    const updateUser = async () => {
        setLoading(true);
        if (isUserBlocked) {
            try {
                let blockedUser = null;
                if (user.type === AccountType.CUSTOMER || user.type === AccountType.CUSTOMER_SUBUSER) {
                    blockedUser = await UserAccountService.unblock(reason, user.user.uuid)
                }
                else {
                    blockedUser = await AdministratorService.unblock(reason, user.user.uuid)
                }

                if (blockedUser) success(false)
            }
            catch (e) {
                handleError(e)
            }
            finally {
                setLoading(false)
            }
        }
        else {
            try {
                let blockedUser = null;
                if (user.type === AccountType.CUSTOMER || user.type === AccountType.CUSTOMER_SUBUSER) {
                    blockedUser = await UserAccountService.block(reason, user.user.uuid)
                }
                else {
                    blockedUser = await AdministratorService.block(reason, user.user.uuid)
                }

                if (blockedUser) success(true)
            }
            catch (e) {
                handleError(e)
            }
            finally {
                setLoading(false)
            }
        }
    }

    //Handlers
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setReason(event.target.value);
    const handleError = (error: any) => {
        const e = new ErrorWrapper(error);
        notification(show({ type: 'error', message: e.httpStatus == 422 ? `Dados inválidos.` : `Ocorreu um erro: ${e.message}` }))
    }

    return (
        <Dialog onClose={close} open={open} >
            {
                isUserBlocked ?
                    <Box>
                        <DialogTitle sx={{ pb: 0 }} className='ba'><LockOpen color='success' sx={{ mr: 2 }} />Desbloquear conta</DialogTitle>
                        <DialogContent sx={{ maxWidth: 425, mt: 2 }}>
                            <DialogContentText> Ao realizar esta ação, este usuário retomará o acesso à sua conta. </DialogContentText>
                            <TextField
                                label='Motivo do desbloqueio' required sx={{ my: 3 }}
                                fullWidth maxRows={4} multiline
                                inputProps={{ maxLength: 500, style: { height: 100 } }}
                                value={reason} onChange={handleChange}
                            />

                            <DialogContentText>Tem certeza que deseja desbloquear os acessos de conta do usuário: <b>{user?.preferredName}</b>?</DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ p: 3, pt: 1 }}>
                            <Button onClick={close}>Cancelar</Button>
                            <LoadingButton
                                disabled={reason.length === 0 || reason.length >= 500}
                                startIcon={<LockOpen />} loading={loading}
                                variant='contained' className='unblockBtn'
                                onClick={checkSTLlevel}> Desbloquear </LoadingButton>
                        </DialogActions>
                    </Box>
                    :
                    <Box>
                        <DialogTitle sx={{ pb: 0 }} className='ba'><Lock color='error' sx={{ mr: 2 }} />Bloquear conta</DialogTitle>
                        <DialogContent sx={{ maxWidth: 425, mt: 2 }}>
                            <DialogContentText> Ao realizar esta ação, este usuário ficará impossibilitado de acessar sua conta. </DialogContentText>
                            <TextField
                                label='Motivo do bloqueio' required sx={{ my: 3 }}
                                fullWidth maxRows={4} multiline
                                inputProps={{ maxLength: 500, style: { height: 100 } }}
                                value={reason} onChange={handleChange}
                            />
                            <DialogContentText>Tem certeza que deseja bloquear os acessos de conta do usuário: <b>{user?.preferredName}</b>?</DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ p: 3, pt: 1 }}>
                            <Button onClick={close}>Cancelar</Button>
                            <LoadingButton
                                disabled={reason.length === 0 || reason.length >= 500}
                                startIcon={<Lock />} loading={loading}
                                variant='contained' className='blockBtn'
                                onClick={checkSTLlevel}> Bloquear </LoadingButton>
                        </DialogActions>
                    </Box>
            }
            <AppNotification />
            <RenewAccessDialog sessionRenewed={() => onSessionRenewed()} open={renewSessionDialog} />
        </Dialog >
    );
}