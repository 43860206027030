import AccountsErpAPI from "./accountsErpApi";

import { Pagination } from "../models/pagination";
import { PreferredRegion, UserAccount } from "../models/user";
import { Administrator } from "../models/administrator";
import { AdministratorActivity } from "../models/administrator-activity";
import { Roles } from "../contexts/roles";

type CreateAdminAccountDTO = {
    preferredName: string,
    fullname: string,
    email: string,
    preferredRegion: PreferredRegion,
    roles: Roles[]
  }

interface UpdateEmailDTO {
    newEmail: string
}

const AdministratorService = {
    /**
     * Fetch pagination data of administrator
     * @returns 
     */
    fetchPagination: async (): Promise<Pagination> => {
        return await (await AccountsErpAPI.get(`/rest/v1/administrators/pagination`)).data;
    },

    /**
     * Fetch all administrators using pagination
     * @param page 
     * @param limit 
     * @returns 
     */
    fetchAll: async (page: number, limit: number = 20): Promise<UserAccount[] | null> => {
        const response = await AccountsErpAPI.get(`/rest/v1/administrators?page=${page}&limit=${limit}`);

        //Return null if response is 204
        if (response.status === 204) return null;

        return await response.data;
    },

    /**
     * Register an new administrator account into the platform
     * @param dto Object used on request
     */
    register: async (dto: CreateAdminAccountDTO) => {
        //Send request
        return await AccountsErpAPI.post('/rest/v1/administrators', dto);
    },

    find: async (uuid: string) => {
        //Send request
        return await (await AccountsErpAPI.get(`/rest/v1/administrators/${uuid}/find`)).data;
    },

    update: async (dto: Administrator, uuid: string) => {
        //Send request
        return await (await AccountsErpAPI.put(`/rest/v1/administrators/${uuid}`, dto)).data;
    },

    updateEmail: async (uuid : string, dto : UpdateEmailDTO) => {
        //Send request
        return await (await AccountsErpAPI.put(`/rest/v1/administrators/${uuid}/email`, dto)).data;
    },

    block: async (reason: string, uuid: string) => {
        //Send request
        return await AccountsErpAPI.post(`/rest/v1/administrators/${uuid}/block`, { reason });
    },

    unblock: async (reason: string, uuid: string) => {
        //Send request
        return await AccountsErpAPI.post(`/rest/v1/administrators/${uuid}/unblock`, { reason });
    },

    fetchAdministratorActivities: async function (type: string, uuid: string, page: Number, action: string): Promise<AdministratorActivity[]> {
        //Query the action
        const query = action.length > 0 ? `&action=${action}` : ``

        //Send request
        const resp = await AccountsErpAPI.get(`/rest/v1/administrators/${uuid}/activities-by-${type}?page=${page}&limit=5${query}`);

        //Return the fetched data
        if (resp.status === 200) return resp.data as AdministratorActivity[];

        //Return null is there is no content
        if (resp.status === 204) return null!;

        //Throw error
        throw new Error("Unexpected server response status: " + resp.status);
    },

     /**
     * Upload an avatar image
     * @param avatarBlob
     */
      async uploadAvatar(avatarBlob: Blob, uuid: string): Promise<void> {
        //Build the form data body
        let bodyFormData = new FormData();
        bodyFormData.append("avatar", avatarBlob);

        //Call the API to upload the avatar
        return await AccountsErpAPI.post(`/rest/v1/administrators/${uuid}/avatar`, bodyFormData, {
            headers: { "Content-Type": "multipart/form-data" },
        });

    },

    /**
     * Remove the current avatar image from the user profile
     * @returns 
     */
    async removeAvatar(uuid: string): Promise<void> {
        //Call the API to upload the avatar
        return await AccountsErpAPI.delete(`/rest/v1/administrators/${uuid}/avatar`);
    }
}

export default AdministratorService;