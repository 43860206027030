import { useState, useContext } from 'react';
import { Link } from "react-router-dom";
import { Box, Button, IconButton, Container, Divider, Grid, Typography, TextField, FormControl, Select, InputLabel, MenuItem, Autocomplete, SelectChangeEvent, Tooltip } from "@mui/material";

import { KeyboardBackspace, AdminPanelSettingsOutlined, CheckCircle } from "@mui/icons-material";
import AuthenticationContext from "../../contexts/authentication";
import { LoadingButton } from "../../utils/Components";
import AvatarUploader from "../../utils/avatar/AvatarUploader";
import ErrorWrapper from '../../utils/ErrorWrapper';

import { Role, AdminRoles } from '../../static/roles-info'
import { PreferredRegion } from '../../models/user';
import { Roles } from '../../contexts/roles';
import { PreferredRegions } from '../../static/preferred-regions';
import AdministratorService from '../../services/administrator';
import UserService from '../../services/user';

import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import { AppNotification } from "../../utils/AppNotification"

type CreateAdminAccountDTO = {
  preferredName: string,
  fullname: string,
  email: string,
  preferredRegion: PreferredRegion,
  roles: Roles[]
}

/**
 * Login UI
 * @returns {JSX.Element}
 */
export default function AddAdmin() {
  //Context props
  const useAuthentication = () => { return useContext(AuthenticationContext) }
  const auth = useAuthentication();
  const notification = useAppDispatch();

  const [loading, setLoading] = useState(false)

  //Store avatar info, if necessary
  const [avatarData, setAvatarData] = useState<Blob>();

  //Store creation status
  const [adminCreated, setAdminCreated] = useState(false)
  const [avatarError, setAvatarError] = useState<string | null>(null)

  //State prop to store admin account data
  const [adminAccountModel, setAdminAccountModel] = useState<CreateAdminAccountDTO>({
    fullname: '',
    preferredName: '',
    email: '',
    preferredRegion: PreferredRegion.BRA,
    roles: []
  })

  //Component events handlers
  const handleChangeFullName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdminAccountModel({ ...adminAccountModel, fullname: event.target.value });
  };
  const handleChangePreferredName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdminAccountModel({ ...adminAccountModel, preferredName: event.target.value });
  };
  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdminAccountModel({ ...adminAccountModel, email: event.target.value })
  };
  const handleChangePR = (event: SelectChangeEvent<any>) => {
    const { target: { value } } = event;
    setAdminAccountModel({ ...adminAccountModel, preferredRegion: value as PreferredRegion })
  };
  const handleChangeRoles = (value: any) => {
    const roles: Roles[] = [];
    value.forEach((role: Role) => { roles.push(role.value.toString() as Roles) })
    setAdminAccountModel({ ...adminAccountModel, roles: roles });
  };

  const registerAdmin = () => {
    setLoading(true);

    AdministratorService.register(adminAccountModel)
      .then((resp) => {
        setAdminCreated(true)
        //Upload the user avatar after register the account
        if (avatarData)
          AdministratorService.uploadAvatar(avatarData, resp.data.uuid)
            .then(() => { })
            .catch(e => setAvatarError(new ErrorWrapper(e).message))
      })
      .catch(e => notification(show({ type: 'error', message: new ErrorWrapper(e).message })))
      .finally(() => setLoading(false))
  }

  return (
    <Box sx={{ width: .8, margin: 'auto' }}>
      <Link to='/administrators'><IconButton color="primary" sx={{ m: 2, ml: 5 }}> <KeyboardBackspace /></IconButton></Link>
      <Divider />
      <Box sx={{ width: .8, maxWidth: 1000, margin: 'auto', mt: 3 }}>
        {!adminCreated ?
          <Box>
            <Grid container>
              <Grid item xs={3} sm={3} md={3} lg={2}>
                <AdminPanelSettingsOutlined color='primary' sx={{ fontSize: 75 }} />
              </Grid>
              <Grid item xs alignSelf='center'>
                <Typography variant='h5'>Cadastrar um novo administrador</Typography>
                <Typography variant='body2' sx={{ mt: 1 }}>
                  Preencha as informações necessárias para o cadastro de um novo administrador
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', alignItems: 'end', mt: 5 }}>
              <TextField required fullWidth label="Nome" type="text" variant="standard" onChange={handleChangeFullName} value={adminAccountModel.fullname} />
              <AvatarUploader onUpload={(blob: Blob) => setAvatarData(blob)} sx={{ fontSize: 50 }} />
            </Box>

            <TextField sx={{ mt: 5 }} required fullWidth label="Nome de apresentação" type="text" variant="standard" onChange={handleChangePreferredName} value={adminAccountModel.preferredName} />
            <TextField sx={{ mt: 5 }} required fullWidth label="Email" type="email" variant="standard" onChange={handleChangeEmail} value={adminAccountModel.email} />
            <FormControl variant="standard" fullWidth sx={{ mt: 5 }}>
              <InputLabel id="prs">Região de preferência</InputLabel>
              <Select
                value={adminAccountModel.preferredRegion}
                onChange={handleChangePR}
                labelId="prs"
                label="Região de preferência"
              >
                {
                  PreferredRegions.map((pr) => (<MenuItem key={pr.value} value={pr.value}>{pr.name}</MenuItem>))
                }
              </Select>
            </FormControl>

            <Autocomplete
              multiple
              sx={{ mt: 5 }}
              options={AdminRoles}
              getOptionLabel={(role: Role) => role.name}
              onChange={(e, value) => handleChangeRoles(value)}
              disableCloseOnSelect
              renderInput={(params) => (<TextField {...params} variant="standard" label="Permissões" />)}
              renderOption={(props, role) => { return (<Tooltip placement="top-start" title={role.description} key={role.value}><MenuItem {...props}>{role.name}</MenuItem></Tooltip>) }}
            />
            <Box textAlign='right' sx={{ mt: 5 }} >
              <Link to='/administrators'><Button color='error' sx={{ textTransform: 'none', mr: 2 }}>Cancelar</Button></Link>
              <LoadingButton variant="contained" onClick={registerAdmin} id='bc' loading={loading}
                disabled={
                  adminAccountModel.fullname.length == 0 ||
                  adminAccountModel.preferredName.length == 0 ||
                  !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(adminAccountModel.email))
                }
              >
                Salvar
              </LoadingButton>
            </Box>
          </Box>
          :
          <Grid container>
            <Grid item xs={3} sm={3} md={3} lg={2}>
              <CheckCircle color='success' sx={{ fontSize: 75 }} />
            </Grid>
            <Grid item xs alignSelf='center'>
              <Typography variant='h5'>Cadastro realizado com sucesso</Typography>
              <Typography variant='body2' sx={{ mt: 1 }}>
                Acesse a caixa de entrada do email <b>{adminAccountModel.email}</b> e siga as instruções para ativar a conta.
              </Typography>
              {
                avatarError ?
                  < Typography variant='caption' color='error' sx={{ mt: 1 }}>{avatarError}</Typography>
                  : null
              }
            </Grid>
          </Grid>
        }
      </Box>
      <AppNotification />
    </Box>
  );
}