import { Alert, Button, CircularProgress, Snackbar } from "@mui/material";
import { Link } from "react-router-dom";

const LoadingButton = (props: React.ComponentProps<any>) => {

    const { classes, loading, done, progressColor,  ...other } = props;

    if (loading) {
        return (
            <Button  {...other} disabled >
                <CircularProgress size={25} sx={{ color: props.variant === 'text' ? '#3590F3' : 'white' }} />
            </Button>
        );
    } else {
        return (
            <Button  {...other} />
        );
    }
};

const Logo = (props: React.ComponentProps<any>) => {

    const { auth, mode, bold } = props;
   
    return (
        <Link to={auth ? '/' : '/login'}>
            <Button sx={{ color: mode,  p: 2.5, width: 'fit-content', fontWeight : bold ? 'bold' : '' }} className="nh">Klaus | CRM</Button>
        </Link>
    )
};

const LogoBar = (props: React.ComponentProps<any>) => {

    const { size } = props;
    const calcSize = () => { return size / 4 }

    return (
        <div style={{ display: 'flex', height: 5, width: size, top: "20.5%", position: 'relative', margin: 'auto', zIndex: 1 }}>
            <span style={{ width: calcSize(), background: "#FF4B3C", height: "inherit" }}/>
            <span style={{ width: calcSize(), background: "#FF7B3C", height: "inherit" }}/>
            <span style={{ width: calcSize(), background: "#54B948", height: "inherit" }}/>
            <span style={{ width: calcSize(), background: "#3590F3", height: "inherit" }}/>
        </div>
    )
};



const Notification = (props: React.ComponentProps<any>) => {

    const { severity, message, ...other } = props;

    return (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} {...other}>
            <Alert variant='filled' severity={severity}> {message} </Alert>
        </Snackbar>
    )
};

export { LoadingButton, Logo, LogoBar, Notification };