import { Alert, Snackbar } from "@mui/material";

//Redux imports
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { hide } from "../redux/features/app-global-notification/app-global-notification-slice"

const AppNotification = (props: React.ComponentProps<any>): JSX.Element => {
  //Extracting props
  let { message, visible, ...otherProps } = props;
  message = message || '';
  visible = visible || false;

  //Redux props
  const appGlobalNotification = useAppSelector((state) => state.appGlobalNotification);
  const notification = useAppDispatch();

  return (
    <Snackbar
      {...otherProps}
      open={appGlobalNotification.visible || visible}
      onClose={() => { notification(hide()) }}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={() => { notification(hide()) }} severity={appGlobalNotification.type} variant='filled'>
        {appGlobalNotification.message || message}
      </Alert>
    </Snackbar>
  );
}

export { AppNotification };