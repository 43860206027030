import { User, AccountType } from "../models/user";

export enum CustomerStatus {
    BRONZE = "BRONZE",
    SILVER = "SILVER",
    GOLD = "GOLD",
    DIAMOND = "DIAMOND",
    CANCELLED = "CANCELLED"
}

export interface Customer {
    creationDate: Date,
    email: string,
    fullname: string,
    organizationId: string,
    preferredName: string,
    type: AccountType
    user: User,
    uuid: string,
    contractsCrmStatus: CustomerStatus
};

export const customerStatus = [
    {
        value: "BRONZE",
        label: "Bronze",
        color: "#CD7F32",
    },
    {
        value: "SILVER",
        label: "Prata",
        color: "#C0C0C0",
    },
    {
        value: "GOLD",
        label: "Ouro",
        color: "#FFD700",
    },
    {
        value: "DIAMOND",
        label: "Diamante",
        color: "#B9F2FF",
    },
    {
        value: "CANCELLED",
        label: "Cancelado",
        color: "#991B16",
    },
]