export enum Actions {
    REGISTER_ADMINISTRATOR_ACCOUNT = 'REGISTER_ADMINISTRATOR_ACCOUNT',
    UPDATE_ADMINISTRATOR = 'UPDATE_ADMINISTRATOR',
    UPDATE_ADMINISTRATOR_EMAIL = 'UPDATE_ADMINISTRATOR_EMAIL',
    UPDATE_CUSTOMER_ACCOUNTS_QUOTA = 'UPDATE_CUSTOMER_ACCOUNTS_QUOTA',
    BLOCK_ADMINISTRATOR_ACCOUNT = 'BLOCK_ADMINISTRATOR_ACCOUNT',
    UNBLOCK_ADMINISTRATOR_ACCOUNT = 'UNBLOCK_ADMINISTRATOR_ACCOUNT'
}

type AdministratorActivity = {
    label: string;
    value: Actions;
}

export const AdministratorActivities: AdministratorActivity[] = [
    {
        label: 'Cadastro de conta',
        value: Actions.REGISTER_ADMINISTRATOR_ACCOUNT
    },
    {
        label: 'Bloqueio de conta',
        value: Actions.BLOCK_ADMINISTRATOR_ACCOUNT
    },
    {
        label: 'Desbloqueio de conta',
        value: Actions.UNBLOCK_ADMINISTRATOR_ACCOUNT
    },
    {
        label: 'Alteração de dados da conta',
        value: Actions.UPDATE_ADMINISTRATOR
    },
    {
        label: 'Alteração de email da conta',
        value: Actions.UPDATE_ADMINISTRATOR_EMAIL
    },
    {
        label: 'Alteração de licença de contas',
        value: Actions.UPDATE_CUSTOMER_ACCOUNTS_QUOTA
    }
]