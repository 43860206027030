import { useEffect, useState, useContext } from "react";
import { Box, Button, Container, LinearProgress, Typography } from "@mui/material";
import { Check, Error as ErrorIcon } from "@mui/icons-material";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import jwt from "jwt-decode"

import { Logo, LogoBar } from "../../utils/Components";
import AuthenticationService from "../../services/authentication";
import RenewAccessDialog from "./RenewAccessDialog";
import AuthenticationContext from "../../contexts/authentication";
import StlInterceptor from '../../contexts/stl'
import { SecurityTierLevels } from "../../store/stl";
import ErrorWrapper from "../../utils/ErrorWrapper";

/**
 * Enum used to store token validation status
 */
enum TokenValidationStatus {
    Processing,
    Failed,
    Validated
}

const ChangeEmailWithToken = (): JSX.Element => {

    //Auth user props
    const useAuthentication = () => { return useContext(AuthenticationContext) }
    const auth = useAuthentication();
    const stl = useContext(StlInterceptor);

    //Context prop used to navigate to another page
    const navigate = useNavigate();

    // content used to get query parameter data
    const [searchParams] = useSearchParams();


    function TokenValidationProgress(): JSX.Element | null {
        //Store the state of the token verification step
        const [tokenVerificationStatus, setTokenVerificationStatus] = useState<TokenValidationStatus>(TokenValidationStatus.Processing);

        //Token error message
        const [error, setError] = useState('');

        //STL props
        const [renewAccessDialogVisible, setRenewAccessDialogVisible] = useState(false);
        const [stlApproved, setStlApproved] = useState(false);

        //Effect used to validate the token when the component is first loaded
        useEffect(() => {
            //Verify the STL level before the token validation
            try {
                //Get the token from query parameter
                const tokenFromQuery = searchParams.get("token") || null;
                if (tokenFromQuery === null) {
                    throw new Error('Token de verificação não encontrado');
                }

                //Get the decoded JWT
                const decodedJwt = jwt(tokenFromQuery) as any;
                if (decodedJwt === null) {
                    throw new Error('Token de verificação inválido')
                }

                //Get the token expiration date to validate if it is expired
                const tokenExpirationTimestamp = decodedJwt.exp * 1000;
                const now = new Date().getTime();

                ///Verify is the token expired
                if (tokenExpirationTimestamp < now) {
                    console.log("deu erro?")
                    throw new Error('Token de verificação expirado');
                }

                //Validated token
                setTokenVerificationStatus(TokenValidationStatus.Validated);
                setTimeout(() => confirmChangeEmail(tokenFromQuery), 3000);

            } catch (e: any) {
                setError(e.message);
                setTokenVerificationStatus(TokenValidationStatus.Failed);
            }
        }, []);

        const confirmChangeEmail = (token: string) => {
            //Check stl level
            stl.require(auth, SecurityTierLevels.MaxLevel)
                .then(() => {
                    //Update the email
                    AuthenticationService.updateEmailWithToken(token)
                        .then(() => {
                            //Update auth user data
                            auth.updatedUser();
                            setTimeout(() => navigate('/', { replace: true }), 3000);
                        })
                        .catch((e) => {
                            setError(new ErrorWrapper(e).message)
                            setTokenVerificationStatus(TokenValidationStatus.Failed)
                        })
                })
                .catch(() => setRenewAccessDialogVisible(true))
        }

        switch (tokenVerificationStatus) {
            case TokenValidationStatus.Processing:
                return (
                    <Box textAlign='center' my={2}>
                        <Typography variant='h6' >Iniciando a alteração...</Typography>
                        <LinearProgress sx={{ my: 2 }} />
                    </Box>
                );
            case TokenValidationStatus.Validated:
                return (
                    <Box textAlign='center' mt={2}>
                        <Check color='success' fontSize='large' />
                        <Typography variant='h6' >Alterando email...</Typography>
                        <LinearProgress sx={{ my: 2 }} />
                        <RenewAccessDialog
                            open={renewAccessDialogVisible}
                            sessionRenewed={() => setRenewAccessDialogVisible(false)}
                        />
                    </Box>
                );
            case TokenValidationStatus.Failed:
                return (
                    <Box textAlign='center' mt={2}>
                        <ErrorIcon color="error" fontSize="large" sx={{ mr: 1 }} />
                        <Typography variant='h6' mt={2}>Ocorreu um erro ao alterar seu email</Typography>
                        <Typography variant='body1' mb={3}>{error}</Typography>
                        <Link to='/me'><Button>Solicitar alteração de email novamente</Button></Link>
                    </Box>
                )
            default:
                return null;
        }
    }

    /**
     * Main component
     */
    return (
        <Container id='formContainer' sx={{ bgcolor: 'primary.dark' }}>
            <Logo mode={'primary.light'} />
            <LogoBar size={400} />
            <Box id='box'><TokenValidationProgress /></Box>
        </Container>
    );
};

export default ChangeEmailWithToken;