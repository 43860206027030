import axios, { AxiosError } from "axios";
import { CurrentContext } from "../appcontext/webappContext";
import authenticationStorage from '../store/authentication';

//Marks if the application is running on development
const DEV = process.env.NODE_ENV === 'development';

//Create the ContractsApi Axios instance
const ContractsApi = axios.create({
    baseURL: CurrentContext.contractsApi.baseURL,
    timeout: CurrentContext.contractsApi.timeout,
    withCredentials: true
})

//Adding request interceptors
ContractsApi.interceptors.request.use((config) => {

    config.headers = {
        'Authorization': `Bearer ${authenticationStorage.getToken()}`,
        "Accept": "application/json"
    }
    return config;
});

ContractsApi.interceptors.response.use(
    (response) => {
        return response
    },
    (error: AxiosError) => {
        
        // If there is no response just reject the error
        if (!error.response) return Promise.reject(error);
        
        // If there is and the status is 5xx change the x-reason to default message
        if (Math.floor(error.response.status / 100) === 5) {
            error.response.headers["x-reason"] = "Ocorreu um erro no servidor"
            console.error(error)
        }

        return Promise.reject(error);
    }
)

export default ContractsApi;