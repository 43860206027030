import { useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { AlternateEmail, Key } from "@mui/icons-material";
import { LoadingButton } from "../../../utils/Components";

import AuthenticationService from "../../../services/authentication";
import ErrorWrapper from "../../../utils/ErrorWrapper";

import { useAppDispatch } from '../../../redux/hooks';
import { show } from "../../../redux/features/app-global-notification/app-global-notification-slice"
import { AppNotification } from "../../../utils/AppNotification"

/**
 * Dialog to resent email UI
 * @returns {JSX.Element}
 */
export default function ConfirmDialog(props: React.ComponentProps<any>) {
    const notification = useAppDispatch();

    //Props
    const { email, name, open, close, success } = props;

    //State props
    const [error, setError] = useState({ open: false, message: '' })
    const [loading, setLoading] = useState(false);

    const sendConfirm = () => {
        setLoading(true);

        AuthenticationService.sendAccountConfirmationMail(email)
            .then(() => { success(); close() })
           .catch(e => notification(show({ type: 'error', message: new ErrorWrapper(e).message })))
            .finally(() => setLoading(false))

    }

    const sendReset = () => {
        setLoading(true);

        AuthenticationService.sendPasswordResetMail(email)
            .then(() => { success(); close() })
            .catch(e => notification(show({ type: 'error', message: new ErrorWrapper(e).message })))
            .finally(() => setLoading(false))
    }

    return (
        <Dialog onClose={close} open={open} >
            <DialogTitle sx={{ pb: 0 }}>Reenvio de email</DialogTitle>

            <DialogContent sx={{ maxWidth: 350, mt: 2 }}>
                <DialogContentText>
                    Qual email você gostaria de reenviar para <strong>{name}</strong>?
                </DialogContentText>
                <LoadingButton sx={{ my: 2 }} startIcon={<AlternateEmail />} fullWidth loading={loading} variant='contained' onClick={sendConfirm} id='bc'> Confirmar email </LoadingButton>
                <LoadingButton startIcon={<Key />} fullWidth loading={loading} variant='contained' onClick={sendReset} id='bc'> Recuperar senha </LoadingButton>
            </DialogContent>

            <DialogActions sx={{ pr: 3, pb: 2 }}>
                <Button onClick={close} color='error'>Cancelar</Button>
            </DialogActions>
            <AppNotification/>
        </Dialog >
    );
}