import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, TextField, Typography } from "@mui/material";
import { Report } from "@mui/icons-material";
import AuthenticationService from "../../services/authentication";
import { LoadingButton } from "../../utils/Components";
import ErrorWrapper from "../../utils/ErrorWrapper";

import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import { AppNotification } from "../../utils/AppNotification"

interface RenewPasswordDTO {
    email: string,
    password: string,
    newPassword: string
}

function RenewPassword(): JSX.Element {
    //Context prop 
    const navigate = useNavigate();
    const notification = useAppDispatch();

    //Store 'loading' state
    const [loading, setLoading] = useState(false);

    //State that stores the data of the model that will be sent to the server  
    const [canSubmitForm, setCanSubmitForm] = useState(false);
    const [renewPasswordModel, setRenewPasswordModel] = useState<RenewPasswordDTO>({
        email: '',
        password: '',
        newPassword: ''
    });

    //Effect to validate if the email can be sent
    useEffect(() => {
        setCanSubmitForm(
            /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(renewPasswordModel.email) &&
            renewPasswordModel.newPassword.length > 6 &&
            renewPasswordModel.password.length > 6 &&
            renewPasswordModel.password !== renewPasswordModel.newPassword
        );
    }, [renewPasswordModel]);

    //Event changes
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRenewPasswordModel({ ...renewPasswordModel, email: event.target.value })
    };
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRenewPasswordModel({ ...renewPasswordModel, password: event.target.value })
    };
    const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRenewPasswordModel({ ...renewPasswordModel, newPassword: event.target.value })
    };

    //Functions
    const renewPassword = () => {
        //Update loading state
        setLoading(true);

        AuthenticationService.renewPassword(renewPasswordModel)
            .then(() => {
                //Go to login automatically after password renew
                setTimeout(() => { navigate("/login", { replace: true }) }, 2000)
                notification(show({ type: 'success', message: `Senha alterada` }))
            })
            .catch(e => notification(show({ type: 'error', message: new ErrorWrapper(e).message })))
            .finally(() => setLoading(false))
    }

    return (
        <Box>
            <AppNotification />

            <Typography variant='h6' className='ba' mb={3}>  <Report color='info' sx={{ mr: 1 }} />Alteração de senha necessária</Typography>
            <Typography variant='body1' mb={1}>Sua senha atual expirou, insira a seguir a nova senha desejada.</Typography>
            <Typography variant='caption'>Lembre-se de usar seis ou mais caracteres contendo uma combinação de letras, números e símbolos.</Typography>

            <TextField
                sx={{ mt: 2 }}
                variant="standard"
                value={renewPasswordModel.email}
                label='Email'
                type='email'
                onChange={handleEmailChange}
                fullWidth
            />

            <TextField
                sx={{ mt: 2 }}
                variant="standard"
                value={renewPasswordModel.password}
                label='Senha atual'
                type='password'
                onChange={handlePasswordChange}
                fullWidth
            />

            <TextField
                sx={{ mt: 2, mb: 5 }}
                variant="standard"
                value={renewPasswordModel.newPassword}
                label='Nova senha'
                type='password'
                onChange={handleNewPasswordChange}
                fullWidth
            />

            <LoadingButton loading={loading} variant='contained'
                fullWidth
                id='bc'
                disabled={!canSubmitForm}
                onClick={() => renewPassword()}
            >
                Renovar senha
            </LoadingButton>
        </Box >
    );
};

export default RenewPassword;