import { Box, Checkbox, Dialog, FormControlLabel, TextField, Typography } from "@mui/material";
import { GppMaybe, Login } from "@mui/icons-material";
import { useContext, useState } from "react";
import AuthenticationContext from "../../contexts/authentication";
import ErrorWrapper from "../../utils/ErrorWrapper";
import { LogoBar, LoadingButton } from "../../utils/Components";

import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import { AppNotification } from "../../utils/AppNotification"

function RenewAccessDialog(props: React.ComponentProps<any>) {

    const notification = useAppDispatch();

    //Parent props
    const { sessionRenewed, ...others } = props;

    //State props
    const [password, setPassword] = useState('');
    const [trustDevice, setTrustDevice] = useState(false);
    const [loading, setLoading] = useState(false);

    //Context props
    const useAuthentication = () => { return useContext(AuthenticationContext) }
    const authUser = useAuthentication();

    //Functions
    async function updateSession() {
        setLoading(true);
        await authUser.renewSessionWithPassword(password, trustDevice)
            .then(() => sessionRenewed())
            .catch(e => notification(show({ type: 'error', message: new ErrorWrapper(e).message })))
            .finally(() => setLoading(false))
    }

    return (
        <Dialog {...others}>
            <LogoBar size={400} />
            <Box p={3}>
                <Box textAlign='center' mb={2}>
                    <GppMaybe color='error' sx={{ fontSize: 40 }} />
                    <Typography variant='h6'>Confirmação de identidade necessária</Typography>
                    <Typography variant='subtitle2' mt={1}>Reinsira sua senha para utilizar o serviço solicitado</Typography>
                </Box>
                <Box width={350}>

                    <TextField name="password"
                        value={password} fullWidth
                            onChange={(e) => setPassword(e.target.value)}
                            label='Senha' type='password' variant='standard' />

                    <FormControlLabel sx={{ mt: 4, color: 'text.secondary' }}
                        label="Continuar conectado"
                        control={<Checkbox checked={trustDevice} onChange={(e) => setTrustDevice(e.target.checked)} size="small" />} />

                    <LoadingButton
                        disabled={password.length === 0}
                        id='bc' fullWidth loading={loading}
                        variant="contained" onClick={updateSession}>
                        Confirmar</LoadingButton>
                </Box>
            </Box>
            <AppNotification/>
        </Dialog>
    );
}

export default RenewAccessDialog;