import { Box, Button, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Tooltip, Typography } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { AdminRoles, CustomerRoles } from "../../../static/roles-info"
import { Info, AdminPanelSettings, Group } from "@mui/icons-material";

/**
 * Dialog with roles informations UI
 * @returns {JSX.Element}
 */
export default function RolesInfoDialog(props: React.ComponentProps<any>) {

    const { open, close } = props;

    return (
        <Dialog onClose={close} open={open}>
            <Typography variant='h6' className='ba' sx={{ p: 3, pb: 0 }}>  <Info color='action' sx={{ mr: 2 }} />Entenda as permissões</Typography>
            <DialogContent>
                <Box className='bd' p={2}>
                    <Typography fontWeight={600}  className='ba' sx={{ justifyContent: 'space-between' }}>
                        Permissões de gerenciamento de administradores <AdminPanelSettings color='action' />
                    </Typography >
                    <Divider sx={{ mb: 2, mt: 1 }} />
                    {
                        AdminRoles.map(aRole =>
                            <Box ml={1} key={aRole.value}>
                                <Typography variant='body1'>{aRole.name}</Typography>
                                <Typography ml={2} mt={.5} mb={2} variant='body2' color='text.secondary'>{aRole.description}</Typography>
                            </Box>
                        )
                    }
                </Box>
                <Box className='bd' p={2} mt={3}>
                    <Typography fontWeight={600} className='ba' sx={{ justifyContent: 'space-between' }}>
                        Permissões de gerenciamento de clientes <Group color='action' />
                    </Typography >

                    <Divider sx={{ mb: 2, mt: 1 }} />
                    {
                        CustomerRoles.map(cRole =>
                            <Box ml={1} key={cRole.value}>
                                <Typography variant='body1'>{cRole.name}</Typography>
                                <Typography ml={2} mt={.5} mb={2} variant='body2' color='text.secondary'>{cRole.description}</Typography>
                            </Box>
                        )
                    }
                </Box>
            </DialogContent>
            <DialogActions sx={{ pr: 3, pb: 2 }}><Button onClick={close}>Voltar</Button></DialogActions>
        </Dialog>
    );
}