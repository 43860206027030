import { Avatar as AvatarMUI } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { CurrentContext } from "../../appcontext/webappContext";

const Avatar = (props: React.ComponentProps<any>) => {

    //Define props
    const { user, sx, mr } = props;

    if (!user.hasAvatar) return <AccountCircle sx={sx} />

    //Create the avatar src string
    const avatarSrc = `${CurrentContext.accountsCdn.server}/${user.uuid}`

    return <AvatarMUI src={avatarSrc} sx={{...sx, width: sx.fontSize, height: sx.fontSize, mr: mr }} />
}
export default Avatar;

