import { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Box, Button, Container, Grid, IconButton, Typography, makeStyles } from "@mui/material";
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { AdminPanelSettingsOutlined, ArrowForwardIos } from "@mui/icons-material";
import { tableStyle } from "../../style/theme"
import { RolesContext, Roles } from "../../contexts/roles";
import { UserAccount } from "../../models/user"
import AuthenticationContext from "../../contexts/authentication";
import ErrorWrapper from '../../utils/ErrorWrapper';
import AdministratorService from "../../services/administrator";
import { Pagination } from "../../models/pagination"
import InfoAdmin from './InfoAdmin'
import Avatar from '../../utils/avatar/Avatar';

import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import { AppNotification } from "../../utils/AppNotification"

/**
 * List of administrators UI
 * @returns {JSX.Element}
 */
export default function ListAdmin() {

  //Context props
  const useAuthentication = () => { return useContext(AuthenticationContext) }
  const auth = useAuthentication();
  const userRoles = useContext(RolesContext);
  const notification = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState<Pagination>(null!);
  const [admins, setAdmins] = useState<UserAccount[]>([]);
  const [selectedUser, setSelectedUser] = useState<any | null>(null);

  //Effect that validade witch functions the current user can use
  useEffect(() => {
    AdministratorService.fetchPagination()
      .then(pagination => setPagination(pagination))
  }, []);

  //useEffect - Observe user page change and fetch the users
  useEffect(() => {
    setLoading(true)
    //Component loading flag
    AdministratorService.fetchAll(page)
      .then(resp => {
        //Remove auth user from the list
        if (resp) setAdmins(resp.filter(adm => adm.user.uuid != auth.user().user.uuid))
      })
      .catch(erro => {
        const e = new ErrorWrapper(erro)
        notification(show({
          type: 'error',
          message: e.httpStatus === 403 ?
            `Usuário sem permissão` : `Ocorreu um erro: ${e.message}`
        }))
      })
      .finally(() => setLoading(false))
  }, [page, selectedUser]);

  /**
     * Return default props used in pagination
     * @param pagination 
     * @returns 
     */
  function paginationProps(pagination: Pagination | null): Object {
    //Return empty object if pagination is null
    if (pagination === null) return {}
    return {
      rowCount: pagination?.totalRecords,
      pageSize: pagination?.recordsPerPage,
      paginationMode: 'server',
    }
  }

  const normalize = (milliseconds: string | number | Date) => { return new Date(milliseconds).toLocaleDateString() };

  //Domains grid column def
  const adminColumnDef = [
    {
      field: 'avatar', headerName: '', align: 'center' as 'center', sortable: false, flex: 1, minWidth: 100,
      renderCell: (params: GridRenderCellParams) => { return <Avatar user={params.row.user} sx={{ fontSize: 45, color: 'text.disabled' }} /> }
    },
    { field: 'fullname', headerName: 'Nome completo', align: 'center' as 'center', headerAlign: 'center' as 'center', sortable: false, flex: 2, minWidth: 200 },
    { field: 'email', headerName: 'Email', flex: 2, align: 'center' as 'center', headerAlign: 'center' as 'center', sortable: false, minWidth: 200 },
    {
      field: 'creationDate', headerName: 'Data de cadastro', flex: 1, align: 'center' as 'center', headerAlign: 'center' as 'center', sortable: false, minWidth: 150,
      renderCell: (params: GridRenderCellParams) => { return normalize(params.row.creationDate) }
    },
    {
      field: 'actions', headerName: '', align: 'center' as 'center', sortable: false, hide: !userRoles.hasRole(auth, Roles.AM_SM),
      renderCell: (params: GridRenderCellParams) => {
        return <Link to={`/administrators/${params.row.user.uuid}`}><IconButton><ArrowForwardIos sx={{ fontSize: 15 }} /></IconButton></Link>
      }
    }
  ];

  const RenderAddAdmin = (): JSX.Element => {
    if (userRoles.hasRole(auth, Roles.AM_SM)) return (
      <Grid container className='bd' sx={{ mt: 5 }} >
        <Grid item xs sm={2}>
          <AdminPanelSettingsOutlined sx={{ fontSize: 50, color: 'primary.main' }} />
        </Grid>
        <Grid item xs={8} sm={7} md={7} alignSelf='center'>
          <Typography sx={{ pr: 1 }}>Aqui você pode realizar o cadastro de um novo usuário administrador</Typography>
        </Grid>
        <Grid item xs sm={2.5} alignSelf='center' sx={{ borderLeft: '1px solid #EBEBEB' }}>
          <Link to='/administrators/register'><Button>Cadastrar administrador</Button></Link>
        </Grid>
      </Grid>
    )
    else return (<></>);
  }

  /**
   * Find administrator by its UUID
   * @param uuid 
   * @returns 
   */
  const findUser = (uuid: string) => {
    const admin = admins.find(a => a.user.uuid === uuid)
    if (admin) return setSelectedUser(admin)

    AdministratorService.find(uuid)
      .then(fetchedAdmin => setSelectedUser(fetchedAdmin))
      .catch(e => notification(show({ type: 'error', message: `Ocorreu um erro ao buscar o administrador. Erro: ${new ErrorWrapper(e).message}` })))
  }

  return (
    <Box width={.85} mx='auto' >
      <Box textAlign='center'>
        <Typography variant='h5' mb={1} mt={5} fontWeight={600}>Administradores</Typography>
        <Typography variant='subtitle1'>Usuários cadastrados como administradores na plataforma</Typography>
        <RenderAddAdmin />
        <Box height={500} width={1} mt={5}>
          <DataGrid
            sx={tableStyle}
            disableColumnMenu
            disableColumnFilter
            rowsPerPageOptions={[20]}
            getRowId={(admin) => admin.user.uuid}
            rows={admins}
            columns={adminColumnDef}
            loading={loading}
            page={page - 1}
            onPageChange={(page: number) => { setPage(page + 1) }}
            isRowSelectable={() => false}
            pageSize={pagination?.recordsPerPage}
            {...paginationProps(pagination)}
            components={{
              NoRowsOverlay: () => (<Typography color='error' mt={3}> Nenhuma informação disponível. </Typography>)
            }}
          />
        </Box>
      </Box>
      {/* <InfoAdmin user={selectedUser} close={() => setSelectedUser(null)} onFindUser={findUser} />*/}
      <AppNotification />
    </Box >
  );
}