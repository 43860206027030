import { useContext, useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import AuthenticationContext from "../../contexts/authentication";

import { Box, Button, Container, LinearProgress, TextField, Typography } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";

import { LoadingButton, Logo, LogoBar } from "../../utils/Components";
import AuthenticationService from "../../services/authentication";
import jwt from "jwt-decode"
import ErrorWrapper from "../../utils/ErrorWrapper";

import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import { AppNotification } from "../../utils/AppNotification"

type ActivateAccountDTO = {
    password: string,
    confirmEmailToken: string,
    resetPasswordToken: string
}

enum TokenValidationStatus {
    Processing,
    Failed,
    Validated
}

const ActivateAccount = (): JSX.Element => {
    // content used to get query parameter data
    const [searchParams] = useSearchParams();

    //Context prop 
    const navigate = useNavigate();
    const notification = useAppDispatch();
    const [loading, setLoading] = useState(false);

    //Store the state of the token validation status
    const [tokensValidationStatus, setTokensValidationStatus] = useState<TokenValidationStatus>(TokenValidationStatus.Processing);

    const [activateAccountModel, setActivateAccountModel] = useState<ActivateAccountDTO>({
        password: '',
        confirmEmailToken: '',
        resetPasswordToken: ''
    })

    function TokenValidationProgress(): JSX.Element | null {
        useEffect(() => {
            if (activateAccountModel.confirmEmailToken.length === 0) {
                try {
                    const confirmEmailTokenFromQuery = searchParams.get("confirm-email-token") || null;

                    //Get the confirm email token from query parameter
                    if (confirmEmailTokenFromQuery === null) {
                        console.error("Confirm email token not found");
                        throw new Error("Confirm email token not found");
                    }

                    //Get the decoded JWT
                    const decodedJwt = jwt(confirmEmailTokenFromQuery) as any;

                    if (decodedJwt === null) {
                        console.error("Confirm email invalid token");
                        throw new Error("Confirm email invalid token");
                    }

                    //Get the token expiration date to validate if it is expired
                    const tokenExpirationTimestamp = decodedJwt.exp * 1000;
                    const now = new Date().getTime();

                    //Verify is the token expired
                    if (tokenExpirationTimestamp < now) {
                        console.error("The verification confirm email token expired");
                        throw new Error("The verification confirm email token expired");
                    }

                    //If any error is detected set the progress to check and set a timeout to change to step 1 (change password)
                    //setTimeout(() => { setConfirmEmailTokenVerificationStatus(TokenValidationStatus.Validated) }, 2500);
                    setActivateAccountModel({ ...activateAccountModel, confirmEmailToken: confirmEmailTokenFromQuery });
                }
                catch (error) {
                    setTokensValidationStatus(TokenValidationStatus.Failed);
                }
            }

            if (activateAccountModel.resetPasswordToken.length === 0) {
                try {
                    const resetPasswordTokenFromQuery = searchParams.get("reset-password-token") || null;

                    //Get the confirm email token from query parameter
                    if (resetPasswordTokenFromQuery === null) {
                        console.error("Reset password token not found");
                        throw new Error("Reset password token not found");
                    }

                    //Get the decoded JWT
                    const decodedJwt = jwt(resetPasswordTokenFromQuery) as any;

                    if (decodedJwt === null) {
                        console.error("Reset password invalid token");
                        throw new Error("Reset password invalid token");
                    }

                    //Get the token expiration date to validate if it is expired
                    const tokenExpirationTimestamp = decodedJwt.exp * 1000;
                    const now = new Date().getTime();

                    //Verify is the token expired
                    if (tokenExpirationTimestamp < now) {
                        console.error("The verification reset password token expired");
                        throw new Error("The verification reset password token expired");
                    }

                    //If any error is detected set the progress to check and set a timeout to change to step 1 (change password)
                    //setTimeout(() => { setResetPasswordTokenVerificationStatus(TokenValidationStatus.Validated) }, 2500);
                    setActivateAccountModel({ ...activateAccountModel, resetPasswordToken: resetPasswordTokenFromQuery });
                }
                catch (error) {
                    setTokensValidationStatus(TokenValidationStatus.Failed);
                }
            }

            if (activateAccountModel.confirmEmailToken.length > 0 && activateAccountModel.resetPasswordToken.length > 0)
                setTimeout(() => { setTokensValidationStatus(TokenValidationStatus.Validated) }, 2500);
        }, []);

        //Switch between token verification status components
        switch (tokensValidationStatus) {
            case TokenValidationStatus.Processing:
                return (
                    <Box sx={{ textAlign: 'center', my: 2 }}>
                        <Typography variant='h6' >Iniciando ativação da conta...</Typography>
                        <LinearProgress sx={{ my: 2 }} />
                    </Box>
                );
            case TokenValidationStatus.Failed:
                return (
                    <Box sx={{ mt: 2 }}>
                        <Box sx={{ textAlign: 'center' }}>
                            <ErrorIcon color='error' fontSize='large' />
                            <Typography variant='h6' sx={{ mb: 2 }}>Ocorreu um erro ao ativar a conta</Typography>
                            <Typography variant='body2'>Entre em contato com o suporte e solicite o reenvio do email de ativação de conta.</Typography>
                        </Box>
                    </Box>
                );
            default:
                return null;
        }
    }

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setActivateAccountModel({ ...activateAccountModel, password: event.target.value });
    }

    const activateAccount = () => {
        //Call the API
        setLoading(true);
        AuthenticationService.activateAccount(activateAccountModel)
            .then(() => {
                notification(show({ type: 'success', message: `Conta ativada` }))
                //Go to login
                setTimeout(() => navigate("/login", { replace: true }), 2000);
            })
            .catch(e => notification(show({ type: 'error', message: new ErrorWrapper(e).message })))
            .finally(() => setLoading(false))
    }

    return (
        <Container id='formContainer' sx={{ bgcolor: 'primary.dark' }}>
            <AppNotification />
            <Logo mode={'primary.light'} />
            <LogoBar size={400} />
            <Box component='form' noValidate autoComplete='off' id='box'>
                {tokensValidationStatus != TokenValidationStatus.Validated ?
                    <TokenValidationProgress />
                    :
                    <Box>
                        <Typography variant='h6'>Estamos quase lá!</Typography>
                        <Typography sx={{ mt: 3, mb: 1 }}>Insira uma senha para a sua conta abaixo.</Typography>
                        <Typography variant='caption'>Lembre-se de usar seis ou mais caracteres contendo uma combinação de letras, números e símbolos.</Typography>
                        <TextField
                            sx={{ mt: 1, mb: 5 }}
                            required
                            variant='standard'
                            value={activateAccountModel.password}
                            label='Insira sua senha'
                            type='password'
                            onChange={handleChangePassword}
                            fullWidth
                        />
                        <LoadingButton fullWidth variant='contained' id='bc' loading={loading} onClick={activateAccount} disabled={activateAccountModel.password.length < 6}>
                            Ativar conta
                        </LoadingButton>
                    </Box>
                }
                <Link to='/login'><Button sx={{ textTransform: 'none', mt: 2 }}>Voltar para o login</Button></Link>
            </Box>
        </Container>
    );
}

export default ActivateAccount;