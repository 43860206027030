import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './mui.css';
import App from './App';
import store from './redux/store'
import { Provider } from 'react-redux'

import "@fontsource/open-sans"
import "@fontsource/nunito"

import { ThemeProvider } from '@mui/material/styles';
import { Theme } from "./style/theme"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
<Provider store={store}>
    <React.StrictMode>
      {
        <ThemeProvider theme={Theme}>
          <App />
        </ThemeProvider>}
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
