/**
 * Abstraction of platform user account
 */
export interface User {
    creationDate: Date,
    uuid: string,
    hasAvatar: boolean,
    email: string,
    preferredRegion: PreferredRegion,
    emailConfirmationDate: Date | null,
    blockDate: Date | null,
    minimumSessionCreationDate: Date,
    passwordExpirationDate: Date | null
};

/**
 * Abstraction of the platform user account type
 */
export interface UserAccount {
    preferredName: string,
    fullname: string,
    user: User,
    type: AccountType,
    roles: string[]
};

export enum AccountType {
    ADMINISTRATOR = 'ADMINISTRATOR',
    CUSTOMER = 'CUSTOMER',
    CUSTOMER_SUBUSER = 'CUSTOMER_SUBUSER'
}

/**
 * Store session data
 */
export interface Session {
    stl: number,
    stlRoles: STLRoles[]
};

export enum STLRoles {
    ROLE_STL_0,
    ROLE_STL_1,
    ROLE_STL_2,
    ROLE_STL_3,
    ROLE_STL_4
}

/**
 * Encapsulates User, UserAccount and Session objects of the same authenticated user
 */
export interface AuthenticatedUser {
    user: User,
    account: UserAccount,
    session: Session,
}

export enum PreferredRegion {
    BRA = "BRA"
};

export interface AuthenticationContextData {
    /**
     * Flag that indicates if the user is authenticated
     */
    isAuthenticated: () => boolean,

    /**
     * Authenticate the user using the combination of email and password
     */
    authenticateWithEmailAndPassword: (email: string, passsword: string, trustDevice: boolean) => Promise<any>,

    /**
     * Get the data from the authenticated user
     */
    user: () => AuthenticatedUser,

    /**
     * Get the version of the user that is always updated from the server
     */
    updatedUser: () => Promise<AuthenticatedUser>,

    /**
     * Return an flag indicating if the authenticated user has an STL level greater or equals than the given one
     */
    hasStlAtLeast: (requiredStlLevel: number) => boolean,

    /**
     * Renew user session with password
     */
    renewSessionWithPassword: (password: string, trustDevice: boolean) => Promise<AuthenticatedUser>,

    /**
     * Remove the authenticate user data from the application
     */
    signout: (callback: VoidFunction) => void;
}