import { useState, useContext, useEffect } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";

import { Alert, AlertTitle, Box, Container, CircularProgress, Divider, FormControl, IconButton, Select, MenuItem, InputLabel, SelectChangeEvent, Typography, TextField, Grid, ListItem, Tooltip, Button } from "@mui/material";
import { KeyboardBackspace, Info, AlternateEmail, ArrowForwardIos, Edit, History, Lock, LockOpen, Check } from "@mui/icons-material";

import AuthenticationContext from "../../contexts/authentication";
import { LoadingButton } from "../../utils/Components";
import ErrorWrapper from '../../utils/ErrorWrapper';
import AvatarUploader from '../../utils/avatar/AvatarUploader';

import { PreferredRegion, User } from '../../models/user';
import { PreferredRegions } from '../../static/preferred-regions';
import AdministratorService from '../../services/administrator';
import { Roles } from '../../contexts/roles';
import { AdminRoles, CustomerRoles } from '../../static/roles-info';
import { Administrator } from '../../models/administrator';
import UserService from '../../services/user';

import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import { AppNotification } from "../../utils/AppNotification"

//Dialogs
import RolesInfoDialog from "./dialogs/RolesInfoDialog"
import UpdateRolesDialog from "./dialogs/UpdateRolesDialog"
import UpdateEmailDialog from "./dialogs/UpdateEmailDialog"
import SendEmailDialog from "./dialogs/SendEmailDialog"
import BlockOrUnblockDialog from "./dialogs/BlockOrUnblockDialog"

/**
 * Selected administrator informations UI
 * @returns {JSX.Element}
 */
export default function InfoAdmin() {

    //Selected administrator uuid passed by url 
    const uuidFromURL = useParams().uuid || null;
    const navigate = useNavigate();

    useEffect(() => { fetchAdministrator() }, [uuidFromURL]);

    //Auth context props
    const useAuthentication = () => { return useContext(AuthenticationContext) }
    const auth = useAuthentication();
    const notification = useAppDispatch();

    //Dialogs props
    const [rolesInfoVisible, setRolesInfoVisible] = useState(false);
    const [updateRolesVisible, setUpdateRolesVisible] = useState(false);
    const [updateEmailVisible, setUpdateEmailVisible] = useState(false);
    const [blockUserVisible, setBlockUserVisible] = useState(false);
    const [sendEmailVisible, setSendEmailVisible] = useState(false);

    //State props
    const [loading, setLoading] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [adminModel, setAdminModel] = useState<Administrator>({
        email: '',
        fullname: '',
        preferredName: '',
        roles: [] as Roles[],
        preferredRegion: '' as PreferredRegion,
        user: {} as User
    })

    /**
     * Fetch the selected administrator with uuid from URL param 
     */
    const fetchAdministrator = async () => {
        setLoading(true);
        try {
            if (!uuidFromURL) throw new Error();

            if (uuidFromURL === auth.user().user.uuid) return navigate('/me', { replace: true })

            const fetchedAdmin = await AdministratorService.find(uuidFromURL)
            setAdminModel({
                email: fetchedAdmin.email,
                fullname: fetchedAdmin.fullname,
                preferredName: fetchedAdmin.preferredName,
                roles: fetchedAdmin.roles,
                preferredRegion: fetchedAdmin.user.preferredRegion as PreferredRegion,
                user: fetchedAdmin.user
            })
        }
        catch { navigate('/administrators', { replace: true }) }
        finally { setLoading(false) }
    }

    //Component events handlers
    const handleChangeFullName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdminModel({ ...adminModel, fullname: event.target.value });
    };
    const handleChangePreferredName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdminModel({ ...adminModel, preferredName: event.target.value });
    };
    const handleChangePR = (event: SelectChangeEvent) => {
        setAdminModel({ ...adminModel, preferredRegion: event.target.value as PreferredRegion });
    };

    const updateInfo = () => {
        setLoadingUpdate(true);

        AdministratorService.update(adminModel, adminModel.user.uuid)
            .then(() => notification(show({ type: 'success', message: `Administrador alterado` })))
            .catch((err) => {
                const e = new ErrorWrapper(err);
                notification(show({
                    type: 'error',
                    message: e.httpStatus === 422 ?
                        `Dados inválidos` : `Ocorreu um erro: ${e.message}`
                }))
            })
            .finally(() => setLoadingUpdate(false))
    }

    const updateRoles = (roles: Roles[]) => {
        //Update the user roles and close the dialog
        setAdminModel({ ...adminModel, roles: roles })
        setUpdateRolesVisible(false);
    }

    const updateEmail = (newEmail: string) => {
        //Update the user info and close the dialog
        adminModel.user.emailConfirmationDate = null;
        setAdminModel({ ...adminModel, email: newEmail })
        setUpdateEmailVisible(false)
    }

    const RenderUserRoles = (): JSX.Element | null => {
        const aRoles = AdminRoles.filter(aRole => adminModel.roles.indexOf(aRole.value as Roles) >= 0)
        const cRoles = CustomerRoles.filter(cRole => adminModel.roles.indexOf(cRole.value as Roles) >= 0)

        //If user has no role at all
        if (aRoles.length === 0 && cRoles.length === 0)
            return <Typography variant='body2' mt={2} color='error'>
                Este administrador não possui permissões de gerenciamento de conta de outros usuários.
            </Typography>

        return (
            <Box>
                <Typography variant='body1' mt={2}>Gerenciamento de administradores</Typography>
                {aRoles.length === 0 ?
                    <Typography variant='caption' mt={2} color='error'>Este administrador não possui permissões de gerenciamento de conta de administradores.</Typography>
                    :
                    (aRoles.map(role =>
                        <ListItem sx={{ fontSize: 14, display: 'list-item', pb: 0, color: 'text.secondary' }} key={role.value}>{role.name}</ListItem>
                    ))
                }
                <Typography variant='body1' mt={3}>Gerenciamento de clientes</Typography>
                {cRoles.length === 0 ?
                    <Typography variant='caption' mt={2} color='error'>Este administrador não possui permissões de gerenciamento de conta de clientes pertencentes a organizações.</Typography>
                    :
                    (cRoles.map(role =>
                        <ListItem sx={{ fontSize: 14, display: 'list-item', pb: 0, color: 'text.secondary' }} key={role.value}>{role.name}</ListItem>
                    ))
                }
            </Box >
        )
    }

    //Avatar handlers
    const uploadUserAvatar = (blob: Blob) => {
        AdministratorService.uploadAvatar(blob, adminModel.user.uuid)
            .then(() => notification(show({ type: 'success', message: `Avatar alterado` })))
            .catch(e => notification(show({ type: 'error', message: new ErrorWrapper(e).message })))
    }
    const removeUserAvatar = () => {
        AdministratorService.removeAvatar(adminModel.user.uuid)
            .then(() => notification(show({ type: 'success', message: `Avatar removido` })))
            .catch(e => notification(show({ type: 'error', message: new ErrorWrapper(e).message })))
    }

    return (
        <Container >
            {loading ? <Box width={1} mt={10} textAlign='center'><CircularProgress /></Box> :
                <Box width={.85} mx='auto' >
                    <IconButton onClick={() => navigate(-1)} sx={{ m: 2, ml: 5 }}> <KeyboardBackspace /></IconButton>
                    <Divider />
                    <Box width={{ sx: 1, sm: 1, md: .9, lg: .8 }} m='auto' mt={3} >
                        {
                            adminModel.user.blockDate ?
                                <Alert severity='error' variant='outlined' sx={{ mb: 2 }}>
                                    <AlertTitle color='inherit'><strong>Conta bloqueada!</strong></AlertTitle>
                                    Esta conta foi bloqueada em <strong>{new Date(adminModel.user.blockDate).toLocaleDateString()}</strong>.
                                    <Button color='inherit' className='nh' onClick={() => setBlockUserVisible(true)}>Clique aqui para desbloquear.</Button></Alert>
                                : null
                        }

                        <Grid container>
                            <Grid item xs={4} sm={4} md={3} lg={2} textAlign='center'>
                                <AvatarUploader user={adminModel.user} sx={{ fontSize: 100, color: 'text.disabled' }}
                                    onUpload={(blob: Blob) => uploadUserAvatar(blob)}
                                    onRemove={() => removeUserAvatar()} />
                            </Grid>
                            <Grid item xs alignSelf='center'>
                                <Typography variant='h5'>Gerenciamento de administrador</Typography>
                                <Typography variant='body2' sx={{ mt: 1 }}>
                                    Aqui você pode visualizar e alterar algumas informações deste usuário.
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container width={1} mt={3} gap={3}>
                            <Grid item className='bd' xs={12} sm={14} md lg p={3}>

                                <Typography mb={2} variant='body2' color='text.secondary'>SEGURANÇA</Typography>
                                <Typography variant='caption' color='text.disabled'>Email</Typography>

                                <Box id='bj' className='bb'>
                                    <Typography variant='body1'>{adminModel.email}</Typography>
                                    <IconButton size='small' sx={{ p: 0 }} onClick={() => setUpdateEmailVisible(true)}> <Edit sx={{ fontSize: 20 }} /></IconButton>
                                </Box>
                                {
                                    adminModel.user.emailConfirmationDate === null ?
                                        <Box className='ba' mt={2}>
                                            <Info color='info' sx={{ fontSize: 20, mr: 1 }} />
                                            <Typography variant='body2' color='text.secondary'>Email não confirmado</Typography>
                                        </Box>
                                        :
                                        <Box id='bj' mt={3} >
                                            <Typography variant='body2' className='ba' color='text.secondary' >
                                                <Check sx={{ mr: 1, fontSize: 20 }} color='success' />Email confirmado em:</Typography>
                                            <Typography variant='body2'>  {new Date(adminModel.user.emailConfirmationDate).toLocaleDateString()} </Typography>
                                        </Box>
                                }
                            </Grid>
                            <Grid item className='bd' xs={12} sm={14} md lg p={3} pb={1}>
                                <Typography variant='body2' color='text.secondary'>AÇÕES</Typography>

                                <Box id='bj' className='h c' mt={1} py={1.5} onClick={() => setSendEmailVisible(true)}>
                                    <Typography variant='body1' className='ba'  >
                                        <AlternateEmail sx={{ mx: 2, fontSize: 20 }} color='action' />Reenviar email</Typography>
                                    <ArrowForwardIos sx={{ fontSize: 15, mr: 3 }} color='action' />
                                </Box>

                                <Divider />
                                <Link to={`/administrators/${adminModel.user.uuid}/recent-activities`}>
                                    <Box id='bj' className='h c' py={1.5}>
                                        <Typography variant='body1' className='ba'>
                                            <History sx={{ mx: 2, fontSize: 22 }} color='action' />Atividades recentes</Typography>
                                        <ArrowForwardIos sx={{ fontSize: 15, mr: 3 }} color='action' />
                                    </Box>
                                </Link>
                                <Divider />
                                <Box id='bj' className='ha c' py={1.5} onClick={() => setBlockUserVisible(true)}>
                                    {adminModel.user.blockDate ?
                                        <Typography variant='body1' className='ba'  >
                                            <LockOpen color='error' sx={{ mx: 2, fontSize: 20 }} />Desbloquear conta</Typography>
                                        :
                                        <Typography variant='body1' className='ba'  >
                                            <Lock color='error' sx={{ mx: 2, fontSize: 20 }} />Bloquear conta</Typography>
                                    }
                                    <ArrowForwardIos sx={{ fontSize: 15, mr: 3 }} color='action' />
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container my={3} width={1} gap={3}>
                            <Grid item className='bd' xs={12} sm md lg p={0}>
                                <Box p={4} pt={3} height={266}>
                                    <Typography variant='body2' color='text.secondary'>CONTA</Typography>
                                    <TextField sx={{ my: 3 }} fullWidth value={adminModel.fullname} label='Nome completo' type='text' variant='standard' onChange={handleChangeFullName} />
                                    <TextField fullWidth value={adminModel.preferredName} label='Nome de preferência' type='text' variant='standard' onChange={handleChangePreferredName} />
                                    <FormControl fullWidth variant="standard" sx={{ mt: 3 }}>
                                        <InputLabel id="prs">Região de preferência</InputLabel>
                                        <Select
                                            value={adminModel.preferredRegion.toString()}
                                            onChange={handleChangePR}
                                            labelId="prs"
                                            label="Região de preferência"
                                        >
                                            {
                                                PreferredRegions.map((pr) => (<MenuItem key={pr.value} value={pr.value}>{pr.name}</MenuItem>))
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Divider />
                                <LoadingButton sx={{ py: 1 }} loading={loadingUpdate} fullWidth onClick={updateInfo} >Alterar informações</LoadingButton>
                            </Grid>
                            <Grid item className='bd' xs={12} sm md lg p={0}>
                                <Box p={3} pt={1} height={290}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant='body2' color='text.secondary' mt={1}>PERMISSÕES</Typography>
                                        <Tooltip title='Entenda as permissões' placement='top'>
                                            <IconButton onClick={() => setRolesInfoVisible(true)}><Info color='action' /></IconButton>
                                        </Tooltip>
                                    </Box>
                                    <RenderUserRoles />
                                </Box>
                                <Divider />
                                <Button sx={{ py: 1 }} fullWidth onClick={() => setUpdateRolesVisible(true)} >Alterar permissões</Button>
                            </Grid>
                        </Grid>
                    </Box >
                </Box>
            }
            {/* Components */}
            <AppNotification />

            {/* Dialogs */}
            <RolesInfoDialog open={rolesInfoVisible} close={() => setRolesInfoVisible(false)} />

            <UpdateRolesDialog uuid={adminModel.user.uuid} user={adminModel} open={updateRolesVisible} close={() => setUpdateRolesVisible(false)} update={updateRoles} />

            <UpdateEmailDialog
                open={updateEmailVisible} auth={auth} uuid={adminModel.user.uuid} email={adminModel.email}
                close={() => setUpdateEmailVisible(false)} onUpdate={updateEmail} />

            <SendEmailDialog
                name={adminModel.preferredName} email={adminModel.email}
                open={sendEmailVisible} close={() => setSendEmailVisible(false)}
                success={() => notification(show({ type: 'success', message: `Email enviado` }))} />

            <BlockOrUnblockDialog
                user={adminModel} open={blockUserVisible} auth={auth} close={() => setBlockUserVisible(false)}
                success={(block: boolean) => {
                    adminModel.user.blockDate = block ? new Date() : null;
                    setBlockUserVisible(false)
                }} />
        </Container >
    );
}