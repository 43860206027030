import { useState, useContext } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Box, Checkbox, Container, FormControlLabel, Snackbar, Typography, TextField } from "@mui/material";
import AuthenticationContext from "../contexts/authentication";
import { Logo, LogoBar, LoadingButton } from "../utils/Components";
import ErrorWrapper from '../utils/ErrorWrapper';

import { useAppDispatch } from '../redux/hooks';
import { show } from "../redux/features/app-global-notification/app-global-notification-slice"
import { AppNotification } from "../utils/AppNotification"

/**
 * Login UI
 * @returns {JSX.Element}
 */
export default function Login() {
  //State props
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [trustDevice, setTrustDevice] = useState(false);
  const [loading, setLoading] = useState(false);

  //Context props
  const useAuthentication = () => { return useContext(AuthenticationContext) }
  const authentication = useAuthentication();
  const navigate = useNavigate();
  const notification = useAppDispatch();

  //Event props
  const handleEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }
  const handlePasswordInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }
  const handleTrustDeviceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTrustDevice(event.target.checked);
  }

  /**
   * Handle login form submit event
   * @param {React.FormEvent<HTMLFormElement>} event 
   */
  const signin = async () => {
    setLoading(true);
    await authentication.authenticateWithEmailAndPassword(email, password, trustDevice)
      .then(() => navigate("/", { replace: true }))
      .catch(error => {
        const e = new ErrorWrapper(error);
        notification(show({
          type: 'error',
          message: e.httpStatus === 403 || e.httpStatus === 422 ?
            `Credenciais inválidas` :
            `Ocorreu um erro: ${new ErrorWrapper(e).message}`
        }))
      })
      .finally(() => setLoading(false))
  }

  //Capture the enter key press to signin
  const keyPress = (e: any) => { if (e.keyCode === 13 && validate()) signin() }

  //Validate the input values 
  const validate = () => {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) && password.length > 0) return true;
    return false;
  }

  return (
    <Container id='formContainer' sx={{ bgcolor: 'primary.dark' }}>
      <Logo mode={'primary.light'} />
      <LogoBar size={400} />
      <Box id='box'>
        <Typography variant='h5'>Bem vindo!</Typography>
        <Typography variant='subtitle2' color='text.secondary'>Preencha suas informações de acesso </Typography>

        <TextField sx={{ mt: 5 }} name="email" value={email} label="Email" type="email" variant="standard" onChange={handleEmailInputChange} onKeyDown={keyPress} />
        <TextField sx={{ mt: 2 }} name="password" value={password} label="Senha" type="password" variant="standard" onChange={handlePasswordInputChange} onKeyDown={keyPress} />

        <Link to="/recover-my-password">
          <Typography variant='caption' color='text.secondary'>Esqueceu sua senha?</Typography>
        </Link>

        <FormControlLabel sx={{ mt: 7, color: 'text.secondary' }} label="Continuar conectado" control={<Checkbox checked={trustDevice} onChange={handleTrustDeviceChange} size="small" />} />
        <LoadingButton loading={loading} variant="contained" onClick={signin} id='bc' disabled={!validate()}>Entrar</LoadingButton>
      </Box>
      <AppNotification/>
    </Container>
  );
}