import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Alert, Box, Tab } from "@mui/material";
import { useState } from 'react';

import ContractsQuota from './products/ContractsQuota';

import cartoriziIcon from '../../assets/cartorizi_icon.svg';
import { Customer } from '../../models/customer';

interface ProductsQuotaProps extends React.ComponentProps<any> {
    organization : Customer
}


export default function ProductsQuota(props: ProductsQuotaProps) {

    //Props
    const { organization, sendEmailConfirmationMail } = props;

    //Tab state
    const [productTab, setProductTab] = useState('0');
    const handleProductTabChange = (e: any, value: string) => setProductTab(value);

    return (
        <Box sx={{ width: '100%'}}>
            <TabContext value={productTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: 1, marginBottom: 3}}>
                    <TabList onChange={handleProductTabChange}>
                        <Tab icon={<img src={cartoriziIcon} style={{width: '25px'}}/>} iconPosition='start' label='Cartorizi' value='0' />
                    </TabList>
                </Box>
                <TabPanel value='0' sx={{ p: 0 }}>
                {
                    !(props.organization.user.emailConfirmationDate)
                    ?
                        <Alert 
                            severity='warning'
                            sx={{m : 4}}
                        >
                            A cota não poderá ser atribuída até que a organização "{props.organization.fullname}" confirme seu e-mail
                        </Alert>
                    :
                        <></>
                }
                    <ContractsQuota organization={organization} sendEmailConfirmationMail={sendEmailConfirmationMail}/>
                </TabPanel>  
            </TabContext>
        </Box >
    );
}