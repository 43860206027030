import { useState, useEffect } from 'react';

import { Box, IconButton, Divider, Typography, Tooltip, Button, Alert, Card, CardHeader, CardContent, Collapse, Stack, MenuItem, FormControl, InputLabel, Select, SelectChangeEvent } from "@mui/material";

import { MarkEmailUnread, Login, Info, LockReset, LockClock, SyncLock, AlternateEmail, ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

import { LoadingButton } from "../../utils/Components";
import ErrorWrapper from '../../utils/ErrorWrapper';

import UserAccountsService from '../../services/user-accounts';

import { AccountAccessActivity } from '../../models/account-access-activity';
import { Actions, AccountAccessActivities } from "../../static/account-access-activities"

/**
 * Recent activities of administrator UI
 * @returns {JSX.Element}
 */
export default function RecentActivities(props: React.ComponentProps<any>) {

    //Props
    const { uuid } = props;

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({ show: false, message: '' })

    //Requester activities state
    const [activities, setActivities] = useState<AccountAccessActivity[]>([]);
    const [fetchMoreContent, setFetchMoreContent] = useState(true);
    const [action, setAction] = useState('' as Actions)
    const [page, setPage] = useState(1);

    //Load activities every time the selected action changes
    useEffect(() => { fetchActivities() }, [action]);

    const fetchActivities = async () => {
        setLoading(true);
        setError({ show: false, message: '' })

        UserAccountsService.fetchActivities(uuid, page, action)
            .then(resp => {
                if (!resp) setFetchMoreContent(false)
                else {
                    resp.forEach(r => activities.push(r));
                    setPage(page + 1);
                }
            })
            .catch(e => setError({ show: true, message: new ErrorWrapper(e).message }))
            .finally(() => setLoading(false))
    }

    const normalize = (milliseconds: string | number | Date) => {
        return new Date(milliseconds).toLocaleTimeString([],
            {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            })
    };


    const RenderActivities = (props: React.ComponentProps<any>): JSX.Element => {
        //Current activity
        const { activity } = props;
        const [expanded, setExpanded] = useState(false);
        const ExpandIcon = (): JSX.Element => {
            return (
                <IconButton onClick={() => setExpanded(!expanded)}>
                    <ExpandMoreIcon sx={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                </IconButton >
            )
        }

        return (
            <Card className='bd' sx={{ mb: 2 }}>
                <RenderCardHeader activity={activity} expandIcon={ExpandIcon} />
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Divider />
                    <CardContent>
                        <Typography><strong>ID da atividade:</strong> {activity.id}</Typography>
                        <RenderExtraContent activity={activity} />
                    </CardContent>
                </Collapse>
            </Card>
        );
    };

    const RenderCardHeader = (props: React.ComponentProps<any>): JSX.Element => {
        //Current activity
        const { activity, expandIcon } = props;

        //Styles
        const icon_card = { fontSize: 28, px: { xs: 0, sm: 1 } }

        function cardHeaderProps(date: any): Object {
            return {
                titleTypographyProps: { fontSize: { xs: 14, sm: 16 } },
                subheader: `Realizado em: ${normalize(date)}`,
                subheaderTypographyProps: { mt: 1, fontSize: { xs: 13, sm: 14 } }
            }
        }

        switch (activity.action as Actions) {
            case Actions.EMAIL_CHANGED:
                return (
                    <CardHeader
                        avatar={<AlternateEmail color='action' sx={icon_card} />}
                        action={expandIcon()}
                        title='Alteração de email'
                        {...cardHeaderProps(activity.creationDate)}
                    />
                )
            case Actions.EMAIL_CHANGE_REQUEST:
                return (
                    <CardHeader
                        avatar={<MarkEmailUnread color='action' sx={icon_card} />}
                        action={expandIcon()}
                        title='Solicitação de troca de email'
                        {...cardHeaderProps(activity.creationDate)}
                    />
                )
            case Actions.LOGIN:
                return (
                    <CardHeader
                        avatar={<Login color='action' sx={icon_card} />}
                        action={expandIcon()}
                        title='Acesso à conta'
                        {...cardHeaderProps(activity.creationDate)}
                    />
                )
            case Actions.PASSWORD_CHANGED:
                return (
                    <CardHeader
                        avatar={<LockReset color='action' sx={icon_card} />}
                        action={expandIcon()}
                        title='Troca de senha'
                        {...cardHeaderProps(activity.creationDate)}
                    />
                )
            case Actions.PASSWORD_EXPIRATION:
                return (
                    <CardHeader
                        avatar={<LockClock color='action' sx={icon_card} />}
                        action={expandIcon()}
                        title='Expiração de senha'
                        {...cardHeaderProps(activity.creationDate)}
                    />
                )
            case Actions.SESSION_EXPIRATION:
                return (
                    <CardHeader
                        avatar={<SyncLock color='action' sx={icon_card} />}
                        action={expandIcon()}
                        title='Expiração de sessões'
                        {...cardHeaderProps(activity.creationDate)}
                    />
                )
            default: return <></>;
        }
    }

    const RenderExtraContent = (props: React.ComponentProps<any>): JSX.Element => {
        //Extract type action from the element
        const { activity } = props;
        const ipGeoLocationData = activity.ipGeoLocationData;

        if (ipGeoLocationData) {
            return (
                <Stack direction={{ xs: 'column', sm: 'row' }} mt={1} spacing={1}>
                    <Stack spacing={1} mr={2}>
                        <Typography><strong>IP:</strong> {ipGeoLocationData.ip}</Typography>
                        <Typography><strong>Hostname:</strong> {ipGeoLocationData.hostname || `Sem dados`}</Typography>

                    </Stack>
                    <Stack spacing={1} >
                        <Typography><strong>Cidade:</strong> {ipGeoLocationData.city || `Sem dados`}</Typography>
                        <Typography><strong>Região:</strong> {ipGeoLocationData.region || `Sem dados`}</Typography>
                    </Stack>
                    <Stack pl={{ sx: 0, sm: 2 }} >
                        <Typography><strong>Localização:</strong> {ipGeoLocationData.location || `Sem dados`}</Typography>
                    </Stack>
                </Stack >
            );
        }
        return <></>
    }

    const handleSelectChange = (event: SelectChangeEvent) => {
        setAction(event.target.value as Actions)
        resetState();
    };

    //Reset state values to make a new request
    const resetState = () => {
        setPage(1);
        setActivities([]);
        setFetchMoreContent(true);
    };

    return (
        <Box mt={2}>
            <Typography>Histórico das atividades recentes de conta deste cliente</Typography>
            <FormControl variant="standard" fullWidth sx={{ mt: 2, mb: 4 }}>
                <InputLabel id="action">Filtre por ação</InputLabel>
                <Select
                    value={action}
                    onChange={handleSelectChange}
                    labelId="action"
                >
                    <MenuItem value={''}>Sem filtro</MenuItem>
                    {
                        AccountAccessActivities.map(aaa => (<MenuItem key={aaa.value} value={aaa.value}>{aaa.label}</MenuItem>))
                    }
                </Select>
            </FormControl>
            {
                activities.map(activity => <RenderActivities activity={activity} key={activity.id} />)
            }
            {
                !fetchMoreContent && !error.show ?
                    <Alert variant='outlined'>Não há dados a serem exibidos.</Alert>
                    :
                    !error.show ?
                        <LoadingButton loading={loading} fullWidth onClick={fetchActivities}>Buscar mais atividades</LoadingButton>
                        :
                        error.show ?
                            <Button color='error' sx={{ mx: 1, mb: 2 }}
                                startIcon={<Tooltip title={error.message}><Info /></Tooltip>}
                                onClick={fetchActivities}>
                                Ocorreu um erro. Tente novamente.
                            </Button> : null
            }
        </Box>
    );
}