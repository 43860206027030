import { useState, useContext } from 'react';
import { Link } from "react-router-dom";
import { Box, Button, Divider, Grid, IconButton, Typography, TextField, ListItem, InputLabel, Select, MenuItem, SelectChangeEvent, FormControl, Tooltip, DialogContentText } from "@mui/material";
import { DialogContent, Dialog, DialogTitle, DialogActions } from "@mui/material";
import { KeyboardBackspace, Edit, History, ArrowForwardIos, Info, Mail, Send, Check, LockReset } from "@mui/icons-material";
import { Roles, RolesContext } from "../contexts/roles";
import { AdminRoles, CustomerRoles } from "../static/roles-info";
import { PreferredRegion, User } from "../models/user";
import { PreferredRegions } from "../static/preferred-regions";
import AuthenticationContext from "../contexts/authentication";
import { LoadingButton } from "../utils/Components";
import Avatar from '../utils/avatar/Avatar'
import RolesInfoDialog from './administrator/dialogs/RolesInfoDialog';
import UpdateRolesDialog from './administrator/dialogs/UpdateRolesDialog';
import AdministratorService from '../services/administrator';
import ErrorWrapper from '../utils/ErrorWrapper';
import { Administrator } from '../models/administrator';
import AuthenticationService from '../services/authentication';
import { useAppDispatch } from '../redux/hooks';
import { show } from "../redux/features/app-global-notification/app-global-notification-slice"
import { AppNotification } from "../utils/AppNotification"
import StlInterceptor from "../contexts/stl"
import { SecurityTierLevels } from '../store/stl';
import RenewAccessDialog from './account-management/RenewAccessDialog';

/**
 * Personal info of authenticated user UI
 * @returns {JSX.Element}
 */
export default function MyProfile() {

  //Auth user props
  const useAuthentication = () => { return useContext(AuthenticationContext) }
  const auth = useAuthentication();
  const notification = useAppDispatch();
  const userRoles = useContext(RolesContext);
  const stl = useContext(StlInterceptor);

  //Utils
  const [loading, setLoading] = useState(false);

  //Dialogs
  const [rolesInfoVisible, setRolesInfoVisible] = useState(false);
  const [updateEmailVisible, setUpdateEmailVisible] = useState(false);
  const [updateRolesVisible, setUpdateRolesVisible] = useState(false);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);

  const [authUser, setAuthUser] = useState<Administrator>({
    email: auth.user().user.email,
    fullname: auth.user().account.fullname,
    preferredName: auth.user().account.preferredName,
    preferredRegion: auth.user().user.preferredRegion as PreferredRegion,
    roles: auth.user().account.roles as Roles[],
    user: auth.user().user as User
  })

  const RenderRoles = (): JSX.Element | null => {
    const aRoles = AdminRoles.filter(aRole => authUser.roles.indexOf(aRole.value as Roles) >= 0)
    const cRoles = CustomerRoles.filter(cRole => authUser.roles.indexOf(cRole.value as Roles) >= 0)

    //If user has no role at all
    if (aRoles.length === 0 && cRoles.length === 0)
      return <Typography variant='body2' mt={2} color='error'>
        Você não possui permissões de gerenciamento de conta de outros usuários.
      </Typography>

    return (
      <Box>
        <Typography variant='body1' mt={2}>Gerenciamento de administradores</Typography>
        {aRoles.length === 0 ?
          <Typography variant='caption' mt={2} color='error'>Este administrador não possui permissões de gerenciamento de conta de administradores.</Typography>
          :
          (aRoles.map(role =>
            <ListItem sx={{ fontSize: 14, display: 'list-item', pb: 0, color: 'text.secondary' }} key={role.value}>{role.name}</ListItem>
          ))
        }
        <Typography variant='body1' mt={3}>Gerenciamento de clientes</Typography>
        {cRoles.length === 0 ?
          <Typography variant='caption' mt={2} color='error'>Este administrador não possui permissões de gerenciamento de conta de clientes pertencentes a organizações.</Typography>
          :
          (cRoles.map(role =>
            <ListItem sx={{ fontSize: 14, display: 'list-item', pb: 0, color: 'text.secondary' }} key={role.value}>{role.name}</ListItem>
          ))
        }
      </Box >
    )

  }

  //Style
  const st = { pb: 0, display: 'flex', alignItems: 'center', mb: 2 }
  const iconSt = { mr: 2, fontSize: 30 }


  /**
  * Update email dialog 
  */
  const UpdateEmailDialog = (): JSX.Element => {

    const [newEmail, setNewEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [loadingEmail, setLoadingEmail] = useState(false)

    const sendMailChangeEmail = () => {
      setLoadingEmail(true);
      AuthenticationService.sendEmailChangeMail({ newEmail })
        .then(() => setEmailSent(true))
        .catch(error => {
          const e = new ErrorWrapper(error);
          notification(show({
            type: 'error',
            message: e.httpStatus === 422 ?
              `Dados inválidos` : e.httpStatus === 403 ?
                `Ação não autorizada` : `${new ErrorWrapper(e).message}`
          }))
        })
        .finally(() => setLoadingEmail(false))
    }

    return (
      <Dialog onClose={() => setUpdateEmailVisible(false)} open={updateEmailVisible}>
        {emailSent ?
          <Box>
            <DialogTitle sx={st} >
              <Check sx={iconSt} color='success' />Solicitação enviada
            </DialogTitle>
            <DialogContent>
              <Typography variant='body1' mt={3}>
                Enviado para: <b>{newEmail}</b>.
              </Typography>
              <Typography variant='body1'>
                Acesse a caixa de entrada do email solicitado e siga as instruções para realizar a alteração.
              </Typography>
              <Typography variant='caption' color='text.secondary' mt={1} >Caso não encontre o email verifique seu SPAM.</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setUpdateEmailVisible(false)}>Voltar</Button>
            </DialogActions>
          </Box>
          :
          <Box>
            <DialogTitle sx={st}>
              <Mail sx={iconSt} color='action' />Solicitar alteração de email
            </DialogTitle>
            <DialogContent >
              <Typography mt={2} variant='body1'>Insira o endereço de email desejado e será enviado uma solicitação para realizar a alteração</Typography>
              <TextField sx={{ mt: 1 }} onChange={(e) => setNewEmail(e.target.value)} fullWidth value={newEmail} label='Novo email' variant='standard' />
            </DialogContent>
            <DialogActions >
              <Button onClick={() => setUpdateEmailVisible(false)} color='error'>Cancelar</Button>
              <LoadingButton startIcon={<Send />} disabled={!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail)) || newEmail === authUser.email}
                loading={loadingEmail} variant='contained' onClick={sendMailChangeEmail} id='bc'> Enviar solicitação </LoadingButton>
            </DialogActions>
          </Box>
        }
        <AppNotification />
      </Dialog >
    )
  }

  /**
   * Update email dialog 
   */
  const UpdatePasswordDialog = (): JSX.Element => {

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loadingPassword, setLoadingPassword] = useState(false);
    const [renewSessionDialog, setRenewSessionDialog] = useState(false);

    const changePassword = () => {
      setLoadingPassword(true);
      setRenewSessionDialog(false);

      AuthenticationService.changePassword(newPassword)
        .then(() => {
          setShowPasswordDialog(false)
          notification(show({
            type: 'success',
            message: `Senha alterada`
          }))
        })
        .catch(e =>
          notification(show({
            type: 'error',
            message: `${new ErrorWrapper(e).message}`
          })))
        .finally(() => setLoadingPassword(false))
    }

    return (
      <Dialog onClose={() => setShowPasswordDialog(false)} open={showPasswordDialog}>
        <Box>
          <DialogTitle sx={st}>
            <LockReset sx={iconSt} color='action' />Alteração de senha
          </DialogTitle>
          <DialogContent >
            <Typography variant='body1' my={1}>Insira a seguir a nova senha desejada.</Typography>
            <Typography variant='caption'>Lembre-se de usar seis ou mais caracteres contendo uma combinação de letras, números e símbolos.</Typography>
            <TextField sx={{ mt: 1 }} onChange={(e) => setNewPassword(e.target.value)} fullWidth value={newPassword}
              label='Nova senha' variant='standard' type='password' />
            <TextField sx={{ mt: 1 }} onChange={(e) => setConfirmPassword(e.target.value)} fullWidth value={confirmPassword}
              label='Confirmar senha' variant='standard' type='password' />
          </DialogContent>
          <DialogActions >
            <Button onClick={() => setShowPasswordDialog(false)}>Cancelar</Button>
            <LoadingButton disabled={newPassword.length < 6 || newPassword != confirmPassword} loading={loadingPassword} variant='contained'
              onClick={() => {
                stl.require(auth, SecurityTierLevels.MaxLevel)
                  .then(() => changePassword())
                  .catch(() => setRenewSessionDialog(true))
              }} id='bc'> Salvar </LoadingButton>
          </DialogActions>
        </Box>

        <RenewAccessDialog sessionRenewed={changePassword} open={renewSessionDialog} />
        <AppNotification />
      </Dialog >
    )
  }

  //Dialog success props - update auth user roles 
  const updateRoles = (newRoles: Roles[]) => {
    setAuthUser({ ...authUser, roles: newRoles })
    setUpdateRolesVisible(false);
  }

  /**
   * Update auth user personal info
   */
  const updateInfo = () => {
    setLoading(true);
    AdministratorService.update(authUser, authUser.user.uuid)
      .then(() => {
        //Update local storage with the new auth user data
        auth.updatedUser();
        notification(show({ type: 'success', message: `Dados alterados` }))
      })
      .catch((error) => {
        const e = new ErrorWrapper(error);
        notification(show({
          type: 'error',
          message: e.httpStatus === 422 ?
            `Dados inválidos` : `${new ErrorWrapper(e).message}`
        }))
      })
      .finally(() => setLoading(false))
  }

  //Component events handlers 
  const handleChangeFullName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuthUser({ ...authUser, fullname: event.target.value });
  };
  const handleChangePreferredName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuthUser({ ...authUser, preferredName: event.target.value });
  };
  const handleChangePR = (event: SelectChangeEvent) => {
    setAuthUser({ ...authUser, preferredRegion: event.target.value as PreferredRegion });
  };

  return (
    <Box width={.85} m='auto'>
      <Link to='/'><IconButton sx={{ m: 2, ml: 5 }}> <KeyboardBackspace /></IconButton></Link>
      <Divider />
      <Box sx={{ width: .9, maxWidth: 1000, margin: 'auto', mt: 3 }}>
        {/* HEADER */}
        <Grid container>
          <Grid item xs={4} sm={4} md={2} lg={2}>
            <Avatar user={auth.user().user} sx={{ fontSize: 100, color: 'text.disabled', m: 'auto' }} />
          </Grid>
          <Grid item xs alignSelf='center'>
            <Typography variant='h5'>Minhas informações pessoais</Typography>
            <Typography variant='body2' mt={1}>
              Aqui você pode consultar e alterar suas informações pessoais e suas atividades recentes.
            </Typography>
          </Grid>
        </Grid>


        {/* SECOND GRID CONTAINER */}
        <Grid container gap={3} mt={3}>
          <Grid item className='bd' xs={12} sm={14} md lg p={3}>
            <Typography variant='body2' color='text.secondary' mb={1}>SEGURANÇA</Typography>

            <Typography variant='caption' color='text.disabled'>Email</Typography>
            <Box id='bj' className='bb'>
              <Typography variant='body1'>{authUser.email}</Typography>
              {
                (userRoles.hasRole(auth, Roles.AM_SM)) ?
                  <IconButton size='small' sx={{ p: 0 }} onClick={() => setUpdateEmailVisible(true)}> <Edit sx={{ fontSize: 20 }} /></IconButton> :
                  null
              }
            </Box>
          </Grid>
          <Grid item className='bd' xs={12} sm md lg p={3} pb={1}>
            <Typography variant='body2' color='text.secondary' mb={1}>AÇÕES</Typography>
            <Box id='bj' className='h c' py={1.5} onClick={() => setShowPasswordDialog(true)}>
              <Typography variant='body1' className='ba'>
                <LockReset sx={{ mx: 2, fontSize: 25 }} color='action' />Trocar minha senha</Typography>
              <ArrowForwardIos sx={{ fontSize: 15, mr: 3 }} color='action' />
            </Box>
            <Divider />
            <Link to='/me/recent-activities'>
              <Box id='bj' className='h c' py={1.5}>
                <Typography variant='body1' className='ba'>
                  <History sx={{ mx: 2, fontSize: 25 }} color='action' />Visualizar minhas atividades recentes</Typography>
                <ArrowForwardIos sx={{ fontSize: 15, mr: 3 }} color='action' />
              </Box>
            </Link>
          </Grid>
        </Grid>


        {/* FIRST GRID CONTAINER */}
        <Grid container gap={3} my={3}>
          <Grid item className='bd' xs={12} sm={14} md lg p={0}>
            <Box p={4} pt={3} height={266}>
              <Typography variant='body2' color='text.secondary'>CONTA</Typography>
              <TextField variant='standard' fullWidth sx={{ my: 2 }}
                label='Nome completo' value={authUser.fullname} onChange={handleChangeFullName}
              />
              <TextField variant='standard' fullWidth sx={{ my: 2 }} onChange={handleChangePreferredName}
                label='Nome de preferência' value={authUser.preferredName}
              />
              <FormControl variant='standard' fullWidth sx={{ my: 2 }}>
                <InputLabel id='prs'>Região de preferência</InputLabel>
                <Select labelId='prs' onChange={handleChangePR} value={authUser.preferredRegion.toString()}>
                  {
                    PreferredRegions.map((pr) => (<MenuItem key={pr.value} value={pr.value}>{pr.name}</MenuItem>))
                  }
                </Select>
              </FormControl>
            </Box>
            <Divider />
            {
              (userRoles.hasRole(auth, Roles.AM_SM)) ?
                <LoadingButton variant="text" sx={{ py: 1 }} loading={loading} fullWidth onClick={updateInfo} >Alterar informações</LoadingButton> :
                null
            }

          </Grid>

          <Grid item className='bd' xs={12} sm md lg p={0}>
            <Box p={3} pt={1} height={290}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant='body2' color='text.secondary' mt={1}>PERMISSÕES</Typography>
                <Tooltip title='Entenda as permissões' placement='top'>
                  <IconButton onClick={() => setRolesInfoVisible(true)}><Info color='action' /></IconButton>
                </Tooltip>
              </Box>
              <RenderRoles />
            </Box>
            <Divider />
            {
              (userRoles.hasRole(auth, Roles.AM_SM)) ?
                <Button sx={{ py: 1 }} fullWidth onClick={() => setUpdateRolesVisible(true)} >Alterar permissões</Button> :
                null
            }
          </Grid>
        </Grid>
      </Box>

      {/* Components */}
      <AppNotification />
      <UpdateEmailDialog />
      <UpdatePasswordDialog />

      {/* Dialogs */}
      <RolesInfoDialog open={rolesInfoVisible} close={() => setRolesInfoVisible(false)} />
      <UpdateRolesDialog uuid={authUser.user.uuid} user={authUser} isAuthUser open={updateRolesVisible}
        close={() => setUpdateRolesVisible(false)} update={updateRoles} />
    </Box>
  );
}