/* import './App.css'; */
import React, { useContext, useState } from "react";
import { BrowserRouter, Routes, Route, NavLink, Navigate, Outlet } from "react-router-dom"
import { Button, Box, Container, Grid, Tab, Typography, IconButton, Toolbar, AppBar, Menu, MenuItem } from '@mui/material';

import { Logo, LogoBar } from "./utils/Components"

import { RolesContext, Roles } from "./contexts/roles";
import AuthenticationContext from './contexts/authentication';
import AuthStatusRouter from "./views/account-management/AuthStatusRouter";

import Avatar from './utils/avatar/Avatar'

import LoginView from "./views/Login"
import ProfileView from "./views/MyProfile"
import AdminView from "./views/administrator/ListAdmin"
import AddAdminView from "./views/administrator/AddAdmin"
import InfoAdminView from "./views/administrator/InfoAdmin"
import AdminRecentActivitiesView from "./views/administrator/RecentActivities";
import CustomerView from "./views/customer/CustomerView"
import ConfirmEmailWithToken from "./views/account-management/ConfirmEmailWithToken";
import RecoverMyPassword from "./views/account-management/RecoverMyPassword"
import ResetPasswordByEmail from "./views/account-management/ResetPasswordByEmail"
import ActivateAccount from "./views/account-management/ActivateAccount"
import ChangeEmailWithToken from "./views/account-management/ChangeEmailWithToken"
import MyRecentActivities from "./views/MyRecentActivities";

function App() {

    //Context user variables 
    const useAuthentication = () => { return useContext(AuthenticationContext) }
    const authUser = useAuthentication();
    const userRoles = useContext(RolesContext);

    const AppContent = () => {
        if (authUser.isAuthenticated() === true) return <AuthenticatedContent />;
        return <Navigate to="/login" />
    }

    // AUTHENTICATED VIEW - Tab Panels 
    const AuthenticatedContent = () => {

        const [anchorAuthUserMenu, setAnchorAuthUserMenu] = useState<HTMLElement | null>(null);

        //Refs
        const userMenuRef = React.useRef<HTMLDivElement | null>(null);

        //Event handlers
        const handleUserMenuClick = () => {
            setAnchorAuthUserMenu(userMenuRef.current || null);
        }

        const mainMenuItems = [
            {
                label: 'Clientes',
                link: '/clients',
                enabled: () => userRoles.hasRole(authUser, Roles.CM)
            },
            {
                label: 'Administradores',
                link: '/administrators',
                enabled: () => userRoles.hasRole(authUser, Roles.AM) || userRoles.hasRole(authUser, Roles.AM_SM)
            }
        ]

        const userMenuItems = [
            {
                label: 'Ver perfil',
                link: '/me',
                click: () => setAnchorAuthUserMenu(null)
            },
            {
                label: 'Sair',
                link: '/login',
                click: () => authUser.signout(() => { })
            }
        ]

        /**
         * Create the authenticated layout
         */
        return (
            <Container maxWidth={false} sx={{ p: 0 }}>
                <AppBar position="static">
                    <Toolbar disableGutters>
                        <Logo auth mode={'primary.light'} />
                        <Box sx={{ borderLeft: '1px solid #FFF', pl: 3, flexGrow: 1 }}>
                            {mainMenuItems.filter(menuItem => menuItem.enabled()).map(((menuItem) => (
                                <Button key={menuItem.link} className='nh' color='secondary' component={NavLink} to={menuItem.link} sx={{ p: 2.5 }}>{menuItem.label}</Button>
                            )))}
                        </Box>
                        <Box sx={{ flexGrow: 0, mr: 3 }} ref={userMenuRef}>
                            <IconButton color='secondary' className='nh' onClick={handleUserMenuClick}>
                                <Avatar user={authUser.user().user} sx={{ fontSize: 48 }} />
                            </IconButton>
                            <Menu
                                anchorEl={anchorAuthUserMenu}
                                open={anchorAuthUserMenu != null}
                                onClose={() => { setAnchorAuthUserMenu(null) }}
                            >
                                {userMenuItems.map(((menuItem) => (
                                    <MenuItem key={menuItem.link} onClick={menuItem.click} color='secondary' component={NavLink} to={menuItem.link} >
                                        {menuItem.label}
                                    </MenuItem>
                                )))}
                            </Menu>

                        </Box>
                    </Toolbar>
                </AppBar>

                {/*Application view (routes)*/}
                <Grid item>
                    {/*The Outlet component is the child route elements that will be shown*/}
                    <Outlet />
                </Grid>
            </Container>

        );
    }

    // MAIN VIEW - App Landing Page
    const MainView = (): JSX.Element => {
        const boxStyle = { textAlign: 'center', top: '35%', position: 'relative', display: 'flex', flexDirection: 'column', width: 450, margin: 'auto' }
        const userName = authUser?.user()?.account?.preferredName || "Ba";

        return (
            <Container sx={{ height: '90vh' }} >
                <Box sx={boxStyle} >
                    <Typography variant='h5' color='text.primary'>Olá, {userName}!</Typography>
                    <Typography variant='body1' color='text.secondary' sx={{ my: 3 }}>
                        Aqui você pode gerenciar colaboradores, consultar suas informações pessoais e muito mais.
                    </Typography>
                    <Box sx={{ justifyContent: 'space-around', display: 'flex', mb: 2 }}>
                        {userRoles.hasRole(authUser, Roles.CM) ? <Button component={NavLink} to={"/clients"}>Gerenciar clientes</Button> : null}
                        {userRoles.hasRole(authUser, Roles.AM) || userRoles.hasRole(authUser, Roles.AM_SM) ? <Button component={NavLink} to={"/administrators"}>Gerenciar administradores</Button> : null}
                    </Box>
                    <LogoBar size={boxStyle.width} />
                    <Logo mode={'primary.dark'} bold />
                </Box>
            </Container>
        );
    }

    const hasRole = (role: Roles) => {
        if (userRoles.hasRole(authUser, role)) return true;
        return false
    }

    //MAIN RETURN - Routes
    return (
        <BrowserRouter>
            <Routes>
                {/*Public routes*/}
                <Route path="/login" element={<LoginView />} />
                <Route path="/recover-my-password" element={<RecoverMyPassword />} />
                <Route path="/activate-account" element={<ActivateAccount />} />
                <Route path="/confirm-email-with-token" element={<ConfirmEmailWithToken />} />
                <Route path="/reset-password-by-email" element={<ResetPasswordByEmail />} />
                <Route path="/auth-status/:statusCode" element={<AuthStatusRouter />} />

                {/*  Authenticated route */}
                <Route path="/change-email-with-token" element={authUser.isAuthenticated() ? <ChangeEmailWithToken /> : <Navigate to="/" />} />

                {/* Authenticated only routes */}
                <Route element={<AppContent />}>
                    <Route path="/" element={<MainView />} />
                    <Route path="/me" element={<ProfileView />} />
                    <Route path="/me/recent-activities" element={<MyRecentActivities />} />

                    <Route path="/clients" element={hasRole(Roles.CM) ? <CustomerView /> : <Navigate to="/" />} />

                    <Route path="/administrators" element={hasRole(Roles.AM) || hasRole(Roles.AM_SM) ? <AdminView /> : <Navigate to="/" />} />
                    <Route path="/administrators/register" element={hasRole(Roles.AM_SM) ? <AddAdminView /> : <Navigate to="/" />} />
                    <Route path="/administrators/:uuid" element={hasRole(Roles.AM_SM) ? <InfoAdminView /> : <Navigate to="/" />} />
                    <Route path="/administrators/:uuid/recent-activities" element={hasRole(Roles.AM_SM) ? <AdminRecentActivitiesView /> : <Navigate to="/" />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default App;
