import { useState, useContext, useEffect } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import { Box, IconButton, Divider, Typography, Grid, Tab, Button, Alert, Card, CardHeader, CardContent, Collapse, Stack, MenuItem, FormControl, InputLabel, Select, SelectChangeEvent } from "@mui/material";

import { TabContext, TabList, TabPanel } from '@mui/lab';

import { KeyboardBackspace, ManageHistory, Lock, AddModerator, LockOpen, ManageAccounts, AlternateEmail, BarChart, Person, ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import AuthenticationContext from '../../contexts/authentication';
import { LoadingButton } from "../../utils/Components";
import ErrorWrapper from '../../utils/ErrorWrapper';
import AdministratorService from '../../services/administrator';
import { AdministratorActivity } from '../../models/administrator-activity';
import { Actions, AdministratorActivities } from "../../static/administrator-activities"

import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import { AppNotification } from "../../utils/AppNotification"


enum Type {
    REQ = 'requester',
    TAR = 'target'
}

/**
 * Recent activities of administrator UI
 * @returns {JSX.Element}
 */
export default function RecentActivities() {

    //Selected administrator uuid passed by url 
    const uuidFromURL = useParams().uuid || '';

    //Context props
    const useAuthentication = () => { return useContext(AuthenticationContext) }
    const auth = useAuthentication();
    const notification = useAppDispatch();
    const navigate = useNavigate();

    //States
    const [loading, setLoading] = useState(false)
    const [action, setAction] = useState<Actions>('' as Actions);

    //Requester activities state
    const [reqActivities, setReqActivities] = useState<AdministratorActivity[]>([]);
    const [fetchMoreReqContent, setFetchMoreReqContent] = useState(true);
    const [reqPage, setReqPage] = useState(1);

    //Target activities state
    const [tarActivities, setTarActivities] = useState<AdministratorActivity[]>([]);
    const [fetchMoreTarContent, setFetchMoreTarContent] = useState(true);
    const [tarPage, setTarPage] = useState(1);

    //Tab state
    const [tab, setTab] = useState('0');
    const handleTabChange = (e: any, value: string) => setTab(value);

    useEffect(() => {
        if (!uuidFromURL) return navigate('/administrators', { replace: true })
        fetchRequesterActivities(); fetchTargetActivities()
    }, [uuidFromURL, action]);

    const fetchRequesterActivities = () => {
        setLoading(true);
        AdministratorService.fetchAdministratorActivities(Type.REQ, uuidFromURL, reqPage, action)
            .then(resp => {
                if (!resp) setFetchMoreReqContent(false)
                else {
                    resp.forEach(r => reqActivities.push(r));
                    setReqPage(reqPage + 1);
                }
            })
            .catch(e => notification(show({ type: 'error', message: new ErrorWrapper(e).message })))
            .finally(() => setLoading(false))
    }

    const fetchTargetActivities = () => {

        setLoading(true);

        AdministratorService.fetchAdministratorActivities(Type.TAR, uuidFromURL, tarPage, action)
            .then(resp => {
                if (!resp) setFetchMoreTarContent(false)
                else {
                    resp.forEach(r => tarActivities.push(r));
                    setTarPage(tarPage + 1);
                }
            })
            .catch(e => notification(show({ type: 'error', message: new ErrorWrapper(e).message })))
            .finally(() => setLoading(false))
    }

    const normalize = (milliseconds: string | number | Date) => {
        return new Date(milliseconds).toLocaleTimeString([],
            {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            })
    };


    const RenderActivities = (props: React.ComponentProps<any>): JSX.Element => {
        //Current activity
        const { activity, type } = props;
        const [expanded, setExpanded] = useState(false)

        const ExpandIcon = (): JSX.Element => {
            return (
                <IconButton onClick={() => setExpanded(!expanded)}>
                    <ExpandMoreIcon sx={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                </IconButton >
            )
        }

        return (
            <Card className='bd' sx={{ mb: 2 }}>
                <RenderCardHeader activity={activity} expandIcon={ExpandIcon} />
                <Collapse in={expanded} timeout='auto' unmountOnExit>
                    <Divider />
                    <CardContent>
                        {type === Type.REQ ? auth.user().user.uuid === activity.targetResourceId ?
                            <Typography color='action.active' mb={1}>Ação realizada em você</Typography>
                            :
                            <Link to={`/administrators/${activity.targetResourceId}`}><Button sx={{ mb: 1 }} startIcon={<Person />}>Ver usuário afetado</Button></Link>
                            : null
                        }
                        {type === Type.TAR ? auth.user().user.uuid === activity.requesterUuid ?
                            <Typography color='action.active' mb={1}>Ação realizada por você</Typography>
                            :
                            <Link to={`/administrators/${activity.requesterUuid}`}><Button sx={{ mb: 1 }} startIcon={<Person />}>Ver usuário responsável</Button></Link>
                            : null
                        }
                        <Typography><strong>ID da atividade:</strong> {activity.id}</Typography>
                        <RenderExtraContent activity={activity} />
                    </CardContent>
                </Collapse>
            </Card>
        );
    };

    const RenderCardHeader = (props: React.ComponentProps<any>): JSX.Element => {
        //Current activity
        const { activity, expandIcon } = props;

        //Styles
        const icon_card = { fontSize: 30, px: { xs: 0, sm: 1 } }

        function cardHeaderProps(date: any): Object {
            return {
                titleTypographyProps: { fontSize: { xs: 16, sm: 18 } },
                subheader: `Realizado em: ${normalize(date)}`,
                subheaderTypographyProps: { mt: 1, fontSize: { xs: 14, sm: 16 } }
            }
        }

        switch (activity.action as Actions) {
            case Actions.BLOCK_ADMINISTRATOR_ACCOUNT:
                return (
                    <CardHeader
                        avatar={<Lock color='error' sx={icon_card} />}
                        action={expandIcon()}
                        title='Bloqueio de conta'
                        {...cardHeaderProps(activity.creationDate)}
                    />
                )
            case Actions.REGISTER_ADMINISTRATOR_ACCOUNT:
                return (
                    <CardHeader
                        avatar={<AddModerator color='action' sx={icon_card} />}
                        action={expandIcon()}
                        title='Cadastro de conta'
                        {...cardHeaderProps(activity.creationDate)}
                    />
                )
            case Actions.UNBLOCK_ADMINISTRATOR_ACCOUNT:
                return (
                    <CardHeader
                        avatar={<LockOpen color='success' sx={icon_card} />}
                        action={expandIcon()}
                        title='Desbloqueio de conta'
                        {...cardHeaderProps(activity.creationDate)}
                    />
                )
            case Actions.UPDATE_ADMINISTRATOR:
                return (
                    <CardHeader
                        avatar={<ManageAccounts color='action' sx={icon_card} />}
                        action={expandIcon()}
                        title='Alteração de dados da conta'
                        {...cardHeaderProps(activity.creationDate)}
                    />
                )
            case Actions.UPDATE_ADMINISTRATOR_EMAIL:
                return (
                    <CardHeader
                        avatar={<AlternateEmail color='action' sx={icon_card} />}
                        action={expandIcon()}
                        title='Alteração de email da conta'
                        {...cardHeaderProps(activity.creationDate)}
                    />
                )
            case Actions.UPDATE_CUSTOMER_ACCOUNTS_QUOTA:
                return (
                    <CardHeader
                        avatar={<BarChart color='action' sx={icon_card} />}
                        action={expandIcon()}
                        title='Alteração de licença de contas'
                        {...cardHeaderProps(activity.creationDate)}
                    />
                )
            default: return <></>;
        }
    }

    const RenderExtraContent = (props: React.ComponentProps<any>): JSX.Element => {
        //Extract type action from the element
        const { activity } = props;
        const ipGeoLocationData = activity.ipGeoLocationData;

        if (ipGeoLocationData) {
            return (
                <Stack direction={{ xs: 'column', sm: 'row' }} mt={1} spacing={1}>
                    <Stack spacing={1} mr={2}>
                        <Typography><strong>IP:</strong> {ipGeoLocationData.ip}</Typography>
                        <Typography><strong>Hostname:</strong> {ipGeoLocationData.hostname || `Sem dados`}</Typography>

                    </Stack>
                    <Stack spacing={1} >
                        <Typography><strong>Cidade:</strong> {ipGeoLocationData.city || `Sem dados`}</Typography>
                        <Typography><strong>Região:</strong> {ipGeoLocationData.region || `Sem dados`}</Typography>
                    </Stack>
                    <Stack pl={{ sx: 0, sm: 2 }} >
                        <Typography><strong>Localização:</strong> {ipGeoLocationData.location || `Sem dados`}</Typography>
                    </Stack>
                </Stack >
            );
        }
        return <></>
    }

    const handleSelectChange = (event: SelectChangeEvent) => {
        setAction(event.target.value as Actions)
        resetState();
    };

    //Reset state values to make a new request
    const resetState = () => {
        setReqPage(1);
        setReqActivities([]);
        setFetchMoreReqContent(true);
        setTarPage(1);
        setTarActivities([]);
        setFetchMoreTarContent(true);
    };

    return (
        <Box width={.85} mx='auto' >
            <IconButton onClick={() => navigate(-1)} color='primary' sx={{ m: 2, ml: 5 }}> <KeyboardBackspace /></IconButton>
            <Divider />
            <Box sx={{ width: { sx: 1, sm: 1, md: .9, lg: .8 }, maxWidth: 1000, margin: 'auto', mt: 3 }}>
                <Grid container mb={5}>
                    <Grid item xs={4} sm={2} md={2} lg={2} textAlign='center'>
                        <ManageHistory color='action' sx={{ fontSize: 60 }} />
                    </Grid>
                    <Grid item xs alignSelf='center'>
                        <Typography variant='h5'>Atividades recentes</Typography>
                        <Typography variant='body2' mt={1}>Aqui você consultar as atividades recentes que este usuário realizou e as que foram feitas em sua conta.</Typography>
                    </Grid>
                </Grid>
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', width: 1 }}>
                        <TabList onChange={handleTabChange}>
                            <Tab label="Feitas por este usuário" value='0' />
                            <Tab label="Feitas na conta deste usuário" value='1' />
                        </TabList>
                    </Box>
                    <TabPanel value='0'>
                        <Typography>Histórico de atividades realizadas pelo administrador</Typography>
                        <FormControl variant="standard" fullWidth sx={{ mt: 2, mb: 4 }}>
                            <InputLabel id="action">Filtre por ação</InputLabel>
                            <Select
                                value={action}
                                onChange={handleSelectChange}
                                labelId="action"
                            >
                                <MenuItem value={''}>Sem filtro</MenuItem>
                                {
                                    AdministratorActivities.map(aa => (<MenuItem key={aa.value} value={aa.value}>{aa.label}</MenuItem>))
                                }
                            </Select>
                        </FormControl>
                        {
                            reqActivities.map(activity => <RenderActivities activity={activity} key={activity.id} type={Type.REQ} />)
                        }
                        {
                            !fetchMoreReqContent ?
                                <Alert variant='outlined'>Não há mais dados a serem exibidos.</Alert>
                                :
                                <LoadingButton loading={loading} fullWidth onClick={fetchRequesterActivities}>Buscar mais atividades</LoadingButton>
                        }
                    </TabPanel>
                    <TabPanel value='1'>
                        <Typography>Histórico de atividades realizadas na conta do administrador</Typography>
                        <FormControl variant="standard" fullWidth sx={{ mt: 2, mb: 4 }}>
                            <InputLabel id="action">Filtre por ação</InputLabel>
                            <Select
                                value={action}
                                onChange={handleSelectChange}
                                labelId="action"
                            >
                                <MenuItem value={''}>Sem filtro</MenuItem>
                                {
                                    AdministratorActivities.map(aa => (<MenuItem key={aa.value} value={aa.value}>{aa.label}</MenuItem>))
                                }
                            </Select>
                        </FormControl>
                        {
                            tarActivities.map(activity => <RenderActivities activity={activity} key={activity.id} type={Type.TAR} />)
                        }
                        {
                            !fetchMoreTarContent ?
                                <Alert variant='outlined'>Não há mais dados a serem exibidos.</Alert>
                                :
                                <LoadingButton fullWidth loading={loading} onClick={fetchTargetActivities}>Buscar mais atividades</LoadingButton>
                        }
                    </TabPanel>
                </TabContext>
            </Box>
            <AppNotification />
        </Box>
    );
}