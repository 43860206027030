import { useState, useEffect, useContext } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

import { LoadingButton } from "../../../utils/Components";

import RenewAccessDialog from '../../account-management/RenewAccessDialog'

import StlInterceptor from '../../../contexts/stl'
import { SecurityTierLevels } from '../../../store/stl'

import AdministratorService from "../../../services/administrator";
import ErrorWrapper from "../../../utils/ErrorWrapper";

import { useAppDispatch } from "../../../redux/hooks";
import { show } from "../../../redux/features/app-global-notification/app-global-notification-slice"
import { AppNotification } from "../../../utils/AppNotification"

/**
 * Dialog to update user email UI
 * @returns {JSX.Element}
 */
export default function UpdateEmailDialog(props: React.ComponentProps<any>) {
    const notification = useAppDispatch();

    //Props
    const { auth, uuid, email, open, close, onUpdate } = props;

    //Context props
    const stl = useContext(StlInterceptor);

    //State props
    const [loading, setLoading] = useState(false);
    const [newEmail, setNewEmail] = useState('')

    //Renew access dialog 
    const [renewSessionDialog, setRenewSessionDialog] = useState(false);

    //Effect that set user role checked 
    useEffect(() => {setNewEmail(email)}, [open]);

    //Event props
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setNewEmail(event.target.value);

    const checkSTLlevel = () => {
        setLoading(true);
        stl.require(auth, SecurityTierLevels.MaxLevel)
            .then(() => updateEmail())
            .catch((e) => setRenewSessionDialog(true))
    }

    const onSessionRenewed = () => {
        setRenewSessionDialog(false);
        updateEmail()
    }

    const updateEmail = () => {
        AdministratorService.updateEmail(uuid, { newEmail })
            .then(() => onUpdate(newEmail))
            .catch(e => notification(show({ type: 'error', message: new ErrorWrapper(e).message })))
            .finally(() => setLoading(false))
    }

    return (
        <Dialog onClose={close} open={open}>
            <DialogTitle sx={{ pb: 0 }}>Alteração de email</DialogTitle>
            <DialogContent sx={{ width: 350 }}>
                <TextField sx={{ mt: 3 }} onChange={handleChange} fullWidth value={newEmail} label='Insira o novo email' variant='standard' />
            </DialogContent>
            <DialogActions sx={{ pr: 3, pb: 2 }}>
                <Button onClick={close} color='error'>Cancelar</Button>
                <LoadingButton disabled={!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail)) || email === newEmail}
                    loading={loading} variant='contained' onClick={checkSTLlevel} id='bc'> Salvar </LoadingButton>
            </DialogActions>
            <AppNotification />
            <RenewAccessDialog sessionRenewed={() => onSessionRenewed()} open={renewSessionDialog} />
        </Dialog >
    );
}