import { useState, useEffect } from "react"
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Tooltip, Typography } from "@mui/material";

import { AdminRoles, CustomerRoles } from "../../../static/roles-info"
import { Roles } from "../../../contexts/roles"
import { LoadingButton } from "../../../utils/Components";

import AdministratorService from "../../../services/administrator";
import ErrorWrapper from "../../../utils/ErrorWrapper";

import { useAppDispatch } from '../../../redux/hooks';
import { show } from "../../../redux/features/app-global-notification/app-global-notification-slice"
import { AppNotification } from "../../../utils/AppNotification"

/**
 * Dialog with roles informations UI
 * @returns {JSX.Element}
 */
export default function UpdateRolesDialog(props: React.ComponentProps<any>) {
    const notification = useAppDispatch();

    //Props
    const { user, uuid, isAuthUser, open, close, update } = props;
    
    //State props
    const [loading, setLoading] = useState(false);

    //State roles check prop, all starts as false
    const [roles, setRoles] = useState({
        AM: false, AM_SM: false, CM: false, CM_SM: false, CM_QM: false, CM_PPM: false
    });
    const { AM, AM_SM, CM, CM_SM, CM_QM, CM_PPM } = roles;

    //Effect that set user role checked 
    useEffect(() => {
        setLoading(false)
        setRoles({
            AM: user.roles.includes('AM' as Roles),
            AM_SM: user.roles.includes('AM_SM' as Roles),
            CM: user.roles.includes('CM' as Roles),
            CM_SM: user.roles.includes('CM_SM' as Roles),
            CM_QM: user.roles.includes('CM_QM' as Roles),
            CM_PPM: user.roles.includes('CM_PPM' as Roles)
        })
    }, [close, user]);

    const RenderRoles = (): JSX.Element => {
        const handleChange = (event: any) => {
            setRoles({ ...roles, [event.target.value]: event.target.checked });
        };

        const isChecked = (role: string) => {
            switch (role) {
                case 'CM': return CM
                case 'CM_SM': return CM_SM
                case 'CM_QM': return CM_QM
                case 'CM_PPM': return CM_PPM
                default: return false;
            }
        }

        return (
            <Box>
                <Box className='bd' mt={2} p={2}>
                    <Typography fontWeight={600}>Gerenciamento de administradores</Typography>
                    <FormGroup>
                        {AdminRoles.map(aRole =>
                        (
                            isAuthUser ?
                                <Tooltip title='Essa permissão não pode ser alterada' key={aRole.value} placement='top'>
                                    <FormControlLabel label={aRole.name} control={
                                        <Checkbox value={aRole.value} size='small' checked disabled />
                                    } />
                                </Tooltip>
                                :
                                <Tooltip title={aRole.description} key={aRole.value} placement='top'>
                                    <FormControlLabel label={aRole.name} control={
                                        <Checkbox
                                            value={aRole.value} size='small'
                                            checked={aRole.value === Roles.AM ? AM : AM_SM}
                                            onChange={handleChange}
                                        />
                                    } />
                                </Tooltip>
                        )
                        )}
                    </FormGroup>
                </Box>
                <Box className='bd' mt={2} p={2} >
                    <Typography fontWeight={600}>Gerenciamento de clintes</Typography>
                    <FormGroup>
                        {CustomerRoles.map(cRole =>
                            <Tooltip title={cRole.description} key={cRole.value} placement='top'>
                                <FormControlLabel label={cRole.name} control={
                                    <Checkbox
                                        value={cRole.value} size='small'
                                        checked={isChecked(cRole.value)}
                                        onChange={handleChange}
                                    />
                                } />
                            </Tooltip>
                        )}
                    </FormGroup>
                </Box>
            </Box>
        )
    };


    const updateRoles = () => {
        setLoading(true);

        //Create a array of roles that contains a true value
        const newRoles: Roles[] = []
        Object.entries(roles).forEach(([role, contains]) => { if (contains) newRoles.push(role as Roles) })

        //Update
        AdministratorService.update({ ...user, roles: newRoles }, uuid)
            .then(() => update(newRoles))
            .catch(e => notification(show({ type: 'error', message: new ErrorWrapper(e).message })))
            .finally(() => setLoading(false)) 
    }

    return (
        <Dialog onClose={close} open={open}>
            <DialogTitle sx={{ pb: 0 }}>Gerenciamento de permissões</DialogTitle>
            <DialogContent>
                <DialogContentText> Selecione as permissões que gostaria de alterar </DialogContentText>
                <RenderRoles />
            </DialogContent>
            <DialogActions sx={{ pr: 3, pb: 2 }}>
                <Button onClick={close} color='error'>Cancelar</Button>
                <LoadingButton loading={loading} variant='contained' onClick={updateRoles} id='bc'> Salvar </LoadingButton>
            </DialogActions>
            <AppNotification/>
        </Dialog >
    );
}