export interface PreferredRegion {
    name: string;
    value: string;
}

export const PreferredRegions: PreferredRegion[] = [
    {
        name: 'Brasil',
        value: 'BRA'
    }
]