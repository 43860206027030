import { AccountAccessActivity } from "../models/account-access-activity";
import { AuthenticatedUser, STLRoles } from "../models/user";
import AccountsErpAPI from "./accountsErpApi";

/**
 * Main abstraction used for  authentication methods
 */
interface UserAuthentication {
    trustDevice: boolean
}

interface AuthenticateWithPassword extends UserAuthentication {
    password: string
}

/**
 * Interface used to authenticate user with email and password
 */
interface AuthenticateWithEmailAndPassword extends UserAuthentication {
    email: string,
    password: string
}

interface Session {
    token: string,
    expirationDate: Date
}

interface RenewPasswordDTO {
    email: string,
    password: string,
    newPassword: string
}

interface ActivateAccountDTO {
    password: string,
    confirmEmailToken: string,
    resetPasswordToken: string
}

interface UpdateEmailDTO {
    newEmail: string
}

const AuthenticationService = {
    /**
     * Authenticate the user using its combination of email and password. Return the authentication token
     * @param auth - Object used to authenticated the user
     * @returns {Promise<String>} Promise containing the user authentication token
     */
    authenticateWithEmailAndPassword: async function (auth: AuthenticateWithEmailAndPassword): Promise<Session> {

        const flags = (auth.trustDevice) ? "?trustDevice=true" : '';

        //Try to authenticate the user
        const authResponse = await AccountsErpAPI.post(`/rest/v1/auth/public/tokens/administrator/create-with-email-and-password${flags}`, {
            'email': auth.email,
            'password': auth.password
        });

        //Return the data
        return {
            token: authResponse.data.token,
            expirationDate: authResponse.data.expirationDate
        }
    },

    /**
     * Return data from the current authenticated user
     * @returns {Promise<AuthenticatedUser>}
     */
    fetchAuthenticatedUserData: async function (): Promise<AuthenticatedUser> {
        //Try to authenticate the user
        const response = await AccountsErpAPI.get(`/rest/v1/auth/me`);

        //Set STL session roles 
        const stlRoles: STLRoles[] = [], session = response.data.session;
        if (session.roles) session.roles.forEach((roleFromService: any) => {
            stlRoles.push(STLRoles[roleFromService.authority as keyof typeof STLRoles]);
        })

        //Return the model data
        return {
            user: response.data.user,
            account: response.data.account,
            session: { ...session, roles: stlRoles }
        }
    },

    /**
     * Change the auth user password
     * @param newPassword 
     */
    changePassword: async function (newPassword: string) {
        return await (await (AccountsErpAPI.put(`/rest/v1/auth/me/change-password`, { newPassword }))).data;
    },

    /**
     * Renew the authentication token using the password as credential
     * @param auth 
     * @returns 
     */
    renewTokenWithPassword: async function (auth: AuthenticateWithPassword): Promise<Session> {
        const flags = (auth.trustDevice) ? "?trustDevice=true" : '';

        //Try to authenticate the user
        const authResponseBody = await (await (AccountsErpAPI.post(`/rest/v1/auth/tokens/renew-with-password${flags}`, { 'password': auth.password }))).data;

        //Return the data
        return {
            token: authResponseBody.token,
            expirationDate: authResponseBody.expirationDate
        }
    },

    /**
     * Send account confirmation mail
     * @param email 
     */
    sendAccountConfirmationMail: async function (email: string) {
        await AccountsErpAPI.post(`/rest/v1/auth/public/send-email-confirmation?email=${email}`);
    },

    /**
     * Confirm the account stored on the token
     * @param token 
     */
    confirmEmailWithToken: async function (token: string): Promise<void> {
        await AccountsErpAPI.post(`/rest/v1/auth/public/confirm-email-with-token?token=${token}`);
    },

    /**
     * Renew an user password. The user must authenticate with its email and password and input the new password
     * @param dto 
     */
    renewPassword: async function (dto: RenewPasswordDTO): Promise<any> {
        return AccountsErpAPI.put(`/rest/v1/auth/public/renew-password`, dto);
    },

    /**
     * Send an email to start password reset operation
     * @param email - The email to reset
     */
    sendPasswordResetMail: async function (email: string) {
        await AccountsErpAPI.post(`/rest/v1/auth/public/send-password-reset-email?email=${email}`);
    },

    /**
     * Reset the user password using email token
     * @param newPassword 
     * @param verificationToken 
     */
    resetPasswordByEmailToken: async function (newPassword: string, verificationToken: string): Promise<any> {
        return AccountsErpAPI.put(`/rest/v1/auth/public/reset-password-with-email-token`, { newPassword: newPassword, token: verificationToken });
    },

    /**
     * Activate administrator account (first access)
     * @param dto  
     */
    activateAccount: async function (dto: ActivateAccountDTO): Promise<any> {
        return AccountsErpAPI.put(`/rest/v1/auth/public/activate-account`, dto);
    },

    sendEmailChangeMail: async function (dto: UpdateEmailDTO): Promise<void> {
        return AccountsErpAPI.post(`/rest/v1/auth/me/send-email-change-mail`, dto);
    },

    updateEmailWithToken: async function (token: string): Promise<void> {
        return AccountsErpAPI.put(`/rest/v1/auth/me/update-email-with-token?token=${token}`);
    },

    fetchAccountAccessActivities: async function (page: Number, action: string): Promise<AccountAccessActivity[]> {
        //Query the action
        const query = action.length > 0 ? `&action=${action}` : ``

        //Send request
        const resp = await AccountsErpAPI.get(`/rest/v1/auth/me/account-access-activities?page=${page}&limit=5${query}`);

        //Return the fetched data
        if (resp.status === 200) return resp.data as AccountAccessActivity[];

        //Return null is there is no content
        if (resp.status === 204) return null!;

        //Throw error
        throw new Error("Unexpected server response status: " + resp.status);
    }

}

export default AuthenticationService;