import { ContractsQuota } from "../models/contracts-quota";

interface LabedContractsQuota extends ContractsQuota {
    label : string;
}

export enum Plans {
    NO_QUOTA = "NO_QUOTA",
    PAY_AS_YOU_GO = "PAY_AS_YOU_GO",
    BASIC = "BASIC",
    SMALL_BUSINESS = "SMALL_BUSINESS",
    BUSINESS = "BUSINESS"
}

interface LabedContractsQuota extends ContractsQuota {
    label : string;
}

export const PlansQuota: LabedContractsQuota[] = [
    {
        label : "*Sem Plano*",
        maxTemplateCount: 0,
        maxSubusers: 0,
        contractsIssuedPerMonth: 0,
        maxStorageBytes: 0,
        financialAnalyticsModuleEnabled: false,
        contractsFieldsAutomationEnabled: false,
        contractAttachmentsEnabled: false,
        plan: Plans.NO_QUOTA,
        interfaceThemesCustomizationEnabled: false
    },
    {
        label : "Pós pago",
        maxTemplateCount: 1000,
        maxSubusers: 0,
        contractsIssuedPerMonth: 0,
        maxStorageBytes: 1073741824,
        financialAnalyticsModuleEnabled: true,
        contractsFieldsAutomationEnabled: true,
        contractAttachmentsEnabled: true,
        plan: Plans.PAY_AS_YOU_GO,
        interfaceThemesCustomizationEnabled: false
    },
    {
        label : "Básico",
        maxTemplateCount: 1000,
        maxSubusers: 2,
        contractsIssuedPerMonth: 10,
        maxStorageBytes: 1073741824,
        financialAnalyticsModuleEnabled: false,
        contractsFieldsAutomationEnabled: false,
        contractAttachmentsEnabled: false,
        plan: Plans.BASIC,
        interfaceThemesCustomizationEnabled: false
    },
    {
        label : "Pequeno negócio",
        maxTemplateCount: 1000,
        maxSubusers: 4,
        contractsIssuedPerMonth: 20,
        maxStorageBytes: 5368709120,
        financialAnalyticsModuleEnabled: true,
        contractsFieldsAutomationEnabled: true,
        contractAttachmentsEnabled: false,
        plan: Plans.SMALL_BUSINESS,
        interfaceThemesCustomizationEnabled: false
    },
    {
        label : "Empresarial",
        maxTemplateCount: 1000,
        maxSubusers: 10,
        contractsIssuedPerMonth: null,
        maxStorageBytes: 53687091200,
        financialAnalyticsModuleEnabled: true,
        contractsFieldsAutomationEnabled: true,
        contractAttachmentsEnabled: true,
        plan: Plans.BUSINESS,
        interfaceThemesCustomizationEnabled: true
    }
]