import { createContext } from "react";
import { AuthenticationContextData } from "../models/user";

export enum Roles {
    AM = 'AM',
    AM_SM = 'AM_SM',
    CM = 'CM',
    CM_QM = 'CM_QM',
    CM_SM = 'CM_SM',
    CM_PPM = 'CM_PPM'
}

//The context interface
export interface RolesContextInterface {
    hasRole(authContext: AuthenticationContextData, role: Roles): boolean
}

//The react context
export const RolesContext = createContext<RolesContextInterface>({
    hasRole: (authContext: AuthenticationContextData, role: Roles): boolean => {
        //Check if user is authenticated
        if (authContext.isAuthenticated() === false) return false;

        //Check if user has the role (if index value > 0)
        if (authContext.user().account.roles.indexOf(Roles[role]) >= 0) return true;
        
        //Return false if it doesnt have the role
        return false;
    }
});