import { ContractsQuota } from "../models/contracts-quota";
import AccountsErpAPI from "./accountsErpApi";
import ContractsApi from "./contracts-api";

const ContractsQuotaService = {
    find: async (uuid: string) => {
        //Send request
        return await (await AccountsErpAPI.get(`/rest/v1/customers/${uuid}/quotas/contracts`)).data;
    },

    update: async (uuid: string, dto: ContractsQuota) => {
        //Send request
        return await (await AccountsErpAPI.put(`/rest/v1/customers/${uuid}/quotas/contracts`, dto)).data;
    },

    findOrganizationQuotasConsumption: async (uuid: string, startDate?: number, endDate?: number) => {
        let queryString = '';
    
        if (startDate !== undefined && endDate !== undefined) {
            queryString = `?startDate=${startDate}&endDate=${endDate}`;
        }
    
        const response = await ContractsApi.get(`/ktadmin/v1/organizations/${uuid}/quotas-consumption${queryString}`);
        return response.data;
    },
}

export default ContractsQuotaService;