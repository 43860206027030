import { AccountAccessActivity } from "../models/account-access-activity";
import AccountsErpAPI from "./accountsErpApi";

const UserAccountService = {
    find: async (email: string) => {
        //Send request
        return await (await AccountsErpAPI.get(`/rest/v1/user-accounts/organization-members?email=${email}`)).data;
    },

    fetchActivities : async (uuid : string, page : Number, action : string) => {
         //Query the action
         const query = action.length > 0 ? `&action=${action}` : ``

         //Send request
         const resp = await AccountsErpAPI.get(`/rest/v1/user-accounts/${uuid}/account-access-activities?page=${page}&limit=5${query}`);
 
         //Return the fetched data
         if (resp.status === 200) return resp.data as AccountAccessActivity[];
 
         //Return null is there is no content
         if (resp.status === 204) return null!;
 
         //Throw error
         throw new Error("Unexpected server response status: " + resp.status);
    },

    block: async (reason: string, uuid: string) => {
        //Send request
        return await AccountsErpAPI.post(`/rest/v1/user-accounts/${uuid}/block`, { reason });
    },

    unblock: async (reason: string, uuid: string) => {
        //Send request
        return await AccountsErpAPI.post(`/rest/v1/user-accounts/${uuid}/unblock`, { reason });
    },
}

export default UserAccountService;