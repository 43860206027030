export type Role = {
    name: string;
    value: string; 
    description: string;
}

export const AdminRoles: Role[] = [
    {
        name: 'Gerente de administradores',
        value: 'AM',
        description: 'Permite que gerencie administradores'
    },
    {
        name: 'Gerente de segurança de administradores',
        value: 'AM_SM',
        description: 'Permite que gerencie a segurança de administradores'
    },
]

export const CustomerRoles: Role[] = [
    {
        name: 'Gerente de clientes',
        value: 'CM',
        description: 'Permite que gerencie clientes pertencentes à organização'
    },
    {
        name: 'Gerente de licenças de uso de software de clientes',
        value: 'CM_QM',
        description: 'Permite que gerencie licenças de software de clientes pertencentes à organização'
    },
    {
        name: 'Gerente finaceiro de planos de clientes',
        value: 'CM_PPM',
        description: 'Permite que gerencie o preço de planos pagos pelos clientes pertencentes à organização'
    },
    {
        name: 'Gerente de segurança de cliente',
        value: 'CM_SM',
        description: 'Permite que gerencie a segurança de clientes pertencentes à organização'
    }
]