import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LoadingButton, Logo, LogoBar } from "../../utils/Components";
import AuthenticationService from "../../services/authentication";
import ErrorWrapper from "../../utils/ErrorWrapper"

import { useAppDispatch } from '../../redux/hooks';
import { show } from "../../redux/features/app-global-notification/app-global-notification-slice"
import { AppNotification } from "../../utils/AppNotification"

function RecoverMyPassword(): JSX.Element {
    const notification = useAppDispatch();

    //Store the recover password email state
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(false);

    //Event changes
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

    //Functions
    const sendPasswordRecoveryMail = () => {
        //Update loading state
        setLoading(true)

        AuthenticationService.sendPasswordResetMail(email)
            .then(() => {
                setEmailSent(true)
                notification(show({ type: 'success', message: `Email enviado` }))
            })
            .catch(e => notification(show({ type: 'error', message: new ErrorWrapper(e).message })))
            .finally(() => setLoading(false))
    }

    return (
        <Container id='formContainer' sx={{ bgcolor: 'primary.dark' }}>
            <AppNotification />
            <Logo mode={'primary.light'} />
            <LogoBar size={400} />
            <Box id='box'>
                {!emailSent ?
                    <Box>
                        <Typography variant='h5'>Recuperação de acesso à conta</Typography>
                        <Typography variant='body2' mt={3}> Esqueceu sua senha? Informe seu e-mail abaixo para receber um link para redefinir sua senha.</Typography>
                        <TextField fullWidth sx={{ mt: 3, mb: 6 }} value={email} type='email'
                            variant='standard' label='Email' onChange={handleEmailChange}
                        />
                        <LoadingButton fullWidth disabled={!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))} variant="contained" id='bc'
                            onClick={sendPasswordRecoveryMail} loading={loading}>Enviar email</LoadingButton>
                    </Box>
                    :
                    <Box textAlign='center'>
                        <Typography variant='h5' className='ba' mb={2}><CheckCircle color='success' sx={{ mr: 1 }} />Email enviado!</Typography>
                        <Typography variant='body1' mt={3}>
                            Acesse a caixa de entrada do email <b>{email}</b> e siga as instruções para recuperar sua conta.
                        </Typography>
                        <Typography variant='caption' color='text.secondary' mt={1}>Caso não encontre o email verifique seu SPAM.</Typography>
                    </Box>
                }
                <Link to='/' style={{ width: 'fit-content' }}>
                    <Button sx={{ textTransform: 'none', mt: 1 }}>Voltar</Button>
                </Link>
            </Box>
        </Container>
    );
};

export default RecoverMyPassword;