import { useState, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
    Box, IconButton, Divider, Typography, Grid, Tab, Alert, Card, CardHeader, CardContent, Collapse, Stack, MenuItem,
    FormControl, InputLabel, Select, SelectChangeEvent, Button
} from "@mui/material";
import {
    KeyboardBackspace, ManageHistory, Lock, AddModerator, LockOpen, ManageAccounts, BarChart, MarkEmailUnread, Login,
    LockReset, LockClock, SyncLock, AlternateEmail, ExpandMore as ExpandMoreIcon, Person
} from "@mui/icons-material";

import { LoadingButton } from "../utils/Components";
import ErrorWrapper from '../utils/ErrorWrapper';
import AdministratorService from '../services/administrator';
import AuthenticationService from '../services/authentication';
import AuthenticationContext from '../contexts/authentication';
import { RolesContext, Roles } from "../contexts/roles";
import { AccountAccessActivity } from '../models/account-access-activity';
import { Actions as AccountActions, AccountAccessActivities } from '../static/account-access-activities';

import { AdministratorActivity } from '../models/administrator-activity';
import { Actions as AdminActions, AdministratorActivities } from "../static/administrator-activities"

import { useAppDispatch } from '../redux/hooks';
import { show } from "../redux/features/app-global-notification/app-global-notification-slice"
import { AppNotification } from "../utils/AppNotification"

//Action type enum
enum ActionType {
    REQUESTER = 'requester',
    TARGET = 'target',
    ACCOUNT = 'account'
}

/**
 * Recent activities of administrator UI
 * @returns {JSX.Element}
 */
export default function MyRecentActivities() {



    //Context props
    const useAuthentication = () => { return useContext(AuthenticationContext) }
    const auth = useAuthentication();
    const notification = useAppDispatch();
    const navigate = useNavigate();
    const userRoles = useContext(RolesContext);

    const [loading, setLoading] = useState(false)

    //Tab state
    const [tab, setTab] = useState('0');
    const handleTabChange = (e: any, value: string) => { setTab(value) };

    //Account access activities state
    const [accountActivities, setAccountActivities] = useState<AccountAccessActivity[]>([]);
    const [fetchMoreAccountContent, setFetchMoreAccountContent] = useState(true);
    const [accountPage, setAccountPage] = useState(1);
    const [accountAction, setAccountAction] = useState<AccountActions>('' as AccountActions);

    const handleAccountSelectChange = (event: SelectChangeEvent) => {
        setAccountAction(event.target.value as AccountActions)
        resetState();
    };

    const fetchAccountAccessActivities = async () => {
        setLoading(true);

        await AuthenticationService.fetchAccountAccessActivities(accountPage, accountAction)
            .then(resp => {
                if (!resp) setFetchMoreAccountContent(false)
                else {
                    resp.forEach(r => accountActivities.push(r));
                    setAccountPage(accountPage + 1);
                }
            })
            .catch(e => notification(show({
                type: 'error',
                message: `${new ErrorWrapper(e).message}`
            })))
            .finally(() => setLoading(false))
    }

    //Administrator activities by requester state
    const [requesterActivities, setRequesterActivities] = useState<AdministratorActivity[]>([]);
    const [fetchMoreRequesterContent, setFetchMoreRequesterContent] = useState(true);
    const [requesterPage, setRequesterPage] = useState(1);
    const [adminAction, setAdminAction] = useState<AdminActions>('' as AdminActions);

    const handleAdminSelectChange = (event: SelectChangeEvent) => {
        setAdminAction(event.target.value as AdminActions)
        resetAdminState();
    };

    const fetchAdministratorActivitiesByRequester = async () => {
        setLoading(true);

        AdministratorService.fetchAdministratorActivities('requester', auth.user().user.uuid, requesterPage, adminAction)
            .then(resp => {
                if (!resp) setFetchMoreRequesterContent(false)
                else {
                    resp.forEach(r => requesterActivities.push(r));
                    setRequesterPage(requesterPage + 1);
                }
            })
            .catch(e => notification(show({
                type: 'error',
                message: `${new ErrorWrapper(e).message}`
            })))
            .finally(() => setLoading(false))
    }

    //Administrator activities by target state
    const [targetActivities, setTargetActivities] = useState<AdministratorActivity[]>([]);
    const [fetchMoreTargetContent, setFetchMoreTargetContent] = useState(true);
    const [targetPage, setTargetPage] = useState(1);

    const fetchAdministratorActivitiesByTarget = async () => {
        setLoading(true);

        AdministratorService.fetchAdministratorActivities('target', auth.user().user.uuid, targetPage, adminAction)
            .then(resp => {
                if (!resp) setFetchMoreTargetContent(false)
                else {
                    resp.forEach(r => targetActivities.push(r));
                    setTargetPage(targetPage + 1);
                }
            })
            .catch(e => notification(show({
                type: 'error',
                message: `${new ErrorWrapper(e).message}`
            })))
            .finally(() => setLoading(false))
    }

    //Load activities every time the selected action changes
    useEffect(() => { fetchAccountAccessActivities() }, [accountAction]);
    useEffect(() => {
        if (userRoles.hasRole(auth, Roles.AM_SM)) {
            fetchAdministratorActivitiesByRequester();
            fetchAdministratorActivitiesByTarget()
        }
    }, [adminAction]);

    //Reset state values to make a new request
    const resetState = () => {
        setAccountPage(1);
        setAccountActivities([]);
        setFetchMoreAccountContent(true);
    };
    const resetAdminState = () => {
        setTargetPage(1);
        setTargetActivities([]);
        setFetchMoreTargetContent(true);
        setRequesterPage(1);
        setRequesterActivities([]);
        setFetchMoreRequesterContent(true);
    }

    const normalize = (milliseconds: string | number | Date) => {
        return new Date(milliseconds).toLocaleTimeString([],
            {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            })
    };


    const RenderActivities = (props: React.ComponentProps<any>): JSX.Element => {
        //Current activity
        const { activity, actionType } = props;
        const [expanded, setExpanded] = useState(false);

        const ExpandIcon = (): JSX.Element => {
            return (
                <IconButton onClick={() => setExpanded(!expanded)}>
                    <ExpandMoreIcon sx={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                </IconButton >
            )
        }

        /**
         * Render the activity participants
         */
        const RenderActivitiesParties = (): JSX.Element => {
            //Check if s has the same requester and target
            const byMe = activity.requesterUuid === activity.targetResourceId;

            //Return if the activities was made by the auth user or link it to the other user info page
            if (actionType === ActionType.REQUESTER)
                return byMe ?
                    <Typography color='action.active' mb={1}>Ação realizada em você</Typography>
                    :
                    <Link to={`/administrators/${activity.targetResourceId}`}><Button sx={{ mb: 1 }} startIcon={<Person />}>Ver usuário afetado</Button></Link>
            else
                return byMe ?
                    <Typography color='action.active' mb={1}>Ação realizada por você</Typography>
                    :
                    <Link to={`/administrators/${activity.requesterUuid}`}><Button sx={{ mb: 1 }} startIcon={<Person />}>Ver usuário responsável</Button></Link>
        }

        return (
            <Card className='bd' sx={{ mb: 2 }}>
                <RenderCardHeader activity={activity} expandIcon={ExpandIcon} actionType={actionType} />
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Divider />
                    <CardContent>
                        {actionType != ActionType.ACCOUNT ? <RenderActivitiesParties /> : null}
                        <Typography><strong>ID da atividade:</strong> {activity.id}</Typography>
                        <RenderExtraContent activity={activity} />
                    </CardContent>
                </Collapse>
            </Card>
        );
    };

    const RenderCardHeader = (props: React.ComponentProps<any>): JSX.Element => {
        //Current activity
        const { activity, expandIcon, actionType } = props;

        //Styles
        const icon_card = { fontSize: 30, px: { xs: 0, sm: 1 } }

        function cardHeaderProps(date: any): Object {
            return {
                titleTypographyProps: { fontSize: { xs: 16, sm: 18 } },
                subheader: `Realizado em: ${normalize(date)}`,
                subheaderTypographyProps: { mt: 1, fontSize: { xs: 14, sm: 16 } }
            }
        }

        if (actionType === ActionType.ACCOUNT) {
            switch (activity.action as AccountActions) {
                case AccountActions.EMAIL_CHANGED:
                    return (
                        <CardHeader
                            avatar={<AlternateEmail color='action' sx={icon_card} />}
                            action={expandIcon()}
                            title='Alteração de email'
                            {...cardHeaderProps(activity.creationDate)}
                        />
                    )
                case AccountActions.EMAIL_CHANGE_REQUEST:
                    return (
                        <CardHeader
                            avatar={<MarkEmailUnread color='action' sx={icon_card} />}
                            action={expandIcon()}
                            title='Solicitação de troca de email'
                            {...cardHeaderProps(activity.creationDate)}
                        />
                    )
                case AccountActions.LOGIN:
                    return (
                        <CardHeader
                            avatar={<Login color='action' sx={icon_card} />}
                            action={expandIcon()}
                            title='Acesso à conta'
                            {...cardHeaderProps(activity.creationDate)}
                        />
                    )
                case AccountActions.PASSWORD_CHANGED:
                    return (
                        <CardHeader
                            avatar={<LockReset color='action' sx={icon_card} />}
                            action={expandIcon()}
                            title='Troca de senha'
                            {...cardHeaderProps(activity.creationDate)}
                        />
                    )
                case AccountActions.PASSWORD_EXPIRATION:
                    return (
                        <CardHeader
                            avatar={<LockClock color='action' sx={icon_card} />}
                            action={expandIcon()}
                            title='Expiração de senha'
                            {...cardHeaderProps(activity.creationDate)}
                        />
                    )
                case AccountActions.SESSION_EXPIRATION:
                    return (
                        <CardHeader
                            avatar={<SyncLock color='action' sx={icon_card} />}
                            action={expandIcon()}
                            title='Expiração de sessões'
                            {...cardHeaderProps(activity.creationDate)}
                        />
                    )
                default: return <></>;
            }
        }
        else {
            switch (activity.action as AdminActions) {
                case AdminActions.BLOCK_ADMINISTRATOR_ACCOUNT:
                    return (
                        <CardHeader
                            avatar={<Lock color='error' sx={icon_card} />}
                            action={expandIcon()}
                            title='Bloqueio de conta'
                            {...cardHeaderProps(activity.creationDate)}
                        />
                    )
                case AdminActions.REGISTER_ADMINISTRATOR_ACCOUNT:
                    return (
                        <CardHeader
                            avatar={<AddModerator color='action' sx={icon_card} />}
                            action={expandIcon()}
                            title='Cadastro de conta'
                            {...cardHeaderProps(activity.creationDate)}
                        />
                    )
                case AdminActions.UNBLOCK_ADMINISTRATOR_ACCOUNT:
                    return (
                        <CardHeader
                            avatar={<LockOpen color='success' sx={icon_card} />}
                            action={expandIcon()}
                            title='Desbloqueio de conta'
                            {...cardHeaderProps(activity.creationDate)}
                        />
                    )
                case AdminActions.UPDATE_ADMINISTRATOR:
                    return (
                        <CardHeader
                            avatar={<ManageAccounts color='action' sx={icon_card} />}
                            action={expandIcon()}
                            title='Alteração de dados da conta'
                            {...cardHeaderProps(activity.creationDate)}
                        />
                    )
                case AdminActions.UPDATE_ADMINISTRATOR_EMAIL:
                    return (
                        <CardHeader
                            avatar={<AlternateEmail color='action' sx={icon_card} />}
                            action={expandIcon()}
                            title='Alteração de email da conta'
                            {...cardHeaderProps(activity.creationDate)}
                        />
                    )
                case AdminActions.UPDATE_CUSTOMER_ACCOUNTS_QUOTA:
                    return (
                        <CardHeader
                            avatar={<BarChart color='action' sx={icon_card} />}
                            action={expandIcon()}
                            title='Alteração de licença de contas'
                            {...cardHeaderProps(activity.creationDate)}
                        />
                    )
                default: return <></>;
            }
        }
    }

    const RenderExtraContent = (props: React.ComponentProps<any>): JSX.Element => {
        //Extract type action from the element
        const { activity } = props;
        const ipGeoLocationData = activity.ipGeoLocationData;

        if (ipGeoLocationData) {
            return (
                <Stack direction={{ xs: 'column', sm: 'row' }} mt={1} spacing={1}>
                    <Stack spacing={1} mr={2}>
                        <Typography><strong>IP:</strong> {ipGeoLocationData.ip}</Typography>
                        <Typography><strong>Hostname:</strong> {ipGeoLocationData.hostname || `Sem dados`}</Typography>

                    </Stack>
                    <Stack spacing={1} >
                        <Typography><strong>Cidade:</strong> {ipGeoLocationData.city || `Sem dados`}</Typography>
                        <Typography><strong>Região:</strong> {ipGeoLocationData.region || `Sem dados`}</Typography>
                    </Stack>
                    <Stack pl={{ sx: 0, sm: 2 }} >
                        <Typography><strong>Localização:</strong> {ipGeoLocationData.location || `Sem dados`}</Typography>
                    </Stack>
                </Stack >
            );
        }
        return <></>
    }

    return (
        <Box width={.85} mx='auto' >
            <Link to='/me'><IconButton sx={{ m: 2, ml: 5 }}> <KeyboardBackspace /></IconButton></Link>
            <Divider />
            <Box sx={{ width: .9, maxWidth: 1000, margin: 'auto', mt: 4 }}>
                <Grid container mb={5}>
                    <Grid item xs={4} sm={4} md={2} lg={2} textAlign='center'>
                        <ManageHistory color='action' sx={{ fontSize: 60 }} />
                    </Grid>
                    <Grid item xs alignSelf='center'>
                        <Typography variant='h5'>Minhas atividades recentes</Typography>
                        <Typography variant='body2' mt={1}>Aqui você consultar as atividades recentes realizadas em sua conta.</Typography>
                    </Grid>
                </Grid>
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', width: 1 }}>
                        {
                            !userRoles.hasRole(auth, Roles.AM_SM) ?
                                <TabList onChange={handleTabChange}>
                                    <Tab label="De acesso" value='0' />
                                </TabList>
                                :
                                <TabList onChange={handleTabChange}>
                                    <Tab label="De acesso" value='0' />
                                    <Tab label="Feitas por você" value='1' />
                                    <Tab label="Feitas em sua conta" value='2' />
                                </TabList>
                        }
                    </Box>
                    <TabPanel value='0'>
                        <Typography>Histórico das atividades recentes de conta</Typography>
                        <FormControl variant='standard' fullWidth sx={{ mt: 2, mb: 4 }}>
                            <InputLabel id='action'>Filtre por ação</InputLabel>
                            <Select
                                value={accountAction}
                                onChange={handleAccountSelectChange}
                                labelId='action'
                            >
                                <MenuItem value={''}>Sem filtro</MenuItem>
                                {
                                    AccountAccessActivities.map(aa => (<MenuItem key={aa.value} value={aa.value}>{aa.label}</MenuItem>))
                                }
                            </Select>
                        </FormControl>
                        {
                            accountActivities.map(activity => <RenderActivities activity={activity} key={activity.id} actionType={ActionType.ACCOUNT} />)
                        }
                        {
                            !fetchMoreAccountContent ?
                                <Alert variant='outlined'>Não há mais dados a serem exibidos.</Alert>
                                :
                                <LoadingButton loading={loading} fullWidth onClick={fetchAccountAccessActivities}>Buscar mais atividades</LoadingButton>
                        }
                    </TabPanel>
                    <TabPanel value='1'>
                        <Typography>Histórico de atividades recentes realizadas em outros usuários administradores</Typography>
                        <FormControl variant="standard" fullWidth sx={{ mt: 2, mb: 4 }}>
                            <InputLabel id="action">Filtre por ação</InputLabel>
                            <Select
                                value={adminAction}
                                onChange={handleAdminSelectChange}
                                labelId='action'
                            >
                                <MenuItem value={''}>Sem filtro</MenuItem>
                                {
                                    AdministratorActivities.map(aa => (<MenuItem key={aa.value} value={aa.value}>{aa.label}</MenuItem>))
                                }
                            </Select>
                        </FormControl>
                        {
                            requesterActivities.map(activity => <RenderActivities activity={activity} key={activity.id} actionType={ActionType.REQUESTER} />)
                        }
                        {
                            !fetchMoreRequesterContent ?
                                <Alert variant='outlined'>Não há mais dados a serem exibidos.</Alert>
                                :
                                <LoadingButton fullWidth loading={loading} onClick={fetchAdministratorActivitiesByRequester}>Buscar mais atividades</LoadingButton>
                        }
                    </TabPanel>
                    <TabPanel value='2'>
                        <Typography>Histórico de atividades recentes realizadas por outros usuários administradores em sua conta</Typography>
                        <FormControl variant="standard" fullWidth sx={{ mt: 2, mb: 4 }}>
                            <InputLabel id="action">Filtre por ação</InputLabel>
                            <Select
                                value={adminAction}
                                onChange={handleAdminSelectChange}
                                labelId='action'
                            >
                                <MenuItem value={''}>Sem filtro</MenuItem>
                                {
                                    AdministratorActivities.map(aa => (<MenuItem key={aa.value} value={aa.value}>{aa.label}</MenuItem>))
                                }
                            </Select>
                        </FormControl>
                        {
                            targetActivities.map(activity => <RenderActivities activity={activity} key={activity.id} actionType={ActionType.TARGET} />)
                        }
                        {
                            !fetchMoreTargetContent ?
                                <Alert variant='outlined'>Não há mais dados a serem exibidos.</Alert>
                                :
                                <LoadingButton fullWidth loading={loading} onClick={fetchAdministratorActivitiesByTarget}>Buscar mais atividades</LoadingButton>
                        }
                    </TabPanel>
                </TabContext>
            </Box >
            <AppNotification />
        </Box >
    );
}